import { useState } from 'react';
import styled from 'styled-components';
import { ImageBackground, WelcomeHeader } from '../components/presentational';
import backgroundImage from '../images/client-assets/background.jpg';
import ResetPasswordForm from '../components/Login/ResetPasswordForm';
import { EXTRA_SMALL_MAX } from '../components/presentational/mediaBreakpoints';
import { Link, NormalText } from '../components/presentational/common';
import { Link as RouterLink, useParams } from 'react-router-dom';

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const WrapperDiv = styled.div`
  max-width: 350px;
  margin: 10px;

  @media (max-width: ${EXTRA_SMALL_MAX}) {
    max-width: none;
    width: 100%;
    margin: 0;
    padding: 10px;
  }
`;

const BackLink = styled(Link.withComponent(RouterLink))`
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
`;

const ResetPasswordContainer = () => {
  const { uid, token } = useParams();
  const [message, setMessage] = useState('');

  return (
    <>
      <ImageBackground image={backgroundImage} />
      <BackLink to="/">
        <i className="fas fa-angle-left" /> Voltar atrás
      </BackLink>
      <CenteredDiv>
        <WrapperDiv>
          <WelcomeHeader
            welcomeText={ResetPasswordContainer.messages.resetPasswordPage}
            descriptionText={ResetPasswordContainer.messages.resetPasswordDescription}
            textAlign="center"
            marginBottom="35px"
          />
          {message && <NormalText textColor="#fff">{message}</NormalText>}
          <ResetPasswordForm setMessage={setMessage} uid={uid} token={token} />
        </WrapperDiv>
      </CenteredDiv>
    </>
  );
};

ResetPasswordContainer.messages = {
  resetPasswordPage: 'Recuperar Password',
  resetPasswordDescription: 'Introduza a nova password para sua conta',
};

export default ResetPasswordContainer;
