import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from '../presentational/common';

const ResultsListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ResultsList = styled.ul`
  margin-top: 15px;
  list-style-type: none;
  padding: 0;
  border: 1px solid #ccc;

  > li {
    border: none;
    
    > div {
      padding: 10px;
    }

    span {
      color: #666;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
    &:hover, &:active {
      cursor: pointer;
      background-color: #0080B6;
      color: white;
      span {
        color: #333;
      }
    }
  }
`;

/**
 * Callback to handle the click on a item from the search results list
 *
 * @callback resultSelectedHandler
 * @param {string} featureId ID of the selected feature
 */

/**
 * Callback to handle the click on the fit all results item
 *
 * @callback fitAllResultsHandler
 */

/**
 *
 * @param {object} props Component props
 * @param {array} searchResults List of search results in the GEOJSON features format
 * @param {resultSelectedHandler} resultSelectedHandler
 * @param {fitAllResultsHandler} [fitAllResultsHandler]
 * @param {fieldsToShow} fieldsToShow List of fields to show in results list
 * @constructor
 */
const FeatureSearchResults = ({
  searchResults, resultSelectedHandler, fitAllResultsHandler, fieldsToShow,
}) =>
  (searchResults.length > 0 ?
    <ResultsListContainer>
      {(fitAllResultsHandler !== undefined) &&
        <Button
          onClick={fitAllResultsHandler}
        >
          {FeatureSearchResults.messages.fit_search_results}
        </Button>}
      <ResultsList>
        {searchResults.map(feature =>
          (
            <li
              key={feature.id}
            >
              <div
                role="button"
                tabIndex={0}
                onClick={() => resultSelectedHandler(feature.id)}
                onKeyPress={({ charCode }) => (charCode === 13) && resultSelectedHandler(feature.id)}
              >
                {fieldsToShow.map(field => (
                  <div key={field.key}>
                    <span>
                      {`${field.alias}: `}
                    </span>
                    {feature.properties[field.key]}
                    <br />
                  </div>
                ))}
              </div>
            </li>))}
      </ResultsList>
    </ResultsListContainer>
    :
    <div>
      {FeatureSearchResults.messages.no_results}
    </div>);

FeatureSearchResults.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  resultSelectedHandler: PropTypes.func.isRequired,
  fieldsToShow: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    alias: PropTypes.string,
  })).isRequired,
  fitAllResultsHandler: PropTypes.func,
};

FeatureSearchResults.defaultProps = {
  fitAllResultsHandler: undefined,
};

FeatureSearchResults.messages = {
  no_results: 'Sem resultados!',
  fit_search_results: 'Enquadrar todos os resultados no mapa',
};

export default FeatureSearchResults;
