import styled from 'styled-components';

export default styled.a`
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  font-size: 14px;

  &:hover {
    color: white;
  }
`;
