import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import './ZoomLevelChooser.scss';
import Button from '../presentational/common/Button';

/**
 * Widget to choose zoom min/max zoom levels for a Map
 */
function ZoomLevelChooser(props) {
  const olMap = useSelector((state) => state.map.map ? state.map.map.olMap : null);
  const [active, setActive] = useState(true);
  const [zoom, setZoom] = useState(undefined);

  useEffect(() => {
    // check if this field target the minZoom or the maxZoom and put the
    // correspondent update method in updateZoom property
    if (props.options.target === 'minZoom') {
      setZoom(olMap.getView().getMinZoom())
    } else {
      setZoom(olMap.getView().getMaxZoom());
    }
  }, []);



  /**
   * Read zoom level from map button click event handler
   */
  const handleReadButtonClick = (event) => {
    event.preventDefault();

    if (active) {
      setZoom(olMap.getView().getZoom());
    }
    props.onChange(olMap.getView()
      .getZoom());
  };

  /**
   * Zoom level input changes handler
   */
  const handleZoomInputChange = (event) => {
    event.preventDefault();
    // Parse input text to a integer
    const newZoomLevel = Number.parseInt(event.target.value, 10);
    // If is not a number, value is null
    if (Number.isNaN(newZoomLevel)) {
      props.onChange(undefined);
      return;
    }

    // if is active we have to update the min/max zoom of the map
    if (active && olMap !== null) {
      setZoom(newZoomLevel);
    }

    // update value
    props.onChange(newZoomLevel);
  };

  /**
   * Active checkbox change handler
   */
  const handleActiveCheckboxChange = (event) => {
    // update state
    setActive(event.target.checked);
    // if is checked update zoom level according to the value otherwise remove
    // the zoom constraint
    setZoom(event.target.checked ? props.value : undefined);
  };

  return (
    <div>
      <input
        type="number"
        min="1"
        onChange={handleZoomInputChange}
        required={props.required}
        value={props.value}
        className={'form-control zoom-level-input'}
      />
      {olMap &&
        <React.Fragment>
          <Button
            onClick={handleReadButtonClick}
            className={'current-zoom-btn'}
          >
            Ler nível de zoom atual
          </Button>
          <label>
            <input
              type="checkbox"
              checked={active}
              onChange={handleActiveCheckboxChange}
            />
            <span>Ativar limite zoom</span>
          </label>
        </React.Fragment>
      }

    </div>
  );

}

ZoomLevelChooser.propTypes = {
  required: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  options: PropTypes.shape({
    target: PropTypes.string.isRequired,
  }).isRequired,
};


const ZoomLevelChooserWidget = ZoomLevelChooser;

const WrappedLevelChooserWidget = props => <ZoomLevelChooserWidget {...props} />;

export default WrappedLevelChooserWidget;
