import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NumericListItem from './NumericListItem';

// ul without any left spacing
const List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  padding-top: 25px;
`;

const NumericList = ({ items }) => (
  <List>
    {items.map((item, index) =>
      <NumericListItem key={item.id} number={index + 1} text={item.text} url={item.url} />)
    }
  </List>
);

NumericList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
};

export default NumericList;
