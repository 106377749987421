import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Checkbox,
  NormalText,
  SelectableButton
} from '../presentational/common';
import ActionButtonContainer
  from '../presentational/common/ActionButtonContainer';
import { ReactComponent as LineIcon } from '../../images/line.svg';
import { ReactComponent as PolygonIcon } from '../../images/polygon.svg';


const BlueText = styled(NormalText)`
  color: #008CD0;
`;

const SnapLabel = NormalText.withComponent('label');

const SectionContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

const DrawControls = ({ snapChangeCallback, drawTypeChangeCallback, geometryType, currentDrawType }) => {
  const [snapInteractionEnabled, setSnapInteractionEnabled] = useState(true);

  const handleSnapChange = (event) => {
    const isChecked = event.currentTarget.checked;

    setSnapInteractionEnabled(isChecked);
    snapChangeCallback(isChecked);
  };

  const handleDrawTypeChange = (drawType) => {
    const newDrawType = drawType === currentDrawType ? null : drawType;

    drawTypeChangeCallback(newDrawType);
  };

  return (
    <React.Fragment>
      <BlueText>
        {DrawControls.messages.help_tools_message}
      </BlueText>
      <SnapLabel>
        <Checkbox
          checked={snapInteractionEnabled}
          onChange={handleSnapChange}
        />
        {` ${DrawControls.messages.toggle_snap_interaction}`}
      </SnapLabel>
      <SectionContainer>
        <ActionButtonContainer>
          {['POINT', 'MULTIPOINT', 'GEOMETRYCOLLECTION', 'GEOMETRY'].indexOf(
            geometryType) !== -1 &&
          <SelectableButton
            id="drawPointButton"
            selected={currentDrawType === 'Point'}
            onClick={() => handleDrawTypeChange('Point')}
          >
            <i className="fas fa-map-marker-alt" />
          </SelectableButton>
          }
          {['LINESTRING', 'MULTILINESTRING', 'GEOMETRYCOLLECTION',
            'GEOMETRY'].indexOf(geometryType) !== -1 &&
          <SelectableButton
            id="drawLineButton"
            selected={currentDrawType === 'LineString'}
            onClick={() => handleDrawTypeChange('LineString')}
          >
            <LineIcon width="12px" height="20px" />
          </SelectableButton>
          }
          {['POLYGON', 'MULTIPOLYGON', 'GEOMETRYCOLLECTION',
            'GEOMETRY'].indexOf(geometryType) !== -1 &&
          <SelectableButton
            id="drawPolygonButton"
            selected={currentDrawType === 'Polygon'}
            onClick={() => handleDrawTypeChange('Polygon')}
          >
            <PolygonIcon width="12px" height="20px" />
          </SelectableButton>
          }
        </ActionButtonContainer>
      </SectionContainer>
    </React.Fragment>
  );
};

DrawControls.messages = {
  help_tools_message: 'Selecione uma opção de desenho abaixo se pretender criar objectos novos:',
  toggle_snap_interaction: 'Fazer snap de features',
};

export default DrawControls;
