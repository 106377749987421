import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import PropTypes from 'prop-types';
import ExternalLayersList from './ExternalLayersList';
import { LAYER_TYPES, LAYER_SOURCES } from '../../factories/LayerFactory';
import Constants from '../../utils/constants';
import Layer from '../../models/Layer';
import { transformExtent } from 'ol/proj';
import { Tile } from 'ol/layer';
import Projection from 'ol/proj/Projection';

export default function ExternalSourceImporter(props) {
  const [inputText, setInputText] = useState('');
  const [layersList, setLayersList] = useState([]);
  const [failMessage, setFailMessage] = useState('');

  function handleInputChange(event) {
    setInputText(event.currentTarget.value);
  }

  function handleAddSource() {
    // empty list
    setLayersList([]);
    setFailMessage('');

    // add getcapabilities if needed
    let url = inputText;

    // getcapabilities ajax request
    axios
      .get(`${Constants.API_BASE_URL}/layers/wfs-request/capabilities/`, {
        params: {
          url,
        },
      })
      .then(({ data }) => {
        // check if service type is supported
        if ('feature_types' in data) {
          if (url.indexOf('?') >= 0) {
            url = url.split('?')[0].toLowerCase();
          }

          const layers = Object.values(data.feature_types).map(
            (layer) =>
              new Layer(LAYER_TYPES.OVERLAY, {
                id: `${url}:${layer.name}`,
                name: layer.title,
                sourceType: LAYER_SOURCES.WMS,
                url,
                wmsParams: { LAYERS: layer.name },
                extent: transformExtent(layer.boundingBoxWGS84, 'EPSG:4326', props.viewProjection),
              }),
          );

          setLayersList(layers);

          setFailMessage(layers.length > 0 ? '' : 'Não foram encontrados layers válidos');
        }
      })
      .catch(() => {
        setFailMessage('Não foram encontrados layers válidos');
      });
  }

  function handleLayerClick(layer) {
    if (layer.olLayer instanceof Tile) {
      props.addLayerToMap(layer.olLayer);
    } else {
      throw new Error('Not supported layer type.');
    }
  }

  return (
    <Modal
      isOpen={props.open}
      contentLabel="Add Source"
      onRequestClose={props.closeCallback}
      style={{
        content: {
          height: 800,
          width: 400,
        },
      }}
      ariaHideApp={false}
    >
      <input type="url" value={inputText} onChange={handleInputChange} />
      <button onClick={handleAddSource}>Adicionar</button>
      {layersList.length > 0 ? (
        <ExternalLayersList layers={layersList} handleLayerClick={handleLayerClick} />
      ) : (
        <h1>{failMessage}</h1>
      )}
    </Modal>
  );
}

ExternalSourceImporter.propTypes = {
  addLayerToMap: PropTypes.func.isRequired,
  viewProjection: PropTypes.instanceOf(Projection).isRequired,
  open: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
};
