import axios from 'axios';
import Constants from './constants';

export const updateSchema = (fields, schema, data) => {
  // if fields is empty the dotted path was fully resolved so the schema is the
  // one that needs to be updated
  if (fields.length === 0) {
    Object.assign(schema, data);
    return;
  }

  const fieldName = fields[0];

  // process field nesting for object and array type schemas
  if (schema.type === 'object') {
    updateSchema(fields.slice(1), schema.properties[[fieldName]], data);
  } else if (schema.type === 'array') {
    updateSchema(fields.slice(1), schema.items.properties[[fieldName]], data);
  }
};

const fetchFeatureTypes = (formData) => {
  const { wfs_layer: { service_url: serviceUrl, feature_typename: featureTypename } } = formData;
  const apiUrl = `${Constants.API_BASE_URL}/layers/wfs-request/describe-feature-type/?url=${serviceUrl}&typename=${featureTypename}`;

  return axios.get(apiUrl)
    .then(({ data: { jsonschema: featureJsonschema } }) => featureJsonschema)
    .then(({ properties }) => ({ enum: Object.keys(properties) }));
};

export const transformFunctions = { fetchFeatureTypes };
