import styled, { css } from 'styled-components';

export default styled.button`
  color: white;
  background: #0080B6;
  border: none;
  border-radius: 4px;
  padding: 5px 20px;
  font-weight: 300;
  outline: none;

  ${props => props.width && css`
    width: ${props.width};
  `}

  ${props => props.danger && css`background: #DC143C;`}

  ${props => props.secondary && css`
    border: 1px solid #0080B6;
    &:not(:hover) {
      color: #0080B6;
      background: transparent;
    }
  `}

  &:hover, &:active {
    cursor: pointer;
    opacity: 0.9;
  }
`;
