import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ListItem = styled.li`
  padding-bottom: 25px;
  overflow: hidden;
  border-radius: 2px;
`;

const StyledLink = styled(Link)`
  display: flex;
  &:hover, &:active, &:focus {
    cursor: pointer;
    background: rgba(255, 255, 255);
    text-decoration: none;
  }
`;

const NumericBadge = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  background-color: #008CD0;
  color: white;
  font-size: 30px;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  background: rgba(255, 255, 255, 0.75);
`;

const ItemText = styled.p`
  flex: 1;
  margin: 10px;
  color: #666;
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 22px;
  color: #6F706F;
`;


const NumericListItem = ({ number, text, url }) => (
  <ListItem>
    <StyledLink
      to={url}
    >
      <NumericBadge>
        {number}
      </NumericBadge>
      <ItemContainer>
        <ItemText>
          {text}
        </ItemText>
        <ArrowContainer>
          <i className="fas fa-arrow-right" />
        </ArrowContainer>
      </ItemContainer>
    </StyledLink>
  </ListItem>
);

NumericListItem.propTypes = {
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default NumericListItem;
