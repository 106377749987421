import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextInput } from '../presentational/common';

import slugify from 'slugify';


function SlugInput(props) {
    const [slugValue, setSlugValue] = useState("");

    const handleSlugChange = (event) => {

        setSlugValue(event.target.value);
        props.onChange(event.target.value);
    }

    const handleClick = (event) => {
        // Do not submit form on button click
        event.preventDefault();

        if ('readFrom' in props.options) {
            const readFromElementName = `root_${props.options.readFrom}`;
            const readFromElement = document.getElementById(readFromElementName);
            const readFromValue = readFromElement.value;
            const slugify_name = slugify(readFromValue, {lower: true});
            setSlugValue(slugify_name);
            props.onChange(slugify_name);
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <input
                style={{ flex: 1}}
                className="form-control"
                required={props.required}
                id={props.id}
                type="text"
                placeholder={props.placeholder}
                value={slugValue}
                onChange={(event) => handleSlugChange(event)}
            />
            <Button
                className='submit-btn'
                onClick={(event) => handleClick(event)}
            >
                Gerar slug
            </Button>
        </div>
    )
}

SlugInput.propTypes = {
    required: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  };

const SlugInputWidget = SlugInput;
const WrappedSlugInputWidget = props => <SlugInputWidget {...props} />;

export default WrappedSlugInputWidget;
