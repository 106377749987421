import { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Creatable from 'react-select/creatable';
import Constants from '../../utils/constants';
import './WFSLayersFromCapabilities.scss';

/**
 * Custom field for react-jsonschema-form to configure a new searchable layer.
 */
export default function WFSLayersFromCapabilities(props) {
  const [wfsFields, setWfsFields] = useState(props.formData);
  const [selectedOption, setSelectedOption] = useState(null);
  const [featureTypes, setFeatureTypes] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);

  const fetchWFSCapabilities = async (wfsServiceURL) => {
    const apiUrl = `${Constants.API_BASE_URL}/layers/wfs-request/capabilities/?url=${wfsServiceURL}`;
    const response = await axios.get(apiUrl);
    return response.data.feature_types;
  };

  /**
   * Replace empty string values with undefined in order to have all
   * components "controlled" and don't submit empty values in the form
   */
  const processValues = (fieldsObject) => {
    return Object.keys(fieldsObject).reduce(
      (updatedFormData, key) => ({
        ...updatedFormData,
        [key]: fieldsObject[key] !== '' ? fieldsObject[key] : undefined,
      }),
      {},
    );
  };

  const getEnumOptions = (uiSchema) => {
    const {
      service_url: {
        'ui:options': { defaultOptions },
      },
    } = uiSchema;
    return Object.entries(defaultOptions).map(([label, value]) => ({ label, value }));
  };

  const handleURLChange = (selectedOption) => {
    if (selectedOption) {
      const { value: url } = selectedOption;
      setWfsFields({ service_url: url });
      setFeatureTypes(null);
      setSelectedOption(selectedOption);

      fetchWFSCapabilities(url)
        .then(setFeatureTypes)
        .catch(() => setFeatureTypes(null));
    } else {
      setFeatureTypes(null);
      setWfsFields({});
      setSelectedOption(null);
    }
  };

  const handleFeatureTypeChange = (event) => {
    setWfsFields((prevWfsFields) => ({
      ...prevWfsFields,
      feature_typename: event.target.value,
    }));
  };

  // Initialize component state and fetch capabilities if needed
  useEffect(() => {
    const options = getEnumOptions(props.uiSchema);
    setDefaultOptions(options);

    const { service_url: wfsServiceURL } = props.formData;
    let initialSelectedOption = null;

    // if there is a defined value that isn't one of the default ones, it is appended to the
    // default list of options
    if (wfsServiceURL != null) {
      const existingOption = options.find(({ value }) => value === wfsServiceURL);
      if (existingOption === undefined) {
        initialSelectedOption = { label: wfsServiceURL, value: wfsServiceURL };
        setDefaultOptions([...options, initialSelectedOption]);
      } else {
        initialSelectedOption = existingOption;
      }
    }

    setSelectedOption(initialSelectedOption);

    // if this field was instantiated with existing data, it is necessary to fetch the capabilities
    // to the provided url to fill the selects for the layer name and style
    if (wfsServiceURL) {
      fetchWFSCapabilities(wfsServiceURL)
        .then(setFeatureTypes)
        .catch(() => setFeatureTypes(null));
    }
  }, [props.formData, props.uiSchema]);

  // Handle changes in wfsFields
  useEffect(() => {
    props.onChange(processValues(wfsFields));
  }, [wfsFields]);

  return (
    <div>
      <div className="form-group">
        <label htmlFor="service-url">URL do serviço</label>
        <Creatable
          name="service-url"
          value={selectedOption}
          onChange={handleURLChange}
          options={defaultOptions}
          placeholder=""
        />
      </div>

      {featureTypes && (
        <div className="form-group">
          <label htmlFor="feature-type">Tipo da Feature</label>
          <select
            value={wfsFields.feature_typename || ''}
            id="feature-type"
            className="form-control"
            onChange={handleFeatureTypeChange}
          >
            <option value="" />
            {Object.values(featureTypes).map((featureType) => (
              <option key={featureType.name} value={featureType.name}>
                {featureType.title}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}

WFSLayersFromCapabilities.propTypes = {
  /* eslint-disable react/forbid-prop-types, react/require-default-props */
  uiSchema: PropTypes.object,
  formData: PropTypes.any,
  onChange: PropTypes.func,
  /* eslint-enable react/forbid-prop-types, react/require-default-props  */
};
