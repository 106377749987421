import React, { useState } from 'react';
import Form from '@rjsf/core';
import { isObject, deepEquals, toErrorList } from '@rjsf/utils';

function FormRJSF(props) {
  const [formData, setFormData] = useState(props.formData);
  const [errors, setErrors] = useState([]);
  const [errorSchema, setErrorSchema] = useState({});

  const onSubmit = async event => {
    setFormData(event.formData);
    if (!props.noValidate) {
      const sameErrorFlag = deepEquals(event.errors, errors);
      if (Object.keys(event.errors).length > 0 && !sameErrorFlag) {
        setErrors(event.errors);
        setErrorSchema(event.errorSchema);
        if (props.onError) {
          props.onError(errors);
        } else {
          console.error("Form validation failed", errors);
        }
        return;
      }
    }

    setErrors([]);
    setErrorSchema({});

    if (props.onSubmit) {
      try {
        await props.onSubmit({ formData: event.formData, status: "submitted" });
      } catch (error) {
        const errorSchema = apiErrorsToErrorSchema(error.data);
        setErrors(toErrorList(errorSchema));
        setErrorSchema(errorSchema);
      }
    }
  };

  const apiErrorsToErrorSchema = (errorList) => {
    return Object.keys(errorList).reduce((errors, key) => {
      if (isObject(errorList[key])) {
        errors[key] = apiErrorsToErrorSchema(errorList[key]);
      } else {
        errors[key] = { __errors: errorList[key] };
      }
      return errors;
    }, {});
  };

  return (
    <Form
      {...props}
      formData={formData}
      onSubmit={onSubmit}
      onError={props.onError}
      errors={errors}
      errorSchema={errorSchema}
      extraErrors={errorSchema}
      showErrorList={false}
    />
  );
}

export default FormRJSF;
export { deepEquals, isObject };
