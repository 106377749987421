import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';


// proj4 alias definition
const EPSG4326Aliases = [
  'CRS:84',
  'urn:ogc:def:crs:EPSG::4326',
  'urn:ogc:def:crs:EPSG:6.6:4326',
  'urn:ogc:def:crs:OGC:1.3:CRS84',
  'urn:ogc:def:crs:OGC:2:84',
  'http://www.opengis.net/gml/srs/epsg.xml#4326',
  'urn:x-ogc:def:crs:EPSG:4326',
];

const EPSG3857Aliases = [
  'EPSG:102100',
  'urn:ogc:def:crs:EPSG:6.18:3:3857',
  'urn:ogc:def:crs:EPSG::3857',
  'http://www.opengis.net/gml/srs/epsg.xml#3857',
];

EPSG4326Aliases.forEach((alias) => {
  if (!proj4.defs(alias)) {
    proj4.defs(alias, proj4.defs('EPSG:4326'));
  }
});

EPSG3857Aliases.forEach((alias) => {
  if (!proj4.defs(alias)) {
    proj4.defs(alias, proj4.defs('EPSG:3857'));
  }
});

proj4.defs("EPSG:3763","+proj=tmerc +lat_0=39.6682583333333 +lon_0=-8.13310833333333 +k=1 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");

register(proj4);
