import { createSlice } from '@reduxjs/toolkit';
import { fetchMapList } from '../thunks/mapListThunk';

const initialState = {
  maps: [],
  isFetching: false,
  error: false,
};

const mapListSlice = createSlice({
  name: 'mapList',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchMapList.pending, (state) => {
      state.isFetching = true;
      state.error = false;
    })
      .addCase(fetchMapList.fulfilled, (state, action) => {
        state.isFetching = false;
        state.maps = action.payload;
        state.error = false;
      })
      .addCase(fetchMapList.rejected, (state) => {
        state.isFetching = false;
        state.error = true;
      });
  },
});


export default mapListSlice.reducer;
