import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Constants from '../../../utils/constants';
import {
  LoadingSpinner,
  NormalText,
  SelectableButton,
  SidebarHeader,
  SidebarListItem,
  SidebarTitle,
} from '../../presentational/common';
import OWSProvidersListItem from './OWSProvidersListItem';
import AdminComponent, { ADMIN_ACTIONS } from '../../Admin/AdminComponent';
import { HeaderActionButtonContainer } from '../../presentational/common';


const SidebarSubTitle = styled(SidebarTitle)`
  font-size: 18px;
`;

const NoEntriesText = styled(NormalText)`
  margin-bottom: 0;
`;


function OWSProvidersList({renderAdminButtons}){
  const [isFetching, setIsFetching] = useState(false);
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    fetchOWSProviders()
  }, []);


  const fetchOWSProviders = () => {
    axios.get(`${Constants.API_BASE_URL}/layers/providers/`)
      .then(({ data: providers }) => {
        setIsFetching(false)
        setProviders(providers);
      })
      .catch(() => {
        setIsFetching(false);
        setProviders([]);
      });
  }

  const renderProvidersList = () => {
    return (
      (providers && providers.length) ?
        providers.map(provider =>
          (<OWSProvidersListItem
            key={provider.id}
            provider={provider}
            actions={[
              {
                action: ADMIN_ACTIONS.EDIT,
                url: `${Constants.API_BASE_URL}/layers/providers/${provider.id}/`,
                component: SelectableButton,
                children: <i className="fas fa-pencil-alt" />,
              },
              {
                action: ADMIN_ACTIONS.DELETE,
                url: `${Constants.API_BASE_URL}/layers/providers/${provider.id}/`,
                component: SelectableButton,
                children: <i className="fas fa-trash-alt" />,
              },
            ]}
          />)) :
        <SidebarListItem>
          <NoEntriesText>{OWSProvidersList.messages.no_entries}</NoEntriesText>
        </SidebarListItem>
    );
  }

    return (
      <React.Fragment>
        <SidebarHeader>
          <SidebarSubTitle>Providers</SidebarSubTitle>
          <HeaderActionButtonContainer>
            {renderAdminButtons && renderAdminButtons()}
          </HeaderActionButtonContainer>
        </SidebarHeader>
        {isFetching ? <LoadingSpinner /> : renderProvidersList()}
      </React.Fragment>);

}

OWSProvidersList.propTypes = {
  renderAdminButtons: PropTypes.func,
};

OWSProvidersList.defaultProps = {
  renderAdminButtons: null,
};

OWSProvidersList.messages = {
  no_entries: 'Não existe nenhum provider!',
};

export default AdminComponent(OWSProvidersList);
