import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Control from 'ol/control/Control';
import GetFeatureInfo from '../GetFeatureInfo/GetFeatureInfo';
import './IdentifyControl.scss';

class IdentifyControl extends Control {
  constructor(options) {
    const button = document.createElement('button');
    button.innerHTML = '<i class="fa-solid fa-info"></i>';
    button.title = 'Identificar objetos';

    const element = document.createElement('div');
    element.className = 'identify-btn ol-unselectable ol-control';
    element.appendChild(button);

    super({
      element: element,
      target: options.target,
    });

    this.button = button;
    this.identifyFeatures = false;
    this.handleClick = this.handleClick.bind(this);
    this.button.addEventListener('click', this.handleClick);
  }

  handleClick() {
    this.identifyFeatures = !this.identifyFeatures;
    this.updateButtonState();
    this.dispatchEvent('toggleIdentify');
  }

  updateButtonState() {
    if (this.identifyFeatures) {
      this.button.classList.add('active', 'focus');
    } else {
      this.button.classList.remove('active', 'focus');
    }
  }
}

function IdentifyControlWrapper({ map }) {
  const [identifyFeatures, setIdentifyFeatures] = useState(false);

  useEffect(() => {
    const identifyControl = new IdentifyControl({ map });
    map.olMap.addControl(identifyControl);

    const handleToggleIdentify = () => {
      setIdentifyFeatures(identifyControl.identifyFeatures);
    };

    identifyControl.on('toggleIdentify', handleToggleIdentify);

    return () => {
      map.olMap.removeControl(identifyControl);
      identifyControl.un('toggleIdentify', handleToggleIdentify);
    };
  }, [map]);

  return identifyFeatures ? <GetFeatureInfo map={map} /> : null;
}

IdentifyControlWrapper.propTypes = {
  map: PropTypes.shape({
    olMap: PropTypes.object.isRequired,
  }).isRequired,
};

export default IdentifyControlWrapper;