import styled, { css } from 'styled-components';

export default styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background: transparent;
  border: ${props => (props.borderless ? '0' : '1px solid #ccc')};
  border-radius: 3px;
  color: #666;
  outline: none;
  &:hover {
    color: #0080B6;
    border: 1px solid #ccc;
  }

  ${props => props.selected && css`
    color: #0080B6;
    border: 1px solid #ccc;
  `}
`;
