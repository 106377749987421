/* eslint-disable class-methods-use-this */
import React, { useRef, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Utils from '../../utils/utils';
import Modal from 'react-modal';
import FormRJSF, { deepEquals } from '../RJSForm/Form';
import validator from '@rjsf/validator-ajv8';
import {
  CurrentMapField,
  MapExtentChooser,
  WFSLayersFromCapabilities,
  WMSLayersFromCapabilities,
  ZoomLevelChooser,
  SlugInput,
} from "../Widgets";
import { useSelector } from 'react-redux';
import { transformFunctions, updateSchema } from '../../utils/SchemaTransformations';
import "../RJSForm/jsonschema-form.scss";
import Button from "../presentational/common/Button";


const ADMIN_ACTIONS = {
  CREATE: 0,
  EDIT: 1,
  DELETE: 2,
};

const WIDGETS = {
  mapExtentChooser: MapExtentChooser,
  zoomLevelChooser: ZoomLevelChooser,
  slugInput: SlugInput,
};

const FIELDS = {
  wmsLayer: WMSLayersFromCapabilities,
  wfsLayer: WFSLayersFromCapabilities,
  currentMap: CurrentMapField,
};

/**
 * High order component for managing admin integration.
 *
 */
function adminToolbox(WrappedComponent) {
  function AdminToolbox(props) {
    const [form, setForm] = useState(null);
    const [step, setStep] = useState(1);
    const {hasEditPermission} = useSelector((state) => state.user);

    const formSchemas = useRef({});

    const getObjectDetails = (url) => {
      return axios
        .get(url, {
          // replace all null values in the object by undefined to avoid
          // jsonschema errors
          transformResponse: (data) => JSON.parse(data, (key, value) => (value === null ? undefined : value)),
        })
        .then((response) => response.data);
    };

    const fetchFormSchema = (url) => {
      if (url in formSchemas.current) {
        const schemas = formSchemas.current[url];

        return Promise.resolve(schemas);
      }

      return axios.options(url).then((response) => {
        const { schemas } = response.data;

        formSchemas.current[url] = schemas;

        return schemas;
      });
    };

    const deleteObject = (url) => {
      if (window.confirm('Eliminar?')) {
        axios.delete(url).then(() => {
          if (props.onDeleteCallback) {
            props.onDeleteCallback();
            return;
          }

          window.location.reload(true);
        });
      }
    };

    const handleClick = (action, url) => {
      switch (action) {
        case ADMIN_ACTIONS.CREATE:
          fetchFormSchema(url).then((schemas) =>
            setForm(
              {
                url,
                method: 'POST',
                schemas,
                formData: {},
              }
            ),
          );
          break;
        case ADMIN_ACTIONS.EDIT: {
          Promise.all([fetchFormSchema(url), getObjectDetails(url)]).then(([schemas, formData]) => {
            setForm(
              {
                url,
                method: 'PUT',
                schemas,
                formData,
              }
            );
          });
          break;
        }
        case ADMIN_ACTIONS.DELETE:
          deleteObject(url);
          break;
        default:
          break;
      }
    };

    const handleSubmit = (formSubmitted) => {
      const { formData } = formSubmitted;
      const { url, method } = form;

      // if the current step is the last one the form is submitted
      if (step === form.schemas.length) {
        return axios
          .request({
            url,
            method,
            // replace undefined fields with null to void its value in
            // django-restframework
            data: (() => {
              if (method === 'PUT') {
                return JSON.stringify(formData, (key, value) => (value === undefined ? null : value));
              }
              return JSON.stringify(formData);
            })(),
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },
          })
          .then(({ data }) => {
            if (method === 'POST' && props.onCreateCallback) {
              props.onCreateCallback(data);
              return;
            }

            if (method === 'PUT' && props.onChangeCallback) {
              props.onChangeCallback(data);
              return;
            }

            window.location.reload(true);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              throw error.response;
            }
          });
      }

      // Get the current and next schema to check field dependencies and
      // computed fields
      const currentStep = step - 1;
      const currentSchema = { ...form.schemas[currentStep] };
      const nextSchema = { ...form.schemas[currentStep + 1] };

      // Dependent fields
      const { dependentFields } = currentSchema;

      if (dependentFields) {
        // if there are dependent fields, when the one that is depended on
        // changes it invalidates the dependent field
        dependentFields.forEach(({ field, dependent }) => {
          if (form.formData && !deepEquals(form.formData[field], formData[field])) {
            delete formData[dependent];
          }
        });
      }

      // Computed fields
      const { computedFields } = nextSchema;

      if (computedFields) {
        // if there are computed fields its schema is updated using the
        // transform function defined in the jsonschema. fields are defined
        // using dotted notation "fieldA.nestedField.property"
        const updateSchemaPromises = computedFields.map(({ field, transformFunction }) =>
          Promise.resolve(transformFunctions[transformFunction](formData)).then((transformedSchema) =>
            updateSchema(field.split('.'), nextSchema.schema, transformedSchema),
          ),
        );

        // when all computed fields are processed, the altered schema is
        // replaced in the app state
        Promise.all(updateSchemaPromises).then(() => {
          const schemas = [...form.schemas];
          schemas[currentStep + 1] = nextSchema;
          setForm(
            {
              ...form,
              schemas,
              formData,
            }
          );
          setStep(step + 1);
        });
      } else {
        setForm(
          {
            ...form,
            formData,
          }
        );
        setStep(step + 1);
      }
    };

    const renderAdminButtons = () => {
      return props.actions.map(({ action, url, component, children, title }) =>
        React.createElement(
          component,
          {
            key: `${action}-${url}`,
            onClick: () => handleClick(action, url),
            title: title || '',
          },
          children,
        ),
      );
    };

    const previousClick = () => {
      setStep(step - 1);
    }

    const cancelClick = () => {
      setForm(null);
      setStep(1);
    }

    return (
      <React.Fragment>
        {!hasEditPermission ? (
          <WrappedComponent {...props} />
        ) : (
          <>
            <WrappedComponent renderAdminButtons={renderAdminButtons} {...props} />
            {form && (
              <Modal isOpen={true} ariaHideApp={false}>
                <FormRJSF
                  schema={form.schemas[step - 1].schema}
                  uiSchema={form.schemas[step - 1].uiSchema}
                  fields={FIELDS}
                  widgets={WIDGETS}
                  onSubmit={handleSubmit}
                  formData={form.formData}
                  validator={validator}
                  className={'jsonschema-form'}
                >
                  <div className={'form-actions'}>
                    {step > 1 && (
                      <Button onClick={previousClick} className={'back-btn'}>
                        {AdminToolbox.messages.previous}
                      </Button>
                    )}
                    <Button type="submit" className={'submit-btn'}>
                      {step === form.schemas.length
                        ? AdminToolbox.messages.save
                        : AdminToolbox.messages.next}
                    </Button>

                    <Button
                      secondary
                      onClick={cancelClick}
                      className={'cancel-btn'}
                    >
                      {AdminToolbox.messages.cancel}
                    </Button>
                  </div>
                </FormRJSF>
              </Modal>
            )}
          </>
        )}
      </React.Fragment>
    );
  }

  AdminToolbox.displayName = `AdminToolbox(${Utils.getComponentDisplayName(WrappedComponent)})`;
  AdminToolbox.propTypes = {
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        action: PropTypes.oneOf([ADMIN_ACTIONS.CREATE, ADMIN_ACTIONS.EDIT, ADMIN_ACTIONS.DELETE]),
        url: PropTypes.string,
        message: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
    onCreateCallback: PropTypes.func,
    onChangeCallback: PropTypes.func,
    onDeleteCallback: PropTypes.func,
  };
  AdminToolbox.defaultProps = {
    actions: [],
  };
  AdminToolbox.messages = {
    cancel: 'Cancelar',
    save: 'Guardar',
    next: 'Seguinte',
    previous: 'Anterior',
  };


  return AdminToolbox;
}

export default adminToolbox;
export { ADMIN_ACTIONS };
