import { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Constants from '../../utils/constants';
import { SidebarTitle, SidebarHeader, LoadingSpinner, Button } from '../presentational/common';
import { HeaderActionButtonContainer } from '../presentational/common/ActionButtonContainer';
import SelectableButton from '../presentational/common/SelectableButton';
import { METERS_PER_UNIT } from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON';
import PropTypes from 'prop-types';
import Utils from '../../utils/utils';


const RequestListDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const RequestItem = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const RowDiv = styled.div`
  display: flex;
  margin-bottom: 15px;

  div,
  select {
    flex: 1;
  }

  > select:not(:last-child) {
    margin-right: 8px;
  }
`;

function PrintRequestList({ openTool, mapConfig, layers, map }) {
  const [printRequests, setPrintRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const authToken = localStorage.getItem('access_token');

  useEffect(() => {
    fetchPrintRequests();
  }, [mapConfig]);

  const fetchPrintRequests = async () => {
    try {
      const mapId = mapConfig.id;
      const response = await axios.get(`${Constants.API_BASE_URL}/print-request-info/`, {
        headers: {
          Authorization: `Token ${authToken}`,
        },
        params: {
          map_id: mapId,
        },
      });
      setPrintRequests(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching print requests:', error);
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  const setMapToPrint = (printRequest) => {
    const { projection, center, scale, printDPI, active_layers } = printRequest;

    // Check if drawn features are outside map's BBox
    const drawLayer = active_layers.filter((layer) => layer.layers === undefined);
    const features = new GeoJSON().readFeatures(drawLayer[0].geoJson);
    const areFeaturesContained = Utils.extentContainsAllFeatures(features, mapConfig.bbox);

    if (areFeaturesContained === false) {
      alert('Geometrias de print fora da bounding box do mapa');
      return;
    }

    // Set layer visibility based off of print request
    // Filter out { type: 'geojson', geoJson: '{"type":"FeatureCollection","features":[]}' } of print request
    const printLayers = active_layers.filter((layer) => layer.layers !== undefined);

    // List of layers' names on print request
    const printLayerNameList = printLayers.map((layer) => layer['layers'][0]);

    // Toggle visibility of map layers
    layers.forEach((current_layer) => {
      let toggleValue = printLayerNameList.includes(current_layer.layerName);
      current_layer.olLayer.setVisible(toggleValue);
    });

    map
      .getLayers()
      .getArray()
      .forEach((layer) => {
        if (layer.get('name') === 'measure') {
          layer.getSource().addFeatures(features);
        }
      });

    // Set view zoom and center based off of print request
    // As seen in of the the replies in https://gis.stackexchange.com/questions/167284/zoom-scale-in-openlayers-3
    const view = map.getView();
    const units = view.getProjection().getUnits();
    const dpi = 25.4 / 0.28;
    const mpu = METERS_PER_UNIT[units];
    const resolution = parseInt(scale) / (mpu * 39.37 * dpi);
    const zoomForResolution = view.getZoomForResolution(resolution);
    view.setZoom(zoomForResolution);
    view.setCenter(center);
  };

  return (
    <>
      <SidebarHeader>
        <SidebarTitle>Histórico de Prints</SidebarTitle>
        <HeaderActionButtonContainer>
          <SelectableButton onClick={() => openTool('PrintMap')}>
            <i style={{ marginRight: '5px' }} className="fas fa-print" /> Gerar novo print
          </SelectableButton>
        </HeaderActionButtonContainer>
      </SidebarHeader>
      <RequestListDiv>
        {printRequests.length === 0 ? (
          <p style={{ marginLeft: '20px' }}>Ainda não foi realizado nenhum pedido de impressão</p>
        ) : (
          printRequests.map((request) => (
            <RequestItem key={request.id}>
              <p>
                <strong>Nome:</strong> {request.name}
              </p>
              <p>
                <strong>Layout:</strong> {request.layout}
              </p>
              <p>
                <strong>Projeção:</strong> {request.projection}
              </p>
              <p>
                <strong>Escala:</strong> {request.scale}
              </p>
              <p>
                <strong>DPI:</strong> {request.dpi}
              </p>
              <p>
                <strong>Data de Criação:</strong> {new Date(request.created_at).toLocaleString()}
              </p>
              <RowDiv>
                <Button onClick={() => setMapToPrint(request)}>{PrintRequestList.messages.restore_map_text}</Button>
              </RowDiv>
            </RequestItem>
          ))
        )}
      </RequestListDiv>
    </>
  );
}

PrintRequestList.propTypes = {
  openTool: PropTypes.func.isRequired,
  layers: PropTypes.array.isRequired,
  map: PropTypes.object.isRequired,
};

PrintRequestList.messages = {
  restore_map_text: 'Aplicar configuração da impressão',
};

export default PrintRequestList;
