import styled from 'styled-components';
import sortIcon from '../../../images/sort.png';

const Select = styled.select`
  background: url(${sortIcon});
  background-color: transparent;
  background-size: 9px 14px;
  background-position: right 4px center;
  background-repeat: no-repeat;
  width: ${props => props.width};
  padding: 2px 14px 2px 8px;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
  color: #7f7f7f;
  border: 1px solid #7f7f7f;
  border-radius: 4px;
  cursor:pointer;

  &:focus {
    outline: none;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #7f7f7f;
  }
`;

Select.defaultProps = {
  width: 'auto',
};

export default Select;
