import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import slugify from 'slugify';

import Layer from '../../models/Layer';
import OLMap from 'ol/Map';

import './GenerateShareMap.scss';
import Constants from '../../utils/constants';

import { TextInput, Button } from '../presentational/common';

function GenerateShareMap(props) {
  const [slugText, setSlugText] = useState('');
  const [generatedLink, setGeneratedLink] = useState('');

  const handleSlugChange = (event) => {
    const slugified_text = slugify(event.target.value, { lower: true, trim: false });
    setSlugText(slugified_text);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(generatedLink);
  };

  const handleShareMapClick = async () => {
    setGeneratedLink('');
    const layersCenter = props.processMapState();
    const activeLayers = layersCenter[0];
    let mapCenter = layersCenter[1];
    if (mapCenter == undefined) {
      mapCenter = props.map.getView().getCenter();
    }
    const zoom_value = Math.round(props.map.getView().getZoom());

    let slug = slugText.trim();

    const data = {
      map: props.mapConfig.slug,
      projection: props.map.getView().getProjection().getCode(),
      center: mapCenter,
      active_layers: activeLayers,
      slug: slug,
      state_zoom: zoom_value,
    };
    const headers = {
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.post(`${Constants.API_BASE_URL}/map-state/`, data, {
        headers: headers,
      });
      setGeneratedLink(response.data.link);
    } catch (error) {
      alert('Ocorreu um erro inesperado, tente novamente com um slug diferente.');
    }
  };

  return (
    <div className="separator">
      <div className="description">{GenerateShareMap.messages.description_text}</div>
      <div className="slug-input">
        <label htmlFor="slug-field">Slug:</label>
        <TextInput id="slug-field" type="text" onChange={handleSlugChange} value={slugText} />
        <Button onClick={handleShareMapClick}>{GenerateShareMap.messages.share_map_text}</Button>
      </div>
      {generatedLink != '' && (
        <div className="slug-input">
          <label htmlFor="generated-link">Link gerado:</label>
          <TextInput id="generated_link" disabled={true} value={generatedLink} />
          <Button className="regular" onClick={handleCopyLink}>
            {GenerateShareMap.messages.copy_link}
          </Button>
        </div>
      )}
    </div>
  );
}

GenerateShareMap.messages = {
  description_text: 'Partilha de mapa',
  share_map_text: 'Gerar link para partilhar',
  copy_link: 'Copiar texto para área de transferência',
};

GenerateShareMap.propTypes = {
  map: PropTypes.instanceOf(OLMap).isRequired,
  mapConfig: PropTypes.instanceOf(Object).isRequired,
  layers: PropTypes.arrayOf(PropTypes.instanceOf(Layer)).isRequired,
  processMapState: PropTypes.func.isRequired,
};

export default GenerateShareMap;
