import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function CurrentMapField({ formData, onChange, idSchema }) {
  const detailURL = useSelector((state) => state.map.map.detailURL);
  useEffect(() => {
    if (!formData) {
      onChange(detailURL);
    }
  }, []);

  return (
    <input
      type="hidden"
      id={idSchema && idSchema.$id}
      value={formData == null ? '' : formData}
    />
  );

}

CurrentMapField.propTypes = {
  /* eslint-disable react/require-default-props, react/forbid-prop-types */
  formData: PropTypes.string,
  idSchema: PropTypes.object,
  /* eslint-enable react/require-default-props, react/forbid-prop-types */
  onChange: PropTypes.func.isRequired,
};


export default CurrentMapField;
