import axios from 'axios';
import Constants from '../utils/constants';
import { createAsyncThunk } from '@reduxjs/toolkit';


export const fetchMapList = createAsyncThunk(
  'mapList/fetchMapList',
  async () => {
    try {
      const response = await axios.get(`${Constants.API_BASE_URL}/maps/`);
      return response.data.map(({ id, name, slug }) => ({ id, name, slug }));
    } catch (error) {
      throw error;
    }
  }
);
