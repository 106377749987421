import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import App from './containers/App';
import registerServiceWorker from './registerServiceWorker';
import store from './store/configureStore';
import './common/OLCesium';



// TODO: Check flow to control access tokens
const token = localStorage.getItem('access_token');

if (token) {
  axios.defaults.headers.common.Authorization = `Token ${token}`;
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <App store={store} />
);


registerServiceWorker();
