import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { EXTRA_SMALL_MAX } from './mediaBreakpoints';

const CollapseButton = styled.button`
  position: absolute;
  background: none;
  border: none;
  color: #7f7f7f;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media(max-width: ${EXTRA_SMALL_MAX}) {
    i {
      transform: rotate(90deg);
    }
  }
`;

const SidebarContainer = styled.div`
  position: relative;
  height: 100%;
  width: 400px;
  background: rgba(255, 255, 255, 0.98);
  overflow-y: scroll;

  @media(max-width: ${EXTRA_SMALL_MAX}) {
    flex-direction: row;
    width: 100%;
    height: ${(props) => (props.opened ? 'calc(50vh - 50px)' : '0')};
    transition: all 0.5s ease;
  }
`;

const Sidebar = ({ collapseClick, children, opened }) => (
  <SidebarContainer opened={opened}>
    <CollapseButton onClick={collapseClick}>
      <i className="fas fa-caret-right" />
    </CollapseButton>
    {children}
  </SidebarContainer>
);

Sidebar.propTypes = {
  collapseClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

Sidebar.defaultProps = {
  children: null,
};

export default Sidebar;
