import React from 'react';
import PropTypes from 'prop-types';
import adminToolbox, { ADMIN_ACTIONS } from '../Admin/AdminComponent';
import { SelectableButton, SidebarHeader, SidebarTitle } from '../presentational/common';
import SidebarContent from '../presentational/SidebarContent';
import OWSProvidersList from './OWSProviders/OWSProvidersList';
import Constants from '../../utils/constants';

const Settings = ({ renderAdminButtons }) => (
  <React.Fragment>
    <SidebarHeader>
      <SidebarTitle>{Settings.messages.settings}</SidebarTitle>
    </SidebarHeader>
    <SidebarContent>
      {renderAdminButtons && renderAdminButtons()}
    </SidebarContent>
    <OWSProvidersList
      actions={[
        {
          action: ADMIN_ACTIONS.CREATE,
          url: `${Constants.API_BASE_URL}/layers/providers/`,
          component: SelectableButton,
          children: (
            <React.Fragment>
              <i className="fas fa-magic" /> Adicionar Provider
            </React.Fragment>
          ),
        },
      ]}
    />
  </React.Fragment>
);

Settings.propTypes = {
  renderAdminButtons: PropTypes.func,
};

Settings.defaultProps = {
  renderAdminButtons: null,
};

Settings.messages = {
  settings: 'Definições',
};

export default adminToolbox(Settings);
