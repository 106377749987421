import { createSlice } from '@reduxjs/toolkit';
import { fetchMap, fetchMapState } from '../thunks/mapThunk';

const initialState = {
  map: null,
  mapConfig : null,
  projections: [],
  currentProjection: null,
  isFetching: false,
  error: false,
  mapState: null,
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setProjection: (state, action) => {
      // Selected SRID
      const selected_proj = action.payload;
      // Get projection object from "projections" list by selected SRID
      let newproj = state.projections.filter((x) => x.srid == selected_proj)[0];
      // Set new currentProjection
      state.currentProjection = newproj;
    },
    clearMapState: (state, action) => {
      state.mapState = null;
    }
  },
  extraReducers: (builder) => {
    builder
    // fetchMap
      .addCase(fetchMap.pending, (state) => {
        state.isFetching = true;
        state.error = false;
      })
      .addCase(fetchMap.fulfilled, (state, action) => {
        state.isFetching = false;
        state.map = action.payload.map;
        state.currentProjection = action.payload.mapProjection;
        state.mapConfig = action.payload.mapConfig;
        state.projections = action.payload.projectionList;
        state.error = false;
      })
      .addCase(fetchMap.rejected, (state) => {
        state.isFetching = false;
        state.error = true;
      })
      // fetchMapState
      .addCase(fetchMapState.pending, (state) => {
        state.isFetching = true;
        state.error = false;
      })
      .addCase(fetchMapState.fulfilled, (state, action) => {
        state.isFetching = false;
        state.mapState = action.payload.mapState;
        state.error = false;
      })
      .addCase(fetchMapState.rejected, (state) => {
        state.isFetching = false;
        state.error = true;
      });
  },
});

export const { setProjection, clearMapState } = mapSlice.actions;
export default mapSlice.reducer;
