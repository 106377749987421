// TODO: unit testing
/**
 * Class used for creating subclasses with event listening behavior.
 */
class Observable {
  constructor() {
    this.observers = new Map();
  }

  /**
   * Add a listener for a certain event type
   * @param {string} eventType event type to listen
   * @param {function} listener listener function
   */
  addObserver(eventType, listener) {
    if (!this.observers.has(eventType)) {
      this.observers.set(eventType, []);
    }

    const eventObservers = this.observers.get(eventType);

    // only add the event listener function if it's not already on the list
    if (eventObservers.indexOf(listener) === -1) {
      eventObservers.push(listener);
    }
  }

  /**
   * Remove a registered event listener
   * @param eventType event type to listen
   * @param listener listener function
   */
  removeObserver(eventType, listener) {
    const eventObservers = this.observers.get(eventType);

    // ensure that the provided event type is on the map and if it has registered listener functions
    if (!(eventObservers && eventObservers.length)) {
      return;
    }

    // check for the existence of the listener on the list and proceed to its removal
    const observerIndex = eventObservers.indexOf(listener);

    if (observerIndex > -1) {
      eventObservers.splice(observerIndex, 1);
    }
  }

  /**
   * Dispatch all the listeners for a certain event
   * @param {string} eventType event type to trigger
   * @param {...object} parameters parameters to pass to the listener functions
   */
  dispatchEvent(eventType, ...parameters) {
    const eventObservers = this.observers.get(eventType);

    if (!(eventObservers && eventObservers.length)) {
      return;
    }

    eventObservers.forEach(listener => listener(...parameters));
  }
}

export default Observable;
