import proj4 from 'proj4';
import { defaults, Zoom } from 'ol/control';
import { get as getProjection } from 'ol/proj';

import { LAYER_TYPES } from '../factories/LayerFactory';
import Layer from './Layer';
import Utils from '../utils/utils';
import WKT from 'ol/format/WKT';
import OLMap from 'ol/Map';
import View from 'ol/View';
import { transform } from 'ol/proj';
import 'ol/ol.css';
import { Vector } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Circle from 'ol/style/Circle';

const LINECOLOR = "#f44e42"
import './Map.scss';
const defaultSourceProjection = 'EPSG:4326';

class Map {
  constructor(
    id, name, slug, minZoom, maxZoom, projection,
    detailURL, mapBBox, initialExtent, remainingConfigs,
  ) {
    this.id = id;
    this.name = name;
    this.slug = slug;
    this.projection = projection;
    this.detailURL = detailURL;
    this.initialExtent = initialExtent;

    // TODO: check if only one base layer is visible instead of forcing the visibility of the first
    // one. Do the same for LayerSwitcher.

    // if projection isn't register, we need to add using proj4
    if (!getProjection(projection.name)) {
      proj4.defs(
        projection.name,
        projection.proj4,
      );

      // get the new projection and transform 4326 extent to the new projection extent
      const newProj = getProjection(projection.name);
      const { bbox } = projection;
      const extent = Utils.transformExtentToProjection(
        [bbox[1], bbox[2], bbox[3], bbox[0]],
        'EPSG:4326',
        projection.name,
      );
      newProj.setExtent(extent);
    }

    if (mapBBox) {
      const formatter = new WKT();
      // extent is in format: SRID;WKT string so we split
      const extentWithSRID = mapBBox.split(';');
      // Read the Polygon Geometry from the WKT string, and get the extent for that geometry
      const extent = Utils.transformExtentToProjection(formatter.readGeometry(extentWithSRID[1])
        .getExtent(), 'EPSG:4326', projection.name);
      // Add extent to the mapViewParams
      this.extent = extent;
    }

    // Map View Parameters
    const mapViewParams = {
      zoom: minZoom,
      minZoom,
      maxZoom,
      projection: projection.name,
      extent: this.extent,
      constrainOnlyCenter: true,
    };

    const zoomControl = new Zoom({
      className: 'zoom-btn'})

    // Extract layers configurations
    const { base_layers: baseLayers, overlay_layers: overlayLayers } = remainingConfigs;

    this.layers = Map.parseLayers(baseLayers, overlayLayers);

    this.olMap = new OLMap({
      layers: this.layers.map(layer => layer.olLayer),
      controls: [zoomControl],
      view: new View(mapViewParams),
    });
    const drawLayer = new Vector({
      source: new VectorSource(),
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: LINECOLOR,
          width: 2,
        }),
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: LINECOLOR,
          }),
        }),
      }),
    });
    drawLayer.set('name', 'measure')
    this.olMap.addLayer(drawLayer);
    // Search layers configurations
    const { searchable_layers: searchableLayers } = remainingConfigs;
    this.searchableLayers = searchableLayers
      .map(({ id: layerID, alias, attributes }) =>
        ({
          id: layerID,
          alias,
          attributes: attributes.filter(attr => attr.show_in_results)
        }));

    this.addTemporaryLayer = this.addTemporaryLayer.bind(this);
    this.addLayerToMap = this.addLayerToMap.bind(this);

    if(this.extent) {
      // Calculate the new min zoom level based on the extent
      const newMinZoom = this.calculateNewMinZoom(this.extent);
      minZoom = Math.round(newMinZoom);

      // Update the min zoom level in the map view
      const view = this.olMap.getView();
      view.setMinZoom(minZoom);
    }
  }

  static parseLayers(baseLayersJSON, overlayLayersJSON) {
    const baseLayers = baseLayersJSON.map((layerInfo) => {
      const {
        id,
        url: detailURL,
        alias: name,
        type: sourceType,
        order,
        is_default_base_layer: visible,
        wms_layer: { service_url: url, layer_name: wmsLayerName, style: wmsLayerStyle } = {},
      } = layerInfo;

      const wmsParams = {
        LAYERS: wmsLayerName,
        ...(wmsLayerStyle != null && {STYLES: wmsLayerStyle})
      };

      return new Layer(
        LAYER_TYPES.BASE,
        {
          id,
          name,
          order,
          detailURL,
          sourceType,
          visible,
          url,
          extent: this.extent,
          wmsParams,
        },
      );
    }).sort((layer1, layer2) => layer1.order - layer2.order);

    const overlayLayers = overlayLayersJSON.map((layerInfo) => {
      const {
        id,
        url: detailURL,
        alias: name,
        type: sourceType,
        order,
        is_visible: visible,
        opacity,
        wms_layer: { service_url: wmsURL, layer_name: wmsLayerName, style: wmsLayerStyle } = {},
        wfs_layer: { service_url: wfsURL, feature_typename: featureTypeName } = {},
        edition_attributes: editionAttributes,
      } = layerInfo;

      const wmsParams = {
        LAYERS: wmsLayerName,
        ...(wmsLayerStyle != null && {STYLES: wmsLayerStyle})
      };

      return new Layer(LAYER_TYPES.OVERLAY, {
        id,
        name,
        order,
        detailURL,
        sourceType,
        visible,
        url: wmsURL || wfsURL,
        extent: this.extent,
        // in the api the opacity is a percentage
        opacity: (opacity / 100),
        featureTypeName,
        wmsParams,
        editionAttributes,
      });
    }).sort((layer1, layer2) => layer1.order - layer2.order);



    return baseLayers.concat(overlayLayers);
  }

  /**
   * Transforms a coordinate from the defined source Projection to the Projection used
   * by the map view.
   *
   * @param {ol.Coordinate} coordinate Coordinate to transform
   * @returns {ol.Coordinate} Coordinate in the new Projection
   */
  coordinateToMapProjection(coordinate) {
    return transform(
      coordinate, defaultSourceProjection,
      this.projection.name,
    );
  }

  /**
   * Transforms a coordinate from the map view Projection to the defined
   * source Projection.
   *
   * @param {ol.Coordinate} coordinate Coordinate to transform
   * @returns {ol.Coordinate} Coordinate in the new projection
   */
  coordinateFromMapProjection(coordinate) {
    return transform(
      coordinate, this.projection.name,
      defaultSourceProjection,
    );
  }

  /**
   * Add a temporary layer to the map (is added on top of the others and isn't managed by the
   * map layer group)
   * @param layer
   */
  addTemporaryLayer(layer) {
    layer.setMap(this.olMap);
  }

  /**
   * Adds a layer to the map
   *
   * @param {ol.layer.Base} layer Layer to add to the map
   * @throws {Error} This method throws an error if the layer to add has an id that already
   * exists on the map
   */
  addLayerToMap(layer) {
    const mapLayers = this.olMap.getLayers();

    // Find the index of the last layer with the same type of the layer to add, so the new layer
    // is added on top of the existing ones.
    const lastIndexOfType = mapLayers.getArray()
      .map(existingLayer => existingLayer.get('type'))
      .lastIndexOf(layer.get('type'));

    // If already exists layers of that type, the new layer is added after it otherwise a default
    // position is assumed (starting of the list if it is a base layer or the end of it if it is
    // an overlay)
    if (lastIndexOfType >= 0) {
      mapLayers.insertAt(lastIndexOfType + 1, layer);

      // If the added layer is a base layer and set to be visible it is necessary to hide the
      // previously visible one
      if (layer.get('type') === LAYER_TYPES.BASE && layer.getVisible()) {
        const visibleBaseLayer = mapLayers.getArray()
          .find(existingLayer => existingLayer.get('type') === LAYER_TYPES.BASE &&
            existingLayer.getVisible());

        if (visibleBaseLayer) {
          visibleBaseLayer.setVisible(false);
        }
      }
    } else {
      switch (layer.get('type')) {
        case LAYER_TYPES.BASE:
          mapLayers.insertAt(0, layer);
          break;
        case LAYER_TYPES.OVERLAY:
        default:
          mapLayers.push(layer);
          break;
      }
    }
  }

  /**
  #27888 Calculate new min_zoom based on extent to give the user more zoom out
  */
  calculateNewMinZoom(extent) {
    const view = this.olMap.getView();

    // Get the size of the map via window because the size of the map is not available yet
    const mapSize = [window.innerWidth, window.innerHeight];

    const resolution = view.getResolutionForExtent(extent, mapSize);
    const zoom = view.getZoomForResolution(resolution);

    // Adjust this factor to control how much further out the user can zoom
    const zoomOutFactor = 5;

    // Ensure min zoom is not negative or lower then the default value
    return Math.max(1, zoom - zoomOutFactor);
  }
}

export default Map;
