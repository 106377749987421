import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, TextInput } from '../presentational/common';
import { containsXY } from 'ol/extent';
import { transform } from 'ol/proj';
import PropTypes from 'prop-types';
import OLMap from 'ol/Map';
import MeasureTools from '../Measure/MeasureTools';

const CRSDiv = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const CRSSeparator = styled.div`
  border-top: 1px solid #ccc;
  margin-bottom: 7%;
`;

const CRSLabel = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: #666;
`;

const CRSInputBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: 'row';
  justify-content: 'space-around';
`;

const CoordinateInput = styled(TextInput)`
  display: flex;
  flex: 1;
  margin-right: 2%;
`;

const CoordinateButton = styled(Button)`
  display: flex;
  flex: 1;
  max-width: fit-content;
  justify-content: center;
`;

function CoordSearch(props) {
  const {mapConfig} = useSelector((state) => state.map);
  const [ptCoordinates, setPTCoordinates] = useState('');

  const [wgsCoordinates, setWGSCoordinates] = useState('');


  const handlePTCoordInput = (event) => {
    setPTCoordinates(event.target.value);
  };

  const handleWGSCoordInput = (event) => {
    setWGSCoordinates(event.target.value);
  };

  const convertCoordinatesToMapProjection = (coordList, coordProjection) => {
    // Transform coordinates to map's projection
    return transform(coordList, coordProjection, props.map.getView().getProjection());
  };


  const setViewToCoordPoint = (coordinates) => {
    // Add Marker and Tooltip before centering view to coordinates
    props.onAddMarker(coordinates);
    // set view's center to coordinate and zoom in
    props.map.getView().setCenter(coordinates);
    props.map.getView().setZoom(18);
  };

  const handlePTGoTo = () => {
    // Regex to capture "-46265.87,167760.28" as x:-46265.87 and y:167760.28
    const regexPTCoords = new RegExp('^(?<x>-?\\d+\\.\\d+)\\,\\s?(?<y>-?\\d+\\.\\d+)$');
    const coordinateMatch = ptCoordinates.match(regexPTCoords);
    if (coordinateMatch) {
      const x = parseFloat(coordinateMatch.groups.x);
      const y = parseFloat(coordinateMatch.groups.y);
      const coordAsList = [x, y];
      const convertedCoordinates = convertCoordinatesToMapProjection(coordAsList, 'EPSG:3763');
      if (verifyCoordinatesBoundingBox(convertedCoordinates)) {
        setViewToCoordPoint(convertedCoordinates);
      } else {
        // Out of bounding box
        alert(CoordSearch.messages.errorOutOfBbox);
      }
    } else {
      // Coordinates Invalid
      alert(CoordSearch.messages.errorInvalidCoords);
    }
  };

  const handleWGSGoTo = () => {
    // Regex to capture "41.177711,-8.684491" as lat:41.177711 and lon:-8.684491
    const regex = new RegExp('^(?<lat>-?\\d+\\.\\d+)\\,\\s?(?<lon>-?\\d+\\.\\d+)$');
    const coordinateMatch = wgsCoordinates.match(regex);
    if (coordinateMatch) {
      const lon = parseFloat(coordinateMatch.groups.lon);
      const lat = parseFloat(coordinateMatch.groups.lat);
      // Validate lat lon
      if (lon >= -180 && lon <= 180 && lat >= -90 && lat <= 90 ) {
        const coordAsList = [lon, lat];
        const convertedCoordinates = convertCoordinatesToMapProjection(coordAsList, 'EPSG:4326');
        if(verifyCoordinatesBoundingBox(convertedCoordinates)){
          setViewToCoordPoint(convertedCoordinates);
        } else {
          // Out of bounding box
          alert(CoordSearch.messages.errorOutOfBbox);
        }
      } else {
        // Coordinates Invalid
        alert(CoordSearch.messages.errorInvalidCoords);
      }
    } else {
      // Coordinates Invalid
      alert(CoordSearch.messages.errorInvalidCoords);
    }
  };

  const verifyCoordinatesBoundingBox = (coordinates) => {
    if(mapConfig.bbox) {
      if(!containsXY(mapConfig.bbox, coordinates[0], coordinates[1])) {
        return false;
      }
    }
    return true
  }


  return (
  <>
    <CRSDiv>
      <CRSSeparator/>
      <CRSLabel>{CoordSearch.messages.insert_coordinates} {CoordSearch.messages.pt_coordinates}</CRSLabel>
      <CRSInputBox>
        <CoordinateInput
          id="pt-coord-input"
          type="text"
          placeholder="ex: 38229.96, -385651.04"
          value={ptCoordinates}
          onChange={(event) => handlePTCoordInput(event)}
        />
        <CoordinateButton onClick={handlePTGoTo}>{CoordSearch.messages.go_to_coordinate}</CoordinateButton>
      </CRSInputBox>
    </CRSDiv>
    <CRSDiv>
      <CRSSeparator/>
      <CRSLabel>{CoordSearch.messages.insert_coordinates} {CoordSearch.messages.wgs_coordinates}</CRSLabel>
      <CRSInputBox>
        <CoordinateInput
          id="wgs-coord-input"
          type="text"
          placeholder="ex: 41.205849, -8.324044"
          value={wgsCoordinates}
          onChange={(event) => handleWGSCoordInput(event)}
        />
        <CoordinateButton onClick={handleWGSGoTo}>{CoordSearch.messages.go_to_coordinate}</CoordinateButton>
      </CRSInputBox>
    </CRSDiv>
  </>)
}
CoordSearch.propTypes = {
  map: PropTypes.instanceOf(OLMap).isRequired,
};



CoordSearch.messages = {
  latitude: 'Latitude',
  longitude: 'Longitude',
  insert_coordinates : 'Inserir coordenadas',
  'pt_coordinates': 'ETRS89 PT-TM06',
  'wgs_coordinates': 'WGS 84',
  go_to_coordinate: 'Centrar coordenada',
  x: 'X',
  y: 'Y',
  errorInvalidCoords: 'Coordenadas Inválidas',
  errorOutOfBbox: 'Coordenadas fora da bounding box'
};
export default CoordSearch;
