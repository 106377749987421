import React from 'react';
import styled from 'styled-components';
import { ImageBackground, WelcomeHeader } from '../components/presentational';
import backgroundImage from '../images/client-assets/background.jpg';
import RegisterForm from '../components/Account/RegisterForm';
import { EXTRA_SMALL_MAX } from '../components/presentational/mediaBreakpoints';
import { Link } from '../components/presentational/common';
import { Link as RouterLink } from 'react-router-dom';

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const WrapperDiv = styled.div`
  max-width: 350px;
  margin: 10px;

  @media(max-width: ${EXTRA_SMALL_MAX}) {
    max-width: none;
    width: 100%;
    margin: 0;
    padding: 10px;
  }
`;

const BackLink = styled(Link.withComponent(RouterLink))`
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
`;


const RegisterContainer = () => (
  <React.Fragment>
    <ImageBackground image={backgroundImage} />
    <BackLink to='/'>
      <i className="fas fa-angle-left" /> Voltar atrás
    </BackLink>
    <CenteredDiv>
      <WrapperDiv>
        <WelcomeHeader
          welcomeText={RegisterContainer.messages.welcomeMessage}
          descriptionText={RegisterContainer.messages.description}
          textAlign="center"
          marginBottom="35px"
        />
        <RegisterForm />
      </WrapperDiv>
    </CenteredDiv>
  </React.Fragment>
);

RegisterContainer.messages = {
  welcomeMessage: 'Registo',
  description: 'Registe-se para ter a sua conta',
};

export default RegisterContainer;
