import axios from 'axios';
import Feature from 'ol/Feature';
import { format as formatCoords } from 'ol/coordinate';
import WKT from 'ol/format/WKT';
import Point from 'ol/geom/Point';
import { Vector } from 'ol/layer';
import { toDegrees, toRadians } from 'ol/math';
import { transform as transformProj } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';
import Icon from 'ol/style/Icon';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Constants from '../../utils/constants';
import { getLineStyle } from './featureStyles';

const VideosContainer = styled.div`
  &.measuring div[data-measure="false"] {
    position: relative;

    &::after {
      content:"";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.6);
      cursor: not-allowed;
    }

    video {
      pointer-events: none;
    }
  }
`;

/* *****************************************************************************
 * MEDIÇÕES
 ****************************************************************************** */

const calculatePosition = (leftCoordinate, rightCoordinate, leftPixel,
                           rightPixel, leftBeta, rightBeta, leftBearing, rightBearing) => {
  const [e1, n1] = leftCoordinate;
  const [x1] = leftPixel.map(v => v * 3.6);
  const [e2, n2] = rightCoordinate;
  const [x2] = rightPixel.map(v => v * 3.6);
  const R1 = toRadians(leftBearing);
  const R2 = toRadians(rightBearing);

  const beta1 = toRadians(leftBeta);
  const beta2 = toRadians(rightBeta);

  const r1 = R1 + beta1 + Math.atan((x1 - 960) / 1046);
  const r2 = R2 + beta2 + Math.atan((x2 - 960) / 1046);

  const n = (e2 - e1 + n1 * Math.tan(r1) - n2 * Math.tan(r2)) /
    (Math.tan(r1) - Math.tan(r2));
  const e = e2 + (n - n2) * Math.tan(r2);

  return [e, n];
};


/* ******************************************************************************
 * / MEDIÇÕES
 ****************************************************************************** */

const getBearing = (coord1, coord2) => {
  const [lon1, lat1,] = coord1.map(toRadians);
  const [lon2, lat2,] = coord2.map(toRadians);

  const y = Math.sin(lon2 - lon1) * Math.cos(lat2);
  const x = Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);

  const bearing = Math.atan2(y, x);

  return (toDegrees(bearing) + 360) % 360;
};

const setFeatureAtTime = (time, car, linestring, map, isLocked, rotateMap) => {
  const currentCoord = linestring.getGeometry()
    .getCoordinateAtM(time, true);
  const nextCoord = linestring.getGeometry()
    .getCoordinateAtM(time + 250e3, true);

  const bearing = getBearing(
    transformProj(currentCoord, map.projection.name, 'EPSG:4326'),
    transformProj(nextCoord, map.projection.name, 'EPSG:4326'),
  );

  car.setGeometry(new Point(currentCoord));

  if (rotateMap) {
    map.olMap.getView()
      .setRotation(toRadians(-bearing));
  } else {
    car.getStyle()
      .getImage()
      .setRotation(toRadians(bearing));
  }

  if (isLocked) {
    map.olMap.getView()
      .setCenter(car.getGeometry()
        .getCoordinates());
  }
};

const getClickPosition = (evt) => {
  const rect = evt.currentTarget.getBoundingClientRect();

  return [
    evt.clientX - rect.left,
    evt.clientY - rect.top,
  ];

};

const availablePositions = ['FL', 'FR', 'CL', 'CR', 'BC'];

const VideoPlayer = ({ map, carTrack, onClose, onCarTrackSeen, visualizationProjectID, boundaryFeature }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(
    localStorage.getItem('fullscreen-mode') === 'true');
  const [currentPlayPercentage, setCurrentPlayPercentage] = useState(0);
  const [playSpeed, setPlaySpeed] = useState('1');
  const [lockCamera, setLockCamera] = useState(true);
  const [lockRotation, setLockRotation] = useState(false);
  const [zoomEnabledVideo, setZoomEnabledVideo] = useState(null);
  const [zoomedImage, setZoomedImage] = useState(null);
  const [visibleCameras, setVisibleCameras] = useState(localStorage.getItem('visible-cameras') ? JSON.parse(localStorage.getItem('visible-cameras')) : availablePositions);

  // MEDIÇÕES

  const [measurements, setMeasurements] = useState([]);
  const [clickedPositions, setClickedPositions] = useState({});
  const [storedPositions, setStoredPositions] = useState([]);
  const [isMeasuring, setIsMeasuring] = useState(false);
  const measurementsLayer = useRef();

  // /MEDIÇÕES

  const carFeature = useRef();
  const trackLinestring = useRef();
  const carTrackLayer = useRef();
  const videoNodes = useRef({});
  const playerRef = useRef(null);

  useEffect(() => {
    Object.values(videoNodes.current).forEach((v) => {
      v.playbackRate = playSpeed;
    });
  }, [playSpeed, carTrack]);


  useEffect(() => {
    Object.values(videoNodes.current).forEach((v) => {
      // don't run this code if it's not a 360 video
      if (v.dataset["is-360"] === 'false') return;
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
      const player = playerRef.current = videojs(v, {}, () => {
        // videojs.log('player is ready');
        handlePlayerReady && handlePlayerReady(player);
      });
      // player.responsive(true);
      player.vr({projection: '360', forceCardboard: false});
    })
  }, [videoNodes, carTrack]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      // if (player && !player.isDisposed()) {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      // videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      // videojs.log('player will dispose');
    });
  };

  useEffect(() => {
    carFeature.current = new Feature({ id: 'car' });
    carFeature.current.setStyle(
      new Style({
        image: new Icon({
          src: '/arrowcar.png',
          imgSize: [64, 64],
        }),
      }),
    );

    carTrackLayer.current = new Vector({
      source: new VectorSource({
        features: [carFeature.current],
      }),
      map: map.olMap,
      style: getLineStyle(),
    });

    if (boundaryFeature != null) {
      carTrackLayer.current.getSource()
        .addFeature(boundaryFeature);
    }

    const mapClickHandler = ({ pixel, coordinate }) => {
      const feature = map.olMap.forEachFeatureAtPixel(
        pixel,
        featureAtPixel => (featureAtPixel !== carFeature.current ?
          featureAtPixel : null),
        { layerFilter: layer => layer === carTrackLayer.current },
      );

      if (feature) {
        const timeAtClick = feature.getGeometry()
          .getClosestPoint(coordinate)[2];

        if (Object.keys(videoNodes.current).length === 0) {
          return;
        }

        const masterVideo = videoNodes.current[[0]];

        masterVideo.currentTime =
          ((timeAtClick - carTrack.first_utc_timestamp) / 1e6) +
          parseFloat(masterVideo.dataset.offset);
      }
    };

    map.olMap.on('singleclick', mapClickHandler);

    const linestring = (new WKT()).readFeature(carTrack.route,
      {
        featureProjection: map.projection.name,
        dataProjection: 'EPSG:4326'
      });

    linestring.set('seen', carTrack.seen);
    carTrackLayer.current.getSource()
      .addFeature(linestring);

    trackLinestring.current = linestring;

    // MEDIÇÕES

    measurementsLayer.current = new Vector({
      source: new VectorSource({}),
      map: map.olMap,
      style: feature => new Style({
        image: new CircleStyle({
          radius: 10,
          stroke: new Stroke({
            color: '#fff',
          }),
          fill: new Fill({
            color: '#3399CC',
          }),
        }),
        text: new Text({
          text: feature.get('name')
            .toString(),
          fill: new Fill({
            color: '#fff',
          }),
        }),
      }),
    });

    // / MEDIÇÕES

    setIsPlaying(false);
    setCurrentPlayPercentage(0);

    return () => {
      carTrackLayer.current.setMap(null);
      map.olMap.un('singleclick', mapClickHandler);
      map.olMap.getView()
        .setRotation(0);

      // MEDIÇÕES
      measurementsLayer.current.setMap(null);
      // / MEDIÇÕES

    };
  }, [boundaryFeature, carTrack, map.olMap, map.projection.name]);

  const handleSeenVideoButton = (seen) => {
    const message = seen ? 'Quer marcar este vídeo como visto?' :
      'Quer anular marcação deste vídeo como visto?';

    // eslint-disable-next-line no-alert
    if (window.confirm(message)) {
      axios.request({
        method: seen ? 'post' : 'delete',
        url: `${Constants.API_BASE_URL}/geovideo/visualization-projects-track-views/`,
        data: {
          visualization_project: visualizationProjectID,
          car_track: carTrack.id
        }
      })
        .then(
          () => {
            onCarTrackSeen(carTrack.id, seen);
            carTrackLayer.current.getSource()
              .getFeatures()
              .forEach(feat => feat.set('seen', seen));
          }
        );
    }
  };

  const getOriginalFrameForTime = (video, time, position) => {
    const frameUrl = `${Constants.API_BASE_URL}/geovideo/get-original-frame/?track=${video}&time=${time}${position ?
      `&position=${position.join(',')}` : ''}`;

    if (!position) {
      window.open(frameUrl);
      return;
    }

    setZoomedImage(frameUrl);
  };

  const widgetElement = document.getElementById('geovideo-widget');

  /* ******************************************************************************
   * MEDIÇÕES
   ****************************************************************************** */

  const handleMeasureButtonClick = () => {
    if (!isMeasuring) {
      videoNodes.current[[0]].pause();
    } else {
      setClickedPositions({});
      setMeasurements([]);

      measurementsLayer.current.getSource()
        .clear();
    }

    setIsMeasuring(!isMeasuring);
  };

  /* ******************************************************************************
   * / MEDIÇÕES
   ****************************************************************************** */

  return ReactDOM.createPortal(
    (
      <VideosContainer
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '15px 55px'
        }}
        className={isMeasuring ? 'measuring' : ''}
      >
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'minmax(50%, 100%) minmax(50%, 100%)',
          gridTemplateAreas: '\'FL FR\' \'CL CR\' \'BC BC\'',
          minHeight: '0',
          overflow: 'scroll',
        }}>
          {carTrack.tracks.map((track, idx) => (
              <div
                data-measure={track.frequency >= 18}
                key={track.id}
                style={{
                  gridArea: track.position,
                  position: 'relative',
                  minWidth: '0',
                  display: visibleCameras.indexOf(track.position) !== -1 ? 'initial' : 'none',
                }}>
                {(track.id === zoomEnabledVideo && zoomedImage) && (
                  <div style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1,
                  }}>
                    <img src={zoomedImage} alt=""/>
                    <button
                      type="button"
                      style={{
                        position: 'absolute',
                        top: '15px',
                        right: '15px'
                      }}
                      onClick={() => {
                        setZoomedImage(null);
                      }}
                    >
                      <i className="fas fa-times"/>
                    </button>
                  </div>)}
                <div style={{
                  position: 'relative',
                  display: 'inline-block',
                  ...(isFullscreen ? { width: '100%' } : {})
                }}>
                  <video
                    data-is-360={track.is_360}
                    {...(track.is_360 ? {
                      className: "video-js",
                      width: "1024",
                      height: "512"
                    } : {})}
                    crossOrigin="anonymous"
                    style={{
                      padding: '0 5px', ...(isFullscreen ? {
                          width: '100%',
                          maxWidth: '1024px'
                        } :
                        { maxWidth: '100%' })
                    }}
                    data-offset={track.offset}
                    muted
                    onTimeUpdate={idx === 0 ? ({ target }) => {
                      const currentUtcTime = (carTrack.first_utc_timestamp +
                        target.currentTime *
                        1e6) - parseFloat(target.dataset.offset);

                      setFeatureAtTime(
                        currentUtcTime,
                        carFeature.current,
                        trackLinestring.current,
                        map,
                        lockCamera,
                        lockRotation,
                      );

                      setCurrentPlayPercentage(
                        ((target.currentTime -
                            parseFloat(target.dataset.offset)) /
                          carTrack.duration) * 100,
                      );
                    } : undefined}
                    onSeeked={idx === 0 ?
                      ({ target: { currentTime, dataset: { offset } } }) => {
                        const currentUtcTime = (carTrack.first_utc_timestamp +
                            currentTime * 1e6) -
                          parseFloat(offset);

                        setFeatureAtTime(
                          currentUtcTime,
                          carFeature.current,
                          trackLinestring.current,
                          map,
                          lockCamera,
                          lockRotation,
                        );

                        Object.entries(videoNodes.current)
                          .filter(k => k[0] !== "0")
                          .forEach(([, videoNode]) => {
                            videoNode.currentTime =
                              currentTime + parseFloat(videoNode.dataset.offset);
                          });
                      } : undefined}
                    onPlay={idx === 0 ? () => {
                      Object.entries(videoNodes.current)
                        .filter(k => k[0] !== "0")
                        .forEach(([, videoNode]) => {
                          if (videoNode !== null) {
                            const playPromise = videoNode.play();
                            if (playPromise !== undefined) {
                              playPromise
                                .then(() => {})
                                .catch(error => {
                                  console.log("Play ERROR:", error);
                                });
                            }
                          }
                        });
                      return true;
                    } : undefined}
                    onPause={idx === 0 ? ({ target: { currentTime } }) => {
                      Object.entries(videoNodes.current)
                        .filter(k => k[0] !== "0")
                        .forEach(([, videoNode]) => {
                          console.log("SETTING VIDEO TO:", currentTime + parseFloat(videoNode.dataset.offset))
                          videoNode.pause();
                          videoNode.currentTime =
                            currentTime + parseFloat(videoNode.dataset.offset);
                        });
                      return true;
                    } : undefined}
                    ref={(videoNode) => {
                      videoNodes.current[[idx]] = videoNode;
                    }}
                    onClick={(evt) => {
                      const position = getClickPosition(evt);
                      const [xPos, yPos] = position;
                      const { width: videoWidth, height: videoHeight } = evt.currentTarget.getBoundingClientRect();


                      if (track.id === zoomEnabledVideo) {
                        const positionPercentage = [xPos / videoWidth,
                          yPos / videoHeight];

                        getOriginalFrameForTime(track.id,
                          evt.currentTarget.currentTime,
                          positionPercentage);
                      }

                      // MEDIÇÃO
                      if (isMeasuring) {
                        const utcTime = (carTrack.first_utc_timestamp +
                            evt.currentTarget.currentTime * 1e6) -
                          parseFloat(track.offset);

                        const currentCoord = trackLinestring.current
                          .getGeometry()
                          .getCoordinateAtM(utcTime, true);
                        const nextCoord = trackLinestring.current
                          .getGeometry()
                          .getCoordinateAtM(utcTime + 250e3, true);

                        const bearing = getBearing(
                          transformProj(currentCoord, map.projection.name,
                            'EPSG:4326'),
                          transformProj(nextCoord, map.projection.name,
                            'EPSG:4326'),
                        );

                        const betas = {
                          'FL': 113,
                          'FR': 67,
                          'CL': -113,
                          'CR': -67,
                          // 'BC': 180,
                        };

                        const currentBeta = betas[track.position];

                        if (currentBeta === undefined) {
                          throw new Error('Missing beta for this camera!');
                        }

                        const cameraDist = Math.sqrt(0.263 ** 2 + 0.21 ** 2);
                        const cameraAngle = currentBeta - bearing;

                        const cameraCoord = [
                          Math.sin(cameraAngle) * cameraDist +
                          currentCoord[0],
                          Math.cos(cameraAngle) * cameraDist +
                          currentCoord[1],
                        ];

                        const values = {
                          coordinates: transformProj(
                            cameraCoord,
                            map.projection.name,
                            'EPSG:3857',
                          ),
                          xc: videoWidth / 2,
                          position,
                          beta: currentBeta,
                          bearing,
                        };

                        setClickedPositions({
                          ...clickedPositions,
                          [idx]: values,
                        });
                      }
                      // / MEDIÇÃO

                    }}
                  >
                    {track.is_360 ? (
                      <source src={track.original_video} type="video/mp4"/>
                    ) : (
                      <source src={track.webm} type="video/webm"/>
                    )}
                  </video>
                  <button
                    type="button"
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px'
                    }}
                    onClick={({ currentTarget }) => {
                      if (isPlaying) {
                        return;
                      }

                      const video = currentTarget.parentNode.querySelector(
                        'video');

                      getOriginalFrameForTime(track.id, video.currentTime);
                    }}
                  >
                    <i className="fas fa-image"/>
                  </button>
                  <button
                    type="button"
                    style={{
                      position: 'absolute',
                      top: '35px',
                      right: '10px',
                      backgroundColor: track.id === zoomEnabledVideo ? 'blue' :
                        'white',
                    }}
                    onClick={() => {
                      if (isPlaying) {
                        return;
                      }

                      setZoomedImage(null);
                      setZoomEnabledVideo(
                        zoomEnabledVideo !== track.id ? track.id : null);

                    }}
                  >
                    <i className="fas fa-search-plus"/>
                  </button>
                </div>
                {/* MEDIÇÃO */
                  (isMeasuring && clickedPositions[[idx]]) && (
                    <i
                      className="fas fa-crosshairs"
                      style={{
                        pointerEvents: 'none',
                        position: 'absolute',
                        left: `${clickedPositions[[idx]].position[0]}px`,
                        top: `${clickedPositions[[idx]].position[1]}px`,
                        color: 'red',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  )
                  /* / MEDIÇÃO */
                }
              </div>
            )
          )}
        </div>
        <div style={{
          padding: '10px',
          display: 'flex',
          alignItems: 'baseline',
        }}>
          <button type="button" onClick={() => onClose()}>
            Fechar
          </button>
          <button type="button" onClick={() => handleSeenVideoButton(!carTrack.seen)}>
            {carTrack.seen ? 'Anular marcação de video como visto' :
              'Marcar video como visto'}
          </button>
          <button type="button" onClick={() => {
            const geometry = carFeature.current.getGeometry();

            if (!geometry) {
              return;
            }

            const currentCoords = transformProj(
              carFeature.current.getGeometry()
                .getCoordinates(),
              map.projection.name,
              'EPSG:4326',
            );

            window.open(
              `http://www.google.com/maps/place/${formatCoords(currentCoords, '{y},{x}', 5)}`,
              '_blank',
            );
          }}>
            Google Maps
          </button>
          {/* MEDIÇÃO */}
          <div>
            <div className="measurements">
              <button type="button" onClick={handleMeasureButtonClick}>
                {isMeasuring ? 'Desativar medições' : 'Ativar medições'}
              </button>
              {(isMeasuring && Object.keys(clickedPositions).length > 0) &&
                <button type="button" onClick={() => {
                  const allPositions = [
                    ...storedPositions,
                    ...Object.values(clickedPositions),
                  ];

                  setClickedPositions({});

                  if (allPositions.length < 2) {
                    setStoredPositions(allPositions);

                    return;
                  }

                  const [pos1, pos2] = allPositions;

                  const measure = transformProj(
                    calculatePosition(
                      pos1.coordinates,
                      pos2.coordinates,
                      pos1.position,
                      pos2.position,
                      pos1.beta,
                      pos2.beta,
                      pos1.bearing,
                      pos2.bearing,
                    ),
                    'EPSG:3857',
                    map.projection.name,
                  );

                  measurementsLayer.current.getSource()
                    .addFeature(
                      new Feature({
                        geometry: new Point(measure),
                        name: measurements.length + 1,
                      }),
                    );

                  setMeasurements([...measurements,
                    transformProj(measure, 'EPSG:3857', 'EPSG:4326')]);

                  setStoredPositions([]);
                }}>
                  Confirmar Marcações
                </button>
              }
              {isMeasuring && <div>
                {measurements.length > 0 && (<ol>
                  {measurements.map(m => formatCoords(m, '{y}, {x}', 5))
                    // eslint-disable-next-line react/no-array-index-key
                    .map((m, idx) => <li key={idx}>{m}</li>)}
                </ol>)}
              </div>}
            </div>
          </div>
          {/* / MEDIÇÃO */}
          <div style={{ display: 'flex', marginLeft: '50px' }}>
            {availablePositions.map(position => (
              <div key={position} style={{marginLeft: '10px'}}>
                <input
                  id={position}
                  type="checkbox"
                  value={position}
                  checked={visibleCameras.indexOf(position) !== -1}
                  onChange={(event) => {
                    const newCameras = event.currentTarget.checked ?
                      [...visibleCameras, position] :
                      visibleCameras.filter(p => p !== position);

                    localStorage.setItem('visible-cameras', JSON.stringify(newCameras));

                    setVisibleCameras(newCameras);

                  }}
                />
                <label htmlFor={position}>{position}</label>
              </div>
            ))}
          </div>
        </div>
        <div
          className="controls"
          style={{
            display: 'flex',
            justifyContent: 'stretch',
            padding: '5px 0',
            position: 'sticky',
            bottom: '0',
            backgroundColor: 'white',
          }}
        >
          <button type="button" onClick={() => {
            const video = videoNodes.current[[0]];
            if (video === null) return;
            if (isPlaying) {
              video.pause();
              setIsPlaying(false);
            } else {
              const playPromise = video.play();
              if (playPromise !== undefined) {
                playPromise
                  .then(() => setIsPlaying(true))
                  .catch(error => {
                    console.log("Play ERROR:", error);
                  });
              }
            }
          }}
          >
            {isPlaying ? <i className="fas fa-pause"/> :
              <i className="fas fa-play"/>}
          </button>
          <button
            type="button"
            onClick={() => {
              const masterVideo = videoNodes.current[[0]];

              masterVideo.currentTime -= 2;
              masterVideo.pause();

              if (isPlaying) {
                const playPromise = masterVideo.play();
                if (playPromise !== undefined) {
                  playPromise
                    .then(() => {})
                    .catch(error => {
                      console.log("Play ERROR:", error);
                    });
                }
              }
            }}
          >
            -2
            <i className="fas fa-undo"/>
          </button>
          <button
            type="button"
            onClick={() => {
              const masterVideo = videoNodes.current[[0]];

              masterVideo.currentTime += 2;
              masterVideo.pause();

              if (isPlaying) {
                const playPromise = masterVideo.play();
                if (playPromise !== undefined) {
                  playPromise
                    .then(() => {})
                    .catch(error => {
                      console.log("Play ERROR:", error);
                    });
                }
              }

            }}
          >
            <i className="fas fa-redo"/>
            +2
          </button>
          <select
            onChange={(event) => {
              setPlaySpeed(event.currentTarget.value);
            }}
            value={playSpeed}
          >
            <option value="0.1">0.1x</option>
            <option value="0.3">0.3x</option>
            <option value="0.5">0.5x</option>
            <option value="0.7">0.7x</option>
            <option value="1">1x</option>
            <option value="1.5">1.5x</option>
            <option value="2">2x</option>
          </select>
          <button type="button" onClick={() => {
            const masterVideo = videoNodes.current[[0]];

            masterVideo.pause();
            masterVideo.currentTime =
              Math.max(0, masterVideo.currentTime - (1 / 59.94));
          }}>
            <i className="fas fa-step-backward"/>
          </button>
          <button type="button" onClick={() => {
            const masterVideo = videoNodes.current[[0]];

            masterVideo.pause();
            masterVideo.currentTime =
              Math.min(masterVideo.duration,
                masterVideo.currentTime + (1 / 59.94));
          }}>
            <i className="fas fa-step-forward"/>
          </button>
          <button type="button" onClick={() => setLockCamera(!lockCamera)}>
            <i className={lockCamera ? 'fas fa-lock' : 'fas fa-lock-open'}/>
          </button>
          <button type="button" onClick={() => {
            if (!lockRotation) {
              carFeature.current.getStyle()
                .getImage()
                .setRotation(0);
            } else {
              map.olMap.getView()
                .setRotation(0);
            }

            setLockRotation(!lockRotation);
          }}>
            <i
              className={lockRotation ? 'fas fa-compass' :
                'fas fa-arrow-up'}/>
          </button>
          <button type="button" onClick={() => {
            localStorage.setItem('fullscreen-mode', !isFullscreen);
            setIsFullscreen(!isFullscreen);
          }}>
            {isFullscreen ? <i className="fas fa-compress"/> :
              <i className="fas fa-expand"/>}
          </button>
          <input
            style={{ flex: 1 }}
            type="range"
            step="any"
            value={currentPlayPercentage}
            disabled={isMeasuring}
            onChange={(event) => {
              const playPercentage = (event.currentTarget.value / 100);
              const masterVideo = videoNodes.current[[0]];

              masterVideo.currentTime = masterVideo.duration * playPercentage;
              setCurrentPlayPercentage(playPercentage);
            }}
          />
        </div>
      </VideosContainer>
    ),
    widgetElement
  );
};

export default VideoPlayer;
