import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Layer from '../../../models/Layer';
import adminToolbox from '../../Admin/AdminComponent';
import { NormalText, Radio, SidebarListItem, ActionButtonContainer } from '../../presentational/common';


const CustomSidebarListItem = styled(SidebarListItem)`
  justify-content: space-between;
`;

const LayerRadio = styled(Radio)`
  top: 3px;
`;

const LayerNameLabel = styled(NormalText.withComponent('label'))`
  margin-bottom: 0;
`;

const CustomActionButtonContainer = styled(ActionButtonContainer)`
  margin-left: 10px;
`;

/**
 * BaseLayerSwitcherGroup item for each base layer
 */
export const BaseLayerSwitcherItem = ({
  layer, isChecked, setActiveLayerCallback, renderAdminButtons,
}) =>
  (
    <React.Fragment>
      <CustomSidebarListItem>
        <LayerNameLabel htmlFor={`input-${layer.id}`}>
          <LayerRadio
            id={`input-${layer.id}`}
            checked={isChecked}
            name="baseLayers"
            onChange={() => setActiveLayerCallback(layer)}
          />
          {layer.name}
        </LayerNameLabel>
        <CustomActionButtonContainer>
          {renderAdminButtons && renderAdminButtons()}
        </CustomActionButtonContainer>
      </CustomSidebarListItem>
    </React.Fragment>
  );

BaseLayerSwitcherItem.propTypes = {
  layer: PropTypes.instanceOf(Layer).isRequired,
  isChecked: PropTypes.bool.isRequired,
  setActiveLayerCallback: PropTypes.func.isRequired,
  renderAdminButtons: PropTypes.func,
};

BaseLayerSwitcherItem.defaultProps = {
  renderAdminButtons: null,
};

export default adminToolbox(BaseLayerSwitcherItem);
