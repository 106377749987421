import styled from 'styled-components';

export default styled.div`
  display: flex;
  height: 85px;
  background: transparent;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #ccc;
`;
