import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ToolButton from './common/ToolButton';
import { EXTRA_SMALL_MAX } from './mediaBreakpoints';
import { ReactComponent as LayersIcon } from '../../images/layers.svg';
import { ReactComponent as RulerIcon } from '../../images/ruler.svg';

const ToolBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 365px;
  width: 50px;

  @media(max-width: ${EXTRA_SMALL_MAX}) {
    flex-direction: row;
    align-self: flex-end;
    height: 50px;
    width: 100%;
  }
`;

const ToolBar = ({ openTool, user, map }) => {
  const enableGeovideo = process.env.REACT_APP_ENABLE_GEOVIDEO === 'true';
  const isSuperuser = user.isAuthenticated && user.hasEditPermission;
  const hasSearchableLayers = map != null && map.searchableLayers.length > 0;

  return (
    <ToolBarContainer>
      {(hasSearchableLayers || isSuperuser) &&
      <ToolButton title="Pesquisa" onClick={() => openTool('FeatureSearchForm')}
                  background="#043348">
        <i className="fas fa-search" />
      </ToolButton>
      }
      {isSuperuser &&
      <ToolButton title="Definições" onClick={() => openTool('Settings')} background="#0080B6">
        <i className="fas fa-cog" />
      </ToolButton>
      }
      <ToolButton title="Layers Base" onClick={() => openTool('BaseLayerSwitcher')}
                  background="#00A1E8">
        <i className="far fa-map" />
      </ToolButton>
      <ToolButton title="Layers Overlay" onClick={() => openTool('OverlayLayerSwitcher')}
                  background="#195F7F">
        <LayersIcon width="20px" height="20px" />
      </ToolButton>
      <ToolButton title="Imprimir Mapa" onClick={() => openTool('PrintMap')} background="#70CDF7">
        <i className="fas fa-print" />
      </ToolButton>
      {enableGeovideo &&
      <ToolButton onClick={() => openTool('Geovideo')} background="#043348">
        <i className="fas fa-video" />
      </ToolButton>
      }
    </ToolBarContainer>
  );
};

ToolBar.propTypes = {
  openTool: PropTypes.func.isRequired,
};

export default ToolBar;
