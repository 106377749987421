import Control from 'ol/control/Control';
import "./Map.scss"

class ResetControl extends Control {
  constructor() {
    const button = document.createElement('button');
    button.innerHTML = `<i class="fa-solid fa-arrows-rotate"></i>`;
    button.title = 'Atualizar Mapa';

    const element = document.createElement('div');
    element.className = 'refresh-btn ol-unselectable ol-control';
    element.appendChild(button);

    super({
      element: element,
    });


  }
}

export default ResetControl;
