import styled from 'styled-components';

export default styled.div`
  position: fixed; 
  top: 0; 
  left: 0; 
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  background: linear-gradient(rgba(21, 119, 175, 0.5), rgba(21, 119, 175, 0.5)), 
  url(${props => props.image}) no-repeat center;
  background-size: cover;
`;
