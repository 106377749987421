import React from 'react';
import styled from 'styled-components';

const SpinnerDiv = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: #0080B6;
`;

export default () => (
  <SpinnerDiv>
    <i className="far fa-compass fa-3x fa-spin" />
  </SpinnerDiv>
);
