import { useState } from 'react';
import styled from 'styled-components';
import { ImageBackground, WelcomeHeader } from '../components/presentational';
import backgroundImage from '../images/client-assets/background.jpg';
import LoginForm from '../components/Login/LoginForm';
import { EXTRA_SMALL_MAX } from '../components/presentational/mediaBreakpoints';
import { Link, NormalText } from '../components/presentational/common';
import { Link as RouterLink } from 'react-router-dom';

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const WrapperDiv = styled.div`
  max-width: 350px;
  margin: 10px;

  @media (max-width: ${EXTRA_SMALL_MAX}) {
    max-width: none;
    width: 100%;
    margin: 0;
    padding: 10px;
  }
`;

const BackLink = styled(Link.withComponent(RouterLink))`
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
`;

const LoginContainer = ({ location }) => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const accountStatus = queryParams.get('status');
  let activationMessage;

  switch (accountStatus) {
    case 'success':
      activationMessage = 'Conta ativada com sucesso. Faça login para' + ' continuar.';
      break;
    case 'not-found':
      activationMessage = 'Não foi encontrada nenhuma conta para o link' + ' acedido!';
      break;
    case 'already-active':
      activationMessage = 'Esta conta já se encontra ativa. Faça login para' + ' continuar.';
      break;
    case 'unknown':
      activationMessage = 'Ocorreu um erro ao processar o pedido.';
      break;
    default:
      break;
  }

  return (
    <>
      <ImageBackground image={backgroundImage} />
      <BackLink to="/">
        <i className="fas fa-angle-left" /> Voltar atrás
      </BackLink>
      <CenteredDiv>
        <WrapperDiv>
          <WelcomeHeader
            welcomeText={
              showForgotPassword ? LoginContainer.messages.forgotPasswordPage : LoginContainer.messages.welcomeMessage
            }
            descriptionText={
              showForgotPassword
                ? LoginContainer.messages.forgotPasswordDescription
                : LoginContainer.messages.description
            }
            textAlign="center"
            marginBottom="35px"
          />
          {activationMessage && <NormalText textColor="#fff">{activationMessage}</NormalText>}
          <LoginForm showForgotPassword={showForgotPassword} setShowForgotPassword={setShowForgotPassword} />
        </WrapperDiv>
      </CenteredDiv>
    </>
  );
};

LoginContainer.messages = {
  welcomeMessage: 'Bem-vindo',
  description: 'Faça Login para entrar na sua conta',
  forgotPasswordPage: 'Recuperar Password',
  forgotPasswordDescription: 'Introduza o seu email para recuperar sua password',
};

export default LoginContainer;
