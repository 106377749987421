import { Stroke, Style } from 'ol/style';
import Point from 'ol/geom/Point';
import Icon from 'ol/style/Icon';


export const getLineStyle = (opacity = 1.0) => {
  return (feature) => {
    const color = feature.get('seen') ? `rgba(117, 255, 43, ${opacity})` :
      `rgba(190, 0, 0, ${opacity})`;

    return new Style({
      stroke: new Stroke({
        color,
        width: 7,
      })
    })
  }
};

export const getLineArrowStyle = (map) => (feature, resolution) => {
  const color = feature.get('seen') ? `rgba(20, 103, 52, 1)` : `rgb(223, 85, 0)`;
  const geometry = feature.getGeometry();
  const styles = [
    // linestring
    new Style({
      stroke: new Stroke({
        color,
        width: 7,
      }),
    })
  ];

  const zoomForResolution = map.olMap.getView().getZoomForResolution(resolution);

  if (zoomForResolution < 16) {
    return styles;
  }

  const lineLength = geometry.getLength();
  const coordinates = geometry.getCoordinates();
  const distPerPoint = lineLength / coordinates.length;

  [...Array(18).keys()]
    .map((_, index) => Math.floor(((index + 1) * 0.05 * lineLength) / distPerPoint))
    .forEach((index) => {
      const start = coordinates[index - 1];
      const end = coordinates[index];
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const rotation = Math.atan2(dy, dx);
      // arrows
      styles.push(new Style({
        geometry: new Point(end),
        image: new Icon({
          src: '/line-arrow.png',
          anchor: [0.75, 0.5],
          color,
          rotateWithView: true,
          rotation: -rotation,
        })
      }));
    });


  return styles;
};
