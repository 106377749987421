import styled from 'styled-components';

const ToolButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: ${props => props.background};
  border: 0;
  font-size: 20px;
  outline: none;
  > i, svg {
    color: white;
    opacity: 0.5;
    box-sizing: content-box;
  }

  &:hover, &:active {
    cursor: pointer;
    > i, svg {
      padding: 5px;
      background: rgba(255, 255, 255, 0.2);
      opacity: 1;
      border-radius: 2px;
    }
  }
`;

export default ToolButton;
