import { createSlice } from '@reduxjs/toolkit';
import {fetchSearchResults} from '../thunks/featureSearchThunks';

/**
 * Enum representing the available scopes for WFS searches. Where local means
 * the search will have a delimiting bounding box
 *
 * @enum {string}
 */
const SEARCH_SCOPES = {
  LOCAL: 'local',
  WORLD: 'world',
};

const initialState = {
  isFetching: false,
  error: false,
  errorMessage: '',
  results: null,
  searchScope: SEARCH_SCOPES.LOCAL,
  searchableLayerId: null,
  searchText: '',
};


const featureSearchSlice = createSlice({
  name: 'featureSearch',
  initialState,
  reducers: {
    invalidateSearchResults(state) {
      state.results = null;
    },
    changeSearchableLayer(state, action) {
      state.searchableLayerId = action.payload;
      state.results = null;
      state.error = false;
      state.errorMessage = '';
    },
    changeSearchScope(state, action) {
      state.searchScope = action.payload;
    },
    changeSearchText(state, action) {
      state.searchText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchResults.pending, (state) => {
        state.isFetching = true;
        state.error = false;
        state.errorMessage = '';
      })
      .addCase(fetchSearchResults.fulfilled, (state, action) => {
        state.isFetching = false;
        state.results = action.payload;
        state.error = false;
        state.errorMessage = '';
      })
      .addCase(fetchSearchResults.rejected, (state, action) => {
        state.isFetching = false;
        state.results = null;
        state.error = true;
        state.errorMessage = action.error.message;
      });
  },
});

export const {
  invalidateSearchResults,
  changeSearchableLayer,
  changeSearchScope,
  changeSearchText,
} = featureSearchSlice.actions;

export default featureSearchSlice.reducer;
