import React from 'react';
import styled from 'styled-components';

const OverlayDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 100;
`;

export default () => (
  <OverlayDiv>
    <i className="far fa-compass fa-4x fa-spin" />
  </OverlayDiv>
);
