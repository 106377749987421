import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AdminComponent from '../../Admin/AdminComponent';
import { ActionButtonContainer, NormalText, SidebarListItem } from '../../presentational/common';

const CustomSidebarListItem = styled(SidebarListItem)`
  justify-content: space-between;
`;

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailText = styled(NormalText)`
  margin-bottom: 0;
`;


const OWSProvidersListItem = ({ provider, renderAdminButtons }) =>
  (
    <React.Fragment>
      <CustomSidebarListItem>
        <ContainerDiv>
          <DetailText>{provider.name}</DetailText>
        </ContainerDiv>
        <ActionButtonContainer>
          {renderAdminButtons && renderAdminButtons()}
        </ActionButtonContainer>
      </CustomSidebarListItem>
    </React.Fragment>
  );

OWSProvidersListItem.propTypes = {
  provider: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  renderAdminButtons: PropTypes.func,
};

OWSProvidersListItem.defaultProps = {
  renderAdminButtons: null,
};

export default AdminComponent(OWSProvidersListItem);
