import { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Creatable from 'react-select/creatable';
import Constants from '../../utils/constants';

/**
 * Custom field for react-jsonschema-form to read wms capabilities and allow the selection of a
 * layer.
 */
export default function WMSLayersFromCapabilities(props) {
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [wmsFields, setWmsFields] = useState(props.formData);
  const [selectedOption, setSelectedOption] = useState(null);
  const [remoteLayers, setRemoteLayers] = useState(null);

  const fetchWMSCapabilities = async (wmsServiceURL) => {
    const apiUrl = `${Constants.API_BASE_URL}/layers/wms-request/capabilities/?url=${wmsServiceURL}`;
    const response = await axios.get(apiUrl);
    return response.data.layers;
  };

  /**
   * Replace empty string values with undefined in order to have all
   * components "controlled" and don't submit empty values in the form
   */
  const processValues = (fieldsObject) => {
    return Object.keys(fieldsObject).reduce(
      (updatedFormData, key) => ({
        ...updatedFormData,
        [key]: fieldsObject[key] !== '' ? fieldsObject[key] : undefined,
      }),
      {},
    );
  };

  const getEnumOptions = (uiSchema) => {
    const {
      service_url: {
        'ui:options': { defaultOptions },
      },
    } = uiSchema;
    // create an entry for each of the enum defined in the field schema
    return Object.entries(defaultOptions).map(([label, value]) => ({ label, value }));
  };

  const handleURLChange = (selectedOption) => {
    if (selectedOption) {
      const { value: url } = selectedOption;
      setWmsFields({ service_url: url });
      setRemoteLayers(null);
      setSelectedOption(selectedOption);

      fetchWMSCapabilities(url)
        .then(setRemoteLayers)
        .catch(() => setRemoteLayers(null));
    } else {
      setRemoteLayers(null);
      setWmsFields({});
      setSelectedOption(null);
    }
  };

  const handleLayerChange = (event) => {
    const layerName = event.target.value;
    setWmsFields((prevWmsFields) => ({
      ...prevWmsFields,
      layer_name: layerName,
    }));
  };

  const handleStyleChange = (event) => {
    const style = event.target.value;
    setWmsFields((prevWmsFields) => ({
      ...prevWmsFields,
      style,
    }));
  };

  useEffect(() => {
    const options = getEnumOptions(props.uiSchema);
    setDefaultOptions(options);

    const { service_url: wmsServiceURL } = props.formData;
    let initialSelectedOption = null;

    // if there is a defined value that isn't one of the default ones, it is appended to the
    // default list of options
    if (wmsServiceURL != null) {
      const existingOption = options.find(({ value }) => value === wmsServiceURL);

      if (existingOption === undefined) {
        initialSelectedOption = { label: wmsServiceURL, value: wmsServiceURL };
        setDefaultOptions([...options, initialSelectedOption]);
      } else {
        initialSelectedOption = existingOption;
      }
    }

    setSelectedOption(initialSelectedOption);

    // if this field was instantiated with existing data, it is necessary to fetch the capabilities
    // to the provided url to fill the selects for the layer name and style
    if (wmsServiceURL) {
      fetchWMSCapabilities(wmsServiceURL)
        .then(setRemoteLayers)
        .catch(() => setRemoteLayers(null));
    }
  }, [props.formData, props.uiSchema]);

  useEffect(() => {
    props.onChange(processValues(wmsFields));
  }, [wmsFields]);

  return (
    <div>
      <div className="form-group">
        <label htmlFor="service-url">URL do serviço</label>
        <Creatable
          name="service-url"
          value={selectedOption}
          onChange={handleURLChange}
          options={defaultOptions}
          placeholder=""
        />
      </div>

      {remoteLayers && (
        <div className="form-group">
          <label htmlFor="layer-name">Layer</label>
          <select
            value={wmsFields.layer_name || ''}
            id="layer-name"
            className="form-control"
            onChange={handleLayerChange}
          >
            <option value="" />
            {Object.values(remoteLayers).map((remoteLayer) => (
              <option key={remoteLayer.name} value={remoteLayer.name}>
                {remoteLayer.title}
              </option>
            ))}
          </select>
        </div>
      )}

      {remoteLayers && wmsFields.layer_name && remoteLayers[wmsFields.layer_name] && (
        <div className="form-group">
          <label htmlFor="layer-style">Estilo da layer</label>
          <select
            value={wmsFields.style || ''}
            id="layer-style"
            className="form-control"
            aria-describedby="style-help"
            onChange={handleStyleChange}
          >
            <option value="" />
            {remoteLayers[wmsFields.layer_name].styles.map((remoteStyle, index) => (
              <option key={index} value={remoteStyle}>
                {remoteStyle}
              </option>
            ))}
          </select>
          <small id="style-help" className="form-text text-muted">
            Se deixar este campo vazio será usado o estilo default.
          </small>
        </div>
      )}
    </div>
  );
}

WMSLayersFromCapabilities.propTypes = {
  /* eslint-disable react/forbid-prop-types, react/require-default-props */
  uiSchema: PropTypes.object,
  formData: PropTypes.any,
  onChange: PropTypes.func,
  /* eslint-enable react/forbid-prop-types, react/require-default-props  */
};
