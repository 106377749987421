import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedTool: null,
  opened: false,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    selectTool(state, action) {

      const  tool = action.payload;
      if (state.selectedTool === tool) {
        state.opened = !state.opened;
      } else {
        state.selectedTool = tool;
        state.opened = true;
      }
    },
    toggleSidebar(state) {
      state.opened = !state.opened;
    },
  },
});

export const { selectTool, toggleSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
