import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios';
import Constants from '../utils/constants';

export const fetchSearchResults = createAsyncThunk(
  'featureSearch/fetchSearchResults',
  async ({ searchText, searchableLayerId, bbox }) => {
    let request = `${Constants.API_BASE_URL}/search/?query=${searchText}&searchId=${searchableLayerId}`;

    if (bbox) {
      request += `&bbox=${bbox.join(',')}`;
    }

    try {
      const response = await axios.get(request);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }
);
