import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { SidebarHeader, SidebarTitle } from '../presentational/common';
import SidebarContent from '../presentational/SidebarContent';
import MapModel from '../../models/Map';
import { LAYER_TYPES } from '../../factories/LayerFactory';
import Constants from '../../utils/constants';
import FeatureDisplayer from './FeatureDisplayer';
import { Vector } from 'ol/layer';
import { GeoJSON } from 'ol/format';
import Overlay from 'ol/Overlay';

export default function GetFeatureInfo({ map }) {
  const [features, setFeatures] = useState([]);
  const selectFeatureTooltip = useRef(null)
  const selectFeatureTooltipElement = useRef(null);

  const overlayLayers = map.layers.filter((layer) => layer.type === LAYER_TYPES.OVERLAY && layer.olLayer.isVisible());

  // Generate manually the link to get features from layer
  const getWFSUrl = (service_url,coordinate, typename, srsname) =>  {
    const margin = 0.01;
    const lon = coordinate[0];
    const lat = coordinate[1];
    const minLon = lon - margin;
    const maxLon = lon + margin;
    const minLat = lat - margin;
    const maxLat = lat + margin;
    const bbox = [minLon, minLat, maxLon, maxLat].join(',');
    return `${service_url}?service=WFS&request=GetFeature&typename=${typename}&outputFormat=application/json&srsname=${srsname}&bbox=${bbox},${srsname}`;
  }

  const createHelpTooltip = () => {
    if (selectFeatureTooltipElement.current) {
      selectFeatureTooltipElement.current.parentNode.removeChild(selectFeatureTooltipElement.current);
    }
    selectFeatureTooltipElement.current = document.createElement('div');
    selectFeatureTooltipElement.current.className = 'tooltip hidden';
    selectFeatureTooltip.current = new Overlay({
      element: selectFeatureTooltipElement.current,
      offset: [15, 0],
      positioning: 'center-left',
      stopEvent: false,
    });
    map.olMap.addOverlay(selectFeatureTooltip.current);
  };


  const handleMapClick = (event) => {
    const view = map.olMap.getView();
    const pixel = map.olMap.getEventPixel(event.originalEvent);
    const viewResolution = view.getResolution();
    const layersAtPixel = overlayLayers.filter((layer) => {
      if(layer.olLayer instanceof Vector && layer.olLayer.getSource().getFeaturesAtCoordinate(event.coordinate).length > 0){
        return true
      }
      else {
        let data = layer.olLayer.getData(pixel);
        return data && data[3] > 0 ;
      }
    });

    if (layersAtPixel.length === 0) {
      setFeatures([]);
      return;
    }

    const getFeatureInfoPromises = layersAtPixel
      .map((layer) => {

        if (layer.olLayer instanceof Vector) {
          return getWFSUrl(layer.serviceUrl,event.coordinate, layer.featureTypeName, map.olMap.getView().getProjection().getCode());
        }
        else {
          return layer
            .olLayer
            .getSource()
            .getFeatureInfoUrl(event.coordinate, viewResolution, map.olMap.getView().getProjection().getCode(), {
              INFO_FORMAT: "application/json",
              FEATURE_COUNT: 10,
              WITH_GEOMETRY: true,
            })
        }
      })
      .filter((url) => url)
      .map((url) => `${Constants.API_BASE_URL}/layers/wms-request/get-feature-info/?url=${encodeURIComponent(url)}`)
      .map((url) => axios.get(url));

    Promise.all(getFeatureInfoPromises).then((results) => {
      const newFeatures = results.reduce((allFeatures, { data: { features } }) => [...allFeatures, ...features], []);
      setFeatures(newFeatures);
    });
  };

  const handlePointerMove = (event) => {
    if (event.dragging) {
      return;
    }

    const pixel = map.olMap.getEventPixel(event.originalEvent);
    const hit = overlayLayers.some((layer) => {
      if(layer.olLayer instanceof Vector && layer.olLayer.getSource().getFeaturesAtCoordinate(event.coordinate).length > 0){
        return true
      }
      else {
        let data = layer.olLayer.getData(pixel);
        return data && data[3] > 0 ;
      }

    });
    selectFeatureTooltipElement.current.innerHTML = GetFeatureInfo.messages.helpTooltip;
    selectFeatureTooltip.current.setPosition(event.coordinate);

    selectFeatureTooltipElement.current.classList.remove('hidden');

    map.olMap.getTargetElement().style.cursor = hit ? "pointer" : "";
  };

  useEffect(() => {
    map.olMap.on("singleclick", handleMapClick);
    map.olMap.on("pointermove", handlePointerMove);
    createHelpTooltip()
    map.olMap.getViewport().addEventListener('mouseout', () => {
      selectFeatureTooltipElement.current.classList.add('hidden');
    });
    return () => {
      map.olMap.un("singleclick", handleMapClick);
      map.olMap.un("pointermove", handlePointerMove);
      map.olMap.getTargetElement().style.cursor = "";
    };
  }, []);

  const handleModalClose = useCallback(() => {
    setFeatures([]);
  }, []);

  const featureFocus = (feature) => {
    try {
      // Get geometry from feature
      const feature_geom = new GeoJSON().readGeometry(feature.geometry);
      // Focus on the feature
      map.olMap.getView().fit(feature_geom);
      // Close modal
      handleModalClose();
    }  catch (error) {
      // Error, probably because feature had no geometry
      alert(GetFeatureInfo.messages.featureFocusError);
    }
  }

  return (
    <>
      <SidebarContent>
        {features.length > 0 && <FeatureDisplayer features={features} featureFocus={featureFocus} handleModalClose={handleModalClose} />}
      </SidebarContent>
    </>
  );
}

GetFeatureInfo.propTypes = {
  map: PropTypes.instanceOf(MapModel).isRequired,
};
GetFeatureInfo.messages = {
  helpTooltip: "Obter informação de overlay layers",
  featureFocusError: "Erro ao focar, não foi possível determinar a geometria da feature",
}
