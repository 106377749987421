import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import adminToolbox from '../Admin/AdminComponent';
import { fetchMapList } from '../../thunks/mapListThunk';
import { NumericList } from '../presentational';
import { OverlaySpinner } from '../presentational/common';

const ErrorMessage = styled.p`
  color: red;
`;

function MapList({ renderAdminButtons }) {
  const dispatch = useDispatch();
  const {maps, isFetching} = useSelector((state) => state.mapList);
  useEffect(() => {
    dispatch(fetchMapList());
  }, []);

  if (isFetching) {
    return <OverlaySpinner />;
  }

  const items = maps.map(({ id, name, slug }) => ({ id, text: name, url: `mapas/${slug}` }));

  return (
    <>
      {items.length > 0 ? <NumericList items={items} /> : <ErrorMessage>Não existem mapas para si.</ErrorMessage>}
      {renderAdminButtons && renderAdminButtons()}
    </>
  );
}



MapList.propTypes = {
  renderAdminButtons: PropTypes.func,
};

MapList.defaultProps = {
  renderAdminButtons: null,
};

export default adminToolbox(MapList);
