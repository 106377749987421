import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import FeatureSearchResults from './FeatureSearchResults';
import adminToolbox from '../Admin/AdminComponent';
import SearchableLayersList from './SearchableLayersList';
import SidebarContent from '../presentational/SidebarContent';
import {
  changeSearchableLayer,
  changeSearchScope,
  changeSearchText,
  invalidateSearchResults,
} from '../../reducers/featureSearchReducer';
import { fetchSearchResults } from '../../thunks/featureSearchThunks';
import {
  Button,
  Form,
  HeaderActionButtonContainer,
  LoadingSpinner,
  NormalText,
  Radio,
  SearchInput,
  Select,
  SelectableButton,
  SidebarHeader,
  SidebarTitle,
} from '../presentational/common';
import { Vector } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { transformExtent } from 'ol/proj';
import OLMap from 'ol/Map';
import { fromExtent } from 'ol/geom/Polygon';

const LayerSelect = styled(Select)`
  border-radius: 4px 0 0 4px;
  border: 1px solid #ccc;
`;

const ScopeRadio = styled(Radio)`
  top: 3px;
`;

const ScopeLabel = styled(NormalText.withComponent('label'))`
  ${(props) =>
    props.marginRight &&
    css`
      margin-right: 50px;
    `}
`;

const RowDiv = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

const SearchError = styled.div`
  color: red;
`;

/**
 * Enum representing the available scopes for WFS searches. Where local means
 * the search will have a delimiting bounding box
 *
 * @enum {string}
 */
const SEARCH_SCOPES = {
  LOCAL: 'local',
  WORLD: 'world',
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * Component used to search features using WFS requests and to list them
 *
 */
function FeatureSearchForm(props) {
  /**
   *
   * @param {object} props Component props
   * @param {ol.Map} props.map Map used to display the search results
   */

  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const { isFetching, results, searchScope, searchableLayerId, searchText, error, errorMessage } = useSelector(
    (state) => state.featureSearch,
  );

  const resultsLayer = useRef(
    new Vector({
      source: new VectorSource(),
      map: props.map,
    }),
  );

  const previousResults = usePrevious(results);
  const previousFetchingState = usePrevious(isFetching);

  useEffect(() => {
    // The first layer is the default one
    const [{ id: layerId = null } = {}] = props.searchableLayers;
    dispatch(changeSearchableLayer(layerId));

    return () => {
      resultsLayer.current.setMap(null);
    };
  }, []);

  useEffect(() => {
    if (results !== previousResults) {
      // Clear from the layer the results from previous searches
      resultsLayer.current.getSource().clear();

      // if the results prop is not null, the results are added to the map
      if (results !== null) {
        resultsLayer.current.getSource().addFeatures(
          new GeoJSON().readFeatures(results, {
            featureProjection: props.map.getView().getProjection(),
          }),
        );
      }
    }
    // After fetching the results, fit view to features
    if (isFetching === false && previousFetchingState === true && results !== null) {
      if (results.totalFeatures > 0) {
        fitAllResultsOnMap();
      }
    }
  }, [results, isFetching]);

  const handleTextChange = (searchText) => {
    dispatch(changeSearchText(searchText));
  };

  const handleScopeChange = (event) => {
    dispatch(changeSearchScope(event.currentTarget.value));
  };

  const handleLayerChange = (event) => {
    dispatch(changeSearchableLayer(parseInt(event.currentTarget.value, 10)));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // If the search input is empty the search results are reset as no request
    // is made
    if (searchText === '') {
      dispatch(invalidateSearchResults());
      return;
    }

    // if the scope of the search is loaded, the map extent is fetched from the
    // view and it is passed to the request to limit the search to that
    // bounding box
    const bbox =
      searchScope === SEARCH_SCOPES.LOCAL
        ? transformExtent(props.map.getView().calculateExtent(), props.map.getView().getProjection(), 'EPSG:4326')
        : null;

    dispatch(fetchSearchResults({ searchText, searchableLayerId, bbox }));
  };

  /**
   * Fit the map on the search result with the provided id
   *
   * @param featureId feature id
   */
  const fitResultOnMap = (featureId) => {
    const feature = resultsLayer.current.getSource().getFeatureById(featureId);

    props.map.getView().fit(feature.getGeometry());
  };

  /**
   * Fit all the search results on the map
   */
  const fitAllResultsOnMap = () => {
    // Get extent of search layers
    const resultsExtent = resultsLayer.current.getSource().getExtent();
    // Transform to polygon and scale it 155%
    // to guarantee that fits all objects even with sidebar visible
    let extentPoly = fromExtent(resultsExtent);
    extentPoly.scale(1.55);
    // Fit map's to the scaled up extent
    props.map.getView().fit(extentPoly);
  };

  return (
    <React.Fragment>
      <SidebarHeader>
        <SidebarTitle>{FeatureSearchForm.messages.search}</SidebarTitle>
        <HeaderActionButtonContainer>
          {props.renderAdminButtons && props.renderAdminButtons()}
          {props.searchableLayers.length > 0 && (
            <React.Fragment>
              <SelectableButton title="Ver Layers Pesquisáveis" onClick={() => setEditMode(true)}>
                <i className="fas fa-eye" />
              </SelectableButton>
              {editMode && (
                <Modal isOpen={true} onRequestClose={() => setEditMode(false)} ariaHideApp={false}>
                  <Button onClick={() => setEditMode(false)}>
                    <i className="fas fa-times" />
                  </Button>
                  <SearchableLayersList searchableLayers={props.searchableLayers} />
                </Modal>
              )}
            </React.Fragment>
          )}
        </HeaderActionButtonContainer>
      </SidebarHeader>
      <SidebarContent>
        {props.searchableLayers.length === 0 ? (
          <p>Não existem layers pesquisáveis!</p>
        ) : (
          <React.Fragment>
            <RowDiv>
              <Form onSubmit={handleSubmit}>
                <RowDiv>
                  <LayerSelect onChange={handleLayerChange}>
                    {props.searchableLayers.map((layer) => (
                      <option key={layer.id} value={layer.id}>
                        {layer.alias}
                      </option>
                    ))}
                  </LayerSelect>
                  <SearchInput
                    type="text"
                    value={searchText}
                    placeholder={FeatureSearchForm.messages.search_placeholder}
                    onChange={handleTextChange}
                  />
                </RowDiv>
                <RowDiv>
                  <ScopeLabel marginRight htmlFor="local-scope">
                    <ScopeRadio
                      id="local-scope"
                      name="search-scope"
                      value={SEARCH_SCOPES.LOCAL}
                      checked={searchScope === SEARCH_SCOPES.LOCAL}
                      onChange={handleScopeChange}
                    />
                    {FeatureSearchForm.messages.local_radio_btn}
                  </ScopeLabel>
                  <ScopeLabel htmlFor="world-scope">
                    <ScopeRadio
                      id="world-scope"
                      name="search-scope"
                      value={SEARCH_SCOPES.WORLD}
                      checked={searchScope === SEARCH_SCOPES.WORLD}
                      onChange={handleScopeChange}
                    />
                    {FeatureSearchForm.messages.world_radio_btn}
                  </ScopeLabel>
                </RowDiv>
                <Button type="submit" width="100%">
                  {FeatureSearchForm.messages.search_button_text}
                </Button>
              </Form>
            </RowDiv>
            <RowDiv>
              {isFetching && <LoadingSpinner />}
              {results === null && error === true && (
                <SearchError>
                {FeatureSearchForm.messages.search_error} {errorMessage}
                </SearchError>
              )}
              {results !== null && isFetching === false && (
                <FeatureSearchResults
                  error={error}
                  errorMessage={errorMessage}
                  searchResults={results.features}
                  resultSelectedHandler={fitResultOnMap}
                  fitAllResultsHandler={fitAllResultsOnMap}
                  fieldsToShow={props.searchableLayers
                    .filter((layer) => layer.id === searchableLayerId)
                    .map(({ attributes }) => attributes)
                    .reduce((fullList, attrList) => fullList.concat(attrList), [])
                    .map(({ property, alias }) => ({
                      key: property,
                      alias,
                    }))}
                />
              )}
            </RowDiv>
          </React.Fragment>
        )}
      </SidebarContent>
    </React.Fragment>
  );
}

FeatureSearchForm.propTypes = {
  map: PropTypes.instanceOf(OLMap).isRequired,
  searchableLayers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      alias: PropTypes.string,
    }),
  ).isRequired,
  renderAdminButtons: PropTypes.func,
};

FeatureSearchForm.defaultProps = {
  results: null,
  searchableLayerId: null,
  renderAdminButtons: null,
};

FeatureSearchForm.messages = {
  search: 'Pesquisa',
  search_placeholder: 'Insira o termo de pesquisa',
  search_button_text: 'pesquisar',
  all_layers_option: 'Todas as layers',
  local_radio_btn: 'área visível',
  world_radio_btn: 'toda a área',
  search_error: 'Ocorreu um erro!',
};

export default adminToolbox(FeatureSearchForm);
export { SEARCH_SCOPES };
