import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SearchableLayerListItem from './SearchableLayerListItem';
import { ADMIN_ACTIONS } from '../../components/Admin/AdminComponent';
import Constants from '../../utils/constants';
import { SelectableButton } from '../presentational/common';

const LayerListDiv = styled.div`
  padding: 10px;
`;

const SearchableLayersList = ({ searchableLayers }) => (
  <LayerListDiv>
    {searchableLayers
      .map(layer => (<SearchableLayerListItem
        key={layer.id}
        layer={layer}
        actions={[
          {
            action: ADMIN_ACTIONS.EDIT,
            url: `${Constants.API_BASE_URL}/search/config/${layer.id}/`,
            component: SelectableButton,
            title: SearchableLayersList.messages.editButtonTooltip,
            children: (
              <i className="fas fa-pencil-alt" />
            ),
          },
          {
            action: ADMIN_ACTIONS.DELETE,
            url: `${Constants.API_BASE_URL}/search/config/${layer.id}/`,
            component: SelectableButton,
            title: SearchableLayersList.messages.deleteButtonTooltip,
            children: (
              <i className="fas fa-trash-alt" />
            ),
          },
        ]}
      />))}
  </LayerListDiv>
);

SearchableLayersList.propTypes = {
  searchableLayers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    alias: PropTypes.string,
  })).isRequired,
};

SearchableLayersList.messages = {
  deleteButtonTooltip: 'Apagar layers pesquisáveis',
  editButtonTooltip: 'Editar layers pesquisáveis'
}

export default SearchableLayersList;
