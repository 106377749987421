import styled from 'styled-components';
import { EXTRA_SMALL_MAX } from '../mediaBreakpoints';

export default styled.p`
  font-size: 24px;
  font-weight: 300;
  color: #666;
  margin: 10px;

  @media(max-width: ${EXTRA_SMALL_MAX}) {
    font-size: 22px;
  }
`;
