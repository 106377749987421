import OSM from 'ol/source/OSM';
import BingMaps from 'ol/source/BingMaps';
import TileWMS from 'ol/source/TileWMS';
import { Vector, Tile } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';


/**
 * Enum for the available layer types.
 * @readonly
 * @enum {number}
 */
const LAYER_TYPES = {
  BASE: 0,
  OVERLAY: 1,
};

/**
 * Enum for the available layer source types.
 * @readonly
 * @enum {string}
 */
const LAYER_SOURCES = {
  OSM: 'OSM',
  BING: 'BING',
  WMS: 'WMS',
  WFS: 'WFS',
};

/**
 * Class to simplify layer creation
 */
class LayerFactory {
  /**
   * Factory layer creation method.
   * @param {object} options
   * @param {LAYER_SOURCES} options.sourceType
   * @param {...OSMLayerOptions|...BingLayerOptions|...WMSLayerOptions|...WFSLayerOptions}
   * options.layerConfig Configurations for the layer to be created
   * @returns {ol.layer.Layer} Created layer
   */
  static createLayer({ sourceType, ...layerConfig }) {
    switch (sourceType) {
      case LAYER_SOURCES.OSM:
        return LayerFactory.createOSMLayer(layerConfig);
      case LAYER_SOURCES.BING:
        return LayerFactory.createBingLayer(layerConfig);
      case LAYER_SOURCES.WMS:
        return LayerFactory.createWMSLayer(layerConfig);
      case LAYER_SOURCES.WFS:
        return LayerFactory.createWFSLayer(layerConfig);
      default:
        throw new Error(`Unsuported layer source type: ${sourceType}`);
    }
  }

  /**
   * Tile Layer options object.
   *
   * @typedef {object} TileLayerOptions
   * @property {number} [opacity=1] Opacity of the layer ranging from 0 to 1.
   * @property {boolean} [visible=true] Layer visibility.
   * @property {ol.Extent} [extent] The extent for which this layer is rendered.
   * @property {number} [minResolution] Minimum resolution (inclusive) at
   * which this layer will be rendered.
   * @property {number} [maxResolution] Maximum resolution (exclusive) at
   * which this layer will be rendered.
   *
   */

  /**
   *  Convenience method used to create a Tile layer. This method should not be called
   *  directly.
   *
   * @param {TileLayerOptions} [options]
   * @returns {ol.layer.Tile} Tile layer.
   */
  static createTileLayer({
    opacity = 1, visible = true, extent, minResolution, maxResolution,
  } = {}) {
    return new Tile({
      preload: Infinity,
      visible,
      extent,
      minResolution,
      maxResolution,
      opacity,
    });
  }

  /**
   * OSM Layer options object.
   *
   * @typedef {object} OSMLayerOptions
   * @property {number} [opacity=1] Opacity of the layer ranging from 0 to 1.
   * @property {boolean} [visible=true] Layer visibility.
   * @property {ol.Extent} [extent] The extent for which this layer is rendered.
   * @property {number} [minResolution] Minimum resolution (inclusive) at
   * which this layer will be rendered.
   * @property {number} [maxResolution] Maximum resolution (exclusive) at
   * which this layer will be rendered.
   *
   */

  /**
   * Creates a Tile layer using OpenStreetMap as source.
   *
   * @param {OSMLayerOptions} [options]
   * @returns {ol.layer.Tile} Tile layer.
   */
  static createOSMLayer({
    opacity = 1, visible = true, extent, minResolution, maxResolution,
  } = {}) {
    const tileLayer = LayerFactory.createTileLayer({
      opacity,
      visible,
      extent,
      minResolution,
      maxResolution,
    });
    const osmSource = new OSM();

    tileLayer.setSource(osmSource);

    return tileLayer;
  }

  /**
   * Bing Layer options object.
   *
   * @typedef {object} BingLayerOptions
   * @property {string} apiKey Api key for Bing Maps.
   * @property {string} [mapType='Road'] Type of imagery to use for this source (eg. 'Road',
   * 'Aerial',
   *     etc.).
   * @property {number} [opacity=1] Opacity of the layer ranging from 0 to 1.
   * @property {boolean} [visible=true] Layer visibility.
   * @property {ol.Extent} [extent] The extent for which this layer is rendered.
   * @property{number} [minResolution] Minimum resolution (inclusive) at
   * which this layer will be rendered.
   * @property {number} [maxResolution] Maximum resolution (exclusive) at
   * which this layer will be rendered.
   *
   */

  /**
   *  Create a Tile layer using Bing Maps as source.
   *
   * @param {BingLayerOptions} options
   * @returns {ol.layer.Tile} Tile layer.
   */
  static createBingLayer({
    apiKey, mapType = 'Road', opacity = 1, visible = true, extent, minResolution, maxResolution,
  }) {
    const tileLayer = LayerFactory.createTileLayer({
      opacity,
      visible,
      extent,
      minResolution,
      maxResolution,
    });

    const bingSource = new BingMaps({
      key: apiKey,
      imagerySet: mapType,
    });

    tileLayer.setSource(bingSource);

    return tileLayer;
  }

  /**
   * WMS Layer options object.
   *
   * @typedef {object} WMSLayerOptions
   * @property {string} url Url of the WMS service.
   * @property {object} wmsParams WMS request parameters as defined in the
   * WMS standard (eg. http://docs.geoserver.org/stable/en/user/services/wms/reference.html#getmap).
   * @property {string} wmsParams.LAYERS WMS layers to load
   * @property {number} [opacity=1] Opacity of the layer ranging from 0 to 1.
   * @property {boolean} [visible=true] Layer visibility.
   * @property {ol.Extent} [extent] The extent for which this layer is rendered.
   * @property {number} [minResolution] Minimum resolution (inclusive) at
   * which this layer will be rendered.
   * @property {number} [maxResolution] Maximum resolution (exclusive) at
   * which this layer will be rendered.
   *
   */

  /**
   *  Create a Tile layer using a WMS tile provider as source.
   *
   * @param {WMSLayerOptions} options
   * @returns {ol.layer.Tile} Tile layer.
   */
  static createWMSLayer({
    url, wmsParams, opacity = 1, visible = true, extent, minResolution, maxResolution,
  }) {
    const tileLayer = LayerFactory.createTileLayer({
      opacity,
      visible,
      extent,
      minResolution,
      maxResolution,
    });

    const wmsSource = new TileWMS({
      url,
      params: { VERSION: '1.1.1', ...wmsParams },
      crossOrigin: 'anonymous',
    });

    tileLayer.setSource(wmsSource);

    return tileLayer;
  }

  /**
   * WFS Layer options object
   *
   * @typedef {object} WFSLayerOptions
   * @property {string} url WFS service url
   * @property {string} featureTypeName WFS feature type as defined in GetCapabilities
   * @property {Array.<ol.Feature> | ol.Collection.<ol.Feature>} [features] WFS features
   * @property {ol.style.Style | Array.<ol.style.Style> | ol.StyleFunction} [style]
   * Styles to apply to the WFS layer
   * @property {number} [opacity=1] Opacity of the layer ranging from 0 to 1.
   * @property {boolean} [visible=true] Layer visibility.
   * @property {boolean} [updateWhileAnimating=true] When set to true, feature batches will be
   * recreated during animations. This means that no vectors will be shown clipped, but the
   * setting will have a performance impact for large amounts of vector data.
   * @property {boolean} [updateWhileInteracting=true] When set to true, feature batches
   * will be recreated during interactions.
   * @property {ol.Extent} [extent] The extent for which this layer is rendered.
   * @property {number} [minResolution] Minimum resolution (inclusive) at
   * which this layer will be rendered.
   * @property {number} [maxResolution] Maximum resolution (exclusive) at
   * which this layer will be rendered.
   */

  /**
   * Create a Vector layer using a WFS source.
   *
   * @param {WFSLayerOptions} options
   * @returns {ol.layer.Vector} Vector layer.
   */
  static createWFSLayer({
    url, featureTypeName, features, style, opacity = 1, visible = true, updateWhileAnimating = true,
    updateWhileInteracting = true, extent, minResolution, maxResolution,
  }) {
    const vectorLayer = new Vector({
      visible,
      opacity,
      updateWhileAnimating,
      updateWhileInteracting,
      extent,
      minResolution,
      maxResolution,
      style,
    });

    const urlTemplate = `${url}?service=WFS&request=GetFeature&typename=${featureTypeName}
&outputFormat=application/json&srsname=EPSG:4326`;

    const vectorSource = new VectorSource({
      url: urlTemplate,
      format: new GeoJSON({ extractGeometryName: true }),
      features,
    });

    vectorLayer.setSource(vectorSource);

    return vectorLayer;
  }
}

export { LAYER_TYPES, LAYER_SOURCES };
export default LayerFactory;
