import styled from 'styled-components';
import { EXTRA_SMALL_MAX } from '../mediaBreakpoints'

const SelectProjection = styled.select`
  flex-grow: 1;
  border: none;
  padding: 6px 10px;
  outline: none;
  text-align: center;
  color: black;
  font-size: 0.9em;
  background: white;

  &:focus {
    outline: none;
  }

  @media(max-width: ${EXTRA_SMALL_MAX}) {
    font-size: 0.8em;
  }
`;

export default SelectProjection;
