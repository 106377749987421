import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SelectProjection from './presentational/common/SelectProjection';
import { fetchMap } from '../thunks/mapThunk';
import Utils from '../utils/utils';
import OverlaySpinner from './presentational/common/OverlaySpinner';
import WKT from 'ol/format/WKT';
import MapCoordinates from './presentational/MapCoordinates';
import { setProjection, clearMapState } from '../reducers/mapReducer';
import CoordinatesProjectionBar from './presentational/CoordinatesProjectionBar';
import { METERS_PER_UNIT } from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON';
import { ScaleLine } from 'ol/control';
import ResetControl from '../models/ResetControl';
import IdentifyControl from '../components/Identify/IdentifyControl';
import './Map.scss';

function Map(props) {
  const dispatch = useDispatch();
  const [refrestMap, setRefrestMap] = useState(false);
  const { map, mapConfig, isFetching, error, projections, currentProjection, mapState } = useSelector((state) => state.map);

  useEffect(() => {
    const mapSlug = props.mapSlug;
    dispatch(fetchMap({ mapSlug }));
  }, []);

  const handleProjectionChange = (e) => {
    dispatch(setProjection(e.target.value));
  };

  /**
   *
   * @param {object} mapState relating to mapstate fetched from API
   * @returns a dispatch to clear mapState state
   *
   * From mapstate activate supposed layers, set center and zoom level
   *
   */
  const setMapToState = (mapState) => {
    const { projection, center, active_layers, state_zoom } = mapState;

    // Check if drawn features are outside map's BBox
    const drawLayer = active_layers.filter((layer) => layer.layers === undefined);

    let features = []
    if (drawLayer.length > 0) {
      features = new GeoJSON().readFeatures(drawLayer[0].geoJson);
      const areFeaturesContained = Utils.extentContainsAllFeatures(features, mapConfig.bbox);

      if (areFeaturesContained === false) {
        alert('Geometrias de print fora da bounding box do mapa');
        return;
      }
    }

    // Set layer visibility based off of print request
    // Filter out { type: 'geojson', geoJson: '{"type":"FeatureCollection","features":[]}' } of print request
    const printLayers = active_layers.filter((layer) => layer.layers !== undefined);

    // List of layers' names on print request
    const printLayerNameList = printLayers.map((layer) => layer['layers'][0]);

    // Toggle visibility of map layers
    map.layers.forEach((current_layer) => {
      let toggleValue = printLayerNameList.includes(current_layer.layerName);
      current_layer.olLayer.setVisible(toggleValue);
    });

    map.olMap
      .getLayers()
      .getArray()
      .forEach((layer) => {
        if (layer.get('name') === 'measure') {
          layer.getSource().addFeatures(features);
        }
      });

    // Set view zoom and center based off of print request
    // As seen in of the the replies in https://gis.stackexchange.com/questions/167284/zoom-scale-in-openlayers-3
    const view = map.olMap.getView();
    if (state_zoom != null) {
      view.setZoom(state_zoom);
    }
    view.setCenter(center);
    dispatch(clearMapState);
  }

  useEffect(() => {
    if (map && !map.olMap.getTarget()) {
      map.olMap.setTarget('map');
      document.title = map.name;
      const formatter = new WKT();
      // Read the Polygon Geometry from the WKT string, and get the extent for that geometry
      const extentWithSRID = map.initialExtent.split(';');
      // Read the Polygon Geometry from the WKT string, and get the extent for that geometry
      const extent = Utils.transformExtentToProjection(
        formatter.readGeometry(extentWithSRID[1]).getExtent(),
        'EPSG:4326',
        map.projection.name,
      );
      // Fit on initial extent
      map.olMap.getView().fit(extent);

      // Add ScaleLine to the map
      const scaleLineControl = new ScaleLine({
        className: 'custom-scale-line',
      });
      map.olMap.addControl(scaleLineControl);

      // TODO: setTimeout?!
      setTimeout(() => map.olMap.updateSize(), 0);
      const resetControl = new ResetControl();
      map.olMap.addControl(resetControl)
      resetControl.element.addEventListener('click', () => setRefrestMap(true));

      // Set map state if redirected from map-state fetch screen
      if (mapState != undefined && mapState != null) {
        setMapToState(mapState);
      }
    }
  }, [map]);
  useEffect(() => {
    if(map && refrestMap){
      const mapSlug = props.mapSlug;
      map.olMap.setTarget(null);
      dispatch(fetchMap({ mapSlug }));

      setRefrestMap(false)
    }


  }, [refrestMap])

  return error ? (
    <div>
      Mapa não encontrado! <Link to="/">Voltar à listagem</Link>
    </div>
  ) : (
    <div id="map" className={props.className}>
      {isFetching && <OverlaySpinner />}
      {map && (
        <>
          <CoordinatesProjectionBar>
              <MapCoordinates map={map.olMap} />
              <SelectProjection title="Sistema de Coordenadas" onChange={handleProjectionChange} value={currentProjection.srid}>
                {projections.map((proj) => (
                  <option key={proj.srid} value={proj.srid}>
                    {Utils.customCRSName(proj.fullName)}
                  </option>
                ))}
              </SelectProjection>
          </CoordinatesProjectionBar>
          <IdentifyControl map={map} />
        </>
      )}
    </div>
  );
}

Map.propTypes = {
  mapSlug: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Map.defaultProps = {
  map: null,
  className: null,
};

export default Map;
