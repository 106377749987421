import styled from 'styled-components';

export default styled.input`
  width: 100%;
  font-size: 14px;
  color: #666;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 5px 10px;
  background: transparent;
  outline: none;
`;
