import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import '@fortawesome/fontawesome-free/css/all.min.css';
import LoginContainer from './LoginContainer';
import RegisterContainer from './RegisterContainer';
import MapListContainer from './MapListContainer';
import MapContainer from './MapContainer';
import Popup from '../components/Geovideo/Popup';
import ResetPasswordContainer from './ResetPasswordContainer';
import AutoMapState from '../components/ShareMap/AutoMapState';

// Root component that contains the Routes
function App({ store }) {
  return <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/">
          {() => (
            <MapListContainer />
          )}
        </Route>
        <Route path="/map-state/:mapStateSlug" component={AutoMapState} />
        <Route path="/mapas/:mapSlug">
          {({ match }) => (
            <MapContainer
              mapSlug={match.params.mapSlug}
            />
          )}
        </Route>
        <Route path="/login/" component={LoginContainer} />
        <Route path="/register/" component={RegisterContainer} />
        <Route path="/video/" component={Popup} />
        <Route path="/reset-password/:uid/:token" component={ResetPasswordContainer} />
      </Switch>
    </Router>
  </Provider>
}

App.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  store: PropTypes.object.isRequired,
};

export default App;
