import { combineReducers } from 'redux';
import mapReducer from './mapReducer';
import mapListReducer from './mapListReducer';
import userReducer from './userReducer';
import featureSearchReducer from './featureSearchReducer';
import sidebarReducer from './sidebarReducer';

const rootReducer = combineReducers({
  map: mapReducer,
  mapList: mapListReducer,
  user: userReducer,
  featureSearch: featureSearchReducer,
  sidebar: sidebarReducer,
});

export default rootReducer;
