import { useState } from 'react';
import PropTypes from 'prop-types';
import OverlayLayerSwitcherItem from './OverlayLayerSwitcherItem';
import {
  SidebarHeader,
  SidebarTitle,
  SelectableButton,
  HeaderActionButtonContainer,
} from '../../presentational/common';
import Layer from '../../../models/Layer';
import adminToolbox, { ADMIN_ACTIONS } from '../../Admin/AdminComponent';
import ExternalSourceImporter from '../../ExternalLayersImport/ExternalSourceImporter';
import Projection from 'ol/proj/Projection';
import styled from 'styled-components';
import "./OverlayLayerSwitcherGroup.scss"

import { EXTRA_SMALL_MAX } from '../../presentational/mediaBreakpoints';

const OverlayLayerContent = styled.div`
  flex: 1;
  position: relative;
  overflow: auto;
  padding-bottom: 10vh;
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: ${EXTRA_SMALL_MAX}) {
    height: 100vh;
  }
`;

function OverlayLayerSwitcherGroup({ layers, renderAdminButtons, addLayerToMap, viewProjection, map }) {
  const [importExternal, setImportExternal] = useState(false);

  const handleImportExternalClick = () => {
    setImportExternal(true);
  };

  const closeImportExternalModal = () => {
    setImportExternal(false);
  };

  return (
    <>
      <SidebarContent>
        <OverlayLayerContent>
          <SidebarHeader>
            <SidebarTitle>Layers Overlay</SidebarTitle>
            <HeaderActionButtonContainer>
              {renderAdminButtons && renderAdminButtons()}
            </HeaderActionButtonContainer>
          </SidebarHeader>
          {layers.map((layer) => (
            <OverlayLayerSwitcherItem
              key={layer.id}
              layer={layer}
              actions={[
                {
                  action: ADMIN_ACTIONS.EDIT,
                  url: layer.detailURL,
                  component: SelectableButton,
                  children: <i className="fas fa-pencil-alt" />,
                  title: 'Editar',
                },
                {
                  action: ADMIN_ACTIONS.DELETE,
                  url: layer.detailURL,
                  component: SelectableButton,
                  children: <i className="fas fa-trash-alt" />,
                  title: 'Apagar',
                },
              ]}
            />
          ))}
          <ExternalSourceImporter
            open={importExternal}
            addLayerToMap={addLayerToMap}
            viewProjection={viewProjection}
            closeCallback={closeImportExternalModal}
          />
        </OverlayLayerContent>
      </SidebarContent>
    </>
  );
}

OverlayLayerSwitcherGroup.propTypes = {
  layers: PropTypes.arrayOf(PropTypes.instanceOf(Layer)).isRequired,
  addLayerToMap: PropTypes.func.isRequired,
  viewProjection: PropTypes.instanceOf(Projection).isRequired,
  renderAdminButtons: PropTypes.func,
};

OverlayLayerSwitcherGroup.defaultProps = {
  renderAdminButtons: null,
};

export default adminToolbox(OverlayLayerSwitcherGroup);
