import React from 'react';
import styled from 'styled-components';
import logo from '../../images/client-assets/logo.png';
import ResponsiveImage from './common/ResponsiveImage';


const ContainerDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding: 15px 30px;
  background-color: white;
  border-bottom-right-radius: 10px;
`;

const LogoImage = styled(ResponsiveImage)`
  max-width: 200px;
`;


const Logo = () => (
  <ContainerDiv>
    <a href="/">
      <LogoImage src={logo} />
    </a>
  </ContainerDiv>
);

export default Logo;
