import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Layer from '../../models/Layer';
import FormRJSF from '../../components/RJSForm/Form';
import { Button } from '../presentational/common';
import Feature from 'ol/Feature';
import Modal from 'react-modal';
import '../RJSForm/jsonschema-form.scss';
import validator from '@rjsf/validator-ajv8';


/**
 * Component that display the selected feature information
 */
const FeatureInfoDisplayer = ({ feature, jsonschema, layer, dataChangedCallback, formCancelledCallback, formOpen = false, cancelText, }) => {
  const [isFormOpen, setIsFormOpen] = useState(formOpen);

  // editable attributes schema for current feature type
  // filter only the attributes that can be edited
  const editableAttributes = Object.fromEntries(
    layer.editionAttributes.map(({ property, alias }) => [property, alias]));

  jsonschema.properties = Object.fromEntries(
    Object.entries(jsonschema.properties)
      .filter(([key,]) =>
        Object.keys(editableAttributes)
          .indexOf(key) !== -1)
      .map(([key, props]) => [key, {
        ...props,
        title: editableAttributes[key]
      }])
  );

  jsonschema.required = jsonschema.required.filter(field =>
    Object.keys(editableAttributes)
      .indexOf(field) !== -1
  );

  const formSubmitHandler = ({ formData }) => {
    feature.setProperties(formData);
    dataChangedCallback(feature);

    setIsFormOpen(false);
  };

  const formCancelHandler = () => {
    formCancelledCallback();

    setIsFormOpen(false);
  };

  return (
    <React.Fragment>
      <Button onClick={() => setIsFormOpen(true)}>Editar atributos</Button>
      <Modal
        isOpen={isFormOpen}
        ariaHideApp={false}
      >
        <FormRJSF
          schema={jsonschema}
          formData={feature.getProperties()}
          onSubmit={formSubmitHandler}
          validator={validator}
          className={'jsonschema-form'}
          omitExtraData={true}
          liveOmit={true}
        >
          <Button type="submit">Gravar</Button>
          <Button onClick={formCancelHandler}>{cancelText}</Button>
        </FormRJSF>
      </Modal>
    </React.Fragment>
  );
};

FeatureInfoDisplayer.propTypes = {
  feature: PropTypes.instanceOf(Feature).isRequired,
  layer: PropTypes.instanceOf(Layer).isRequired,
  dataChangedCallback: PropTypes.func.isRequired,
  jsonschema: PropTypes.object,
};

export default FeatureInfoDisplayer;
