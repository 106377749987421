import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';
import Constants from '../../utils/constants';
import { LAYER_TYPES } from '../../factories/LayerFactory';
import Layer from '../../models/Layer';
import SidebarContent from '../presentational/SidebarContent';
import { TextInput, Select, Button, LoadingSpinner } from '../presentational/common';
import { Vector, Tile } from 'ol/layer';
import OSM from 'ol/source/OSM';
import OLMap from 'ol/Map';
import MeasureTools from '../Measure/MeasureTools';
import GeoJSON from 'ol/format/GeoJSON';
import TileWMS from 'ol/source/TileWMS';
import { ImageWMS } from 'ol/source';
import { useSelector } from 'react-redux';
import { getCenter } from 'ol/extent';
import UploadFileToMap from '../UploadFilesToMap/UploadFileToMap.js';
import GenerateShareMap from '../ShareMap/GenerateShareMap.js';

const RowDiv = styled.div`
  display: flex;
  margin-bottom: 15px;

  div,
  select {
    flex: 1;
  }

  > div:not(:last-child),
  > select:not(:last-child) {
    margin-right: 3%;
  }
`;

/**
 * Enum for all the possible states
 * @readonly
 * @enum {number}
 */
const STATES = {
  INITIAL_LOADING: 0,
  READY_TO_PRINT: 1,
  WAITING_FOR_THE_PRINT: 2,
};

const SCALES = [2000, 10000];

// TODO: Limitations - Print features drawn by the user, Print other formats,
//  Preview map with the right scale, Print map caption

/**
 * Component to print the map
 */
function PrintMap(props) {
  const [currentState, setCurrentState] = useState(STATES.INITIAL_LOADING);
  const [selectedLayout, setSelectedLayout] = useState(null);
  const [layouts, setLayouts] = useState([]);
  const [selectedLayoutName, setSelectedLayoutName] = useState(null);
  const [selectedScale, setSelectedScale] = useState(null);
  const [selectedDpi, setSelectedDpi] = useState(null);
  const [fieldValues, setFieldValues] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState('pdf');
  const [formErrors, setFormErrors] = useState({});

  let source = null;

  useEffect(() => {
    requestPrintCapabilities();
    return () => {
      source.cancel();
    };
  }, []);

  /**
   * Capabilities request to MapFish Print
   */
  const requestPrintCapabilities = () => {
    source = axios.CancelToken.source();
    axios
      .get(`${Constants.MAPFISH_BASE_URL}/default/capabilities.json`,
        {
          cancelToken: source.token,
          // Force no cache for this request
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          },
        })
      .then((response) => {
        // Possible formats to print
        const formats = response.data.formats;
        // Possible layouts to print
        setLayouts(
          response.data.layouts.map((layout) => {
            const { clientInfo } = layout.attributes.find((attr) => attr.type === 'MapAttributeValues');
            const fields = layout.attributes.filter((attr) => attr.type === 'String');
            return {
              name: layout.name,
              dpiSuggestions: clientInfo.dpiSuggestions,
              scales: SCALES,
              fields,
            };
          }),
        );
      });
    // TODO: Error handling
  };
  useEffect(() => {
    // [this.selectedFormat] = this.formats;
    // TODO: Enable other formats
    if (layouts.length > 0) {
      setFieldValues(
        layouts[0].fields.reduce((acc, field) => {
          acc[field.name] = '';
          return acc;
        }, {}),
      );
      setSelectedScale(layouts[0].scales[0]);
      setSelectedDpi(layouts[0].dpiSuggestions[0]);
      setSelectedLayoutName(layouts[0].name);
      setSelectedLayout(layouts[0]);
      setCurrentState(STATES.READY_TO_PRINT);
    }
  }, [layouts]);

  /**
   * Request to store the print request information in the backend
   */
  const storePrintRequest = async (layersToPrint, centerDrawLayer) => {
    const mapProjection = props.map.getView().getProjection().getCode();
    const authToken = localStorage.getItem('access_token');
    const username = localStorage.getItem('user');
    const mapId = props.mapConfig.id;

    const data = {
      username,
      map: mapId,
      layout: selectedLayoutName,
      name: fieldValues.Nome,
      nif: fieldValues.NIF,
      projection: mapProjection,
      center: centerDrawLayer,
      scale: selectedScale,
      dpi: selectedDpi,
      active_layers: layersToPrint,
    };
    const headers = {
      'Content-Type': 'application/json'
    }
    if(authToken && username) {
      headers["Authorization"] = `Token ${authToken}`
    }
    try {
      const response = await axios.post(`${Constants.API_BASE_URL}/print-request-info/`, data, {
        headers: headers
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        // Set form errors based on the response from the server
        setFormErrors(error.response.data);
      } else {
        // Handle other types of errors
        alert('An error occurred while processing your request. Please try again.');
      }
      setCurrentState(STATES.READY_TO_PRINT);
      throw error;
    }
  };

  /**
   * Print Job request to MapFish Print
   * @param {Array.<Object>} layersToPrint Layers to print
   */
  const requestPrintJob = (layersToPrint, legendLayer, centerDrawLayer) => {
    const mapProjection = props.map.getView().getProjection().getCode();
    axios
      .post(`${Constants.MAPFISH_BASE_URL}/report.${selectedFormat}`, {
        layout: selectedLayoutName,
        attributes: {
          ...fieldValues,
          map: {
            projection: mapProjection,
            center: centerDrawLayer,
            scale: selectedScale,
            dpi: selectedDpi,
            layers: layersToPrint,
          },
          legend: {
            name: 'Legenda',
            classes: legendLayer,
          },
        },
      })
      .then((response) => {
        // Check print status every 2 sec until print is done
        const statusIntervalId = setInterval(() => {
          axios.get(Constants.MAPFISH_BASE_URL + response.data.statusURL).then((statusResponse) => {
            if (statusResponse.data.done) {
              clearInterval(statusIntervalId);
              // launch file download
              window.location.href = Constants.MAPFISH_BASE_URL + statusResponse.data.downloadURL;
              setCurrentState(STATES.READY_TO_PRINT);
            }
          });
        }, 2000);
      });
    // TODO: Error handling && Timeout
  };

  const processMapState = () => {
    // all wms and wfs layers that are visible excluding the OSM layer
    // reverse because the print request wants the layers from the top one until the back one
    const layers = props.map
      .getLayers()
      .getArray()
      .filter(
        (l) =>
          l.getVisible() &&
          (l instanceof Tile || l instanceof Vector) &&
          l.get('name') !== 'measure'
      )
      .reverse();

    // transform layers into the print job expected parameters
    const layersParameters = layers.map((layer) => {
      const layerModel = props.layers.find((l) => l.olLayer === layer);
      let parameters = {};
      // Get parameters for OSM layer
      if (layer.getSource() instanceof OSM) {
        parameters = {
          baseURL: layer.getSource().getUrls()[0],
          imageExtension: 'png',
          type: 'osm',
        };
      }
      // Get parameters for wms and wfs layers
      else {
        parameters = {
          type: 'tiledwms',
          baseURL: layerModel.serviceUrl,
          layers: [layerModel.featureTypeName || layerModel.layerName],
          tileSize: [256, 256],
          opacity: layerModel.olLayer.getOpacity(),
        };
      }

      if (layerModel.type === LAYER_TYPES.OVERLAY) {
        parameters.customParams = { TRANSPARENT: 'true' };
      }
      return parameters;
    });
    // Get measure layer to send to mapfish print
    const legendLayers = layers.reduce((acc, layer) => {
      const layerModel = props.layers.find((l) => l.olLayer === layer);
      const source = layerModel.olLayer.getSource();
      if ((source instanceof ImageWMS || source instanceof TileWMS) && layerModel.type === 1) {
        acc.push({ icons: [source.getLegendUrl()], name: layerModel.name });
      }
      return acc;
    }, []);
    const LINECOLOR = '#f44e42';
    let centerDrawLayer;
    props.map.getLayers().forEach((layer) => {
      if (layer.get('name') === 'measure') {
        const features = layer.getSource().getFeatures();
        features.forEach((feature) => feature.setProperties({ type: feature.getGeometry().getType() }));
        const geoJSONFormat = new GeoJSON();
        const geoJson = geoJSONFormat.writeFeatures(features);
        if (features.length > 0) {
          centerDrawLayer = getCenter(layer.getSource().getExtent());
          layersParameters.unshift({
            type: 'geojson',
            geoJson,
            style: {
              styleProperty: 'type',
              version: '1',
              Polygon: {
                type: 'polygon',
                fillColor: '#FFFFFF',
                fillOpacity: 0.2,
                strokeColor: LINECOLOR,
                strokeWidth: 2,
              },
              Point: {
                type: 'point',
                pointRadius: 7,
                fillColor: LINECOLOR,
                strokeColor: LINECOLOR,
                strokeWidth: 2,
              },
              LineString: {
                type: 'linestring',
                fillColor: LINECOLOR,
                strokeColor: LINECOLOR,
                strokeWidth: 2,
              },
            },
          });
        }
      }
    });

    return [layersParameters, centerDrawLayer]
  }

  const handlePrintClick = async () => {
    // Validate form before sending the print request
    const errors = {};
    if (!fieldValues.Nome) {
      errors.Nome = "Este campo é obrigatório";
    }
    if (!fieldValues.NIF) {
      errors.NIF = "Este campo é obrigatório";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // TODO: group by url
    setCurrentState(STATES.WAITING_FOR_THE_PRINT);
    // all wms and wfs layers that are visible excluding the OSM layer
    // reverse because the print request wants the layers from the top one until the back one
    const layers = props.map
      .getLayers()
      .getArray()
      .filter(
        (l) =>
          l.getVisible() &&
          (l instanceof Tile || l instanceof Vector) &&
          l.get('name') !== 'measure'
      )
      .reverse();

    // transform layers into the print job expected parameters
    const layersParameters = layers.map((layer) => {
      const layerModel = props.layers.find((l) => l.olLayer === layer);
      let parameters = {};
      // Get parameters for OSM layer
      if (layer.getSource() instanceof OSM) {
        parameters = {
          baseURL: layer.getSource().getUrls()[0],
          imageExtension: 'png',
          type: 'osm',
        };
      }
      // Get parameters for wms and wfs layers
      else {
        parameters = {
          type: 'tiledwms',
          baseURL: layerModel.serviceUrl,
          layers: [layerModel.featureTypeName || layerModel.layerName],
          tileSize: [256, 256],
          opacity: layerModel.olLayer.getOpacity(),
        };
      }

      if (layerModel.type === LAYER_TYPES.OVERLAY) {
        parameters.customParams = { TRANSPARENT: 'true' };
      }
      return parameters;
    });
    // Get measure layer to send to mapfish print
    const legendLayers = layers.reduce((acc, layer) => {
      const layerModel = props.layers.find((l) => l.olLayer === layer);
      const source = layerModel.olLayer.getSource();
      if ((source instanceof ImageWMS || source instanceof TileWMS) && layerModel.type === 1) {
        acc.push({ icons: [source.getLegendUrl()], name: layerModel.name });
      }
      return acc;
    }, []);
    const LINECOLOR = '#f44e42';
    let centerDrawLayer;
    props.map.getLayers().forEach((layer) => {
      if (layer.get('name') === 'measure') {
        centerDrawLayer = getCenter(layer.getSource().getExtent());
        const features = layer.getSource().getFeatures();
        features.forEach((feature) => feature.setProperties({ type: feature.getGeometry().getType() }));
        const geoJSONFormat = new GeoJSON();
        const geoJson = geoJSONFormat.writeFeatures(features);
        if (features.length > 0) {
          layersParameters.unshift({
            type: 'geojson',
            geoJson,
            style: {
              styleProperty: 'type',
              version: '1',
              Polygon: {
                type: 'polygon',
                fillColor: '#FFFFFF',
                fillOpacity: 0.2,
                strokeColor: LINECOLOR,
                strokeWidth: 2,
              },
              Point: {
                type: 'point',
                pointRadius: 7,
                fillColor: LINECOLOR,
                strokeColor: LINECOLOR,
                strokeWidth: 2,
              },
              LineString: {
                type: 'linestring',
                fillColor: LINECOLOR,
                strokeColor: LINECOLOR,
                strokeWidth: 2,
              },
            },
          });
        }
      }
    });

    if (layersParameters.every((layer) => layer.type !== 'geojson')) {
      alert(PrintMap.messages.alert_draw);
      setCurrentState(STATES.READY_TO_PRINT);
      return;
    }

    try {
      await storePrintRequest(layersParameters, centerDrawLayer);
      requestPrintJob(layersParameters, legendLayers, centerDrawLayer);
    } catch (error) {
      throw error
    }
  };

  const handleScaleChange = (event) => {
    setSelectedScale(event.target.value);
  };

  const handleLayoutChange = (event) => {
    setSelectedLayoutName(event.target.value);
  };

  const handleDPIChange = (event) => {
    setSelectedDpi(event.target.value);
  };

  const handleFormatChange = (event) => {
    setSelectedFormat(event.target.value);
  };

  const handleAttributeChange = (event, fieldName) => {
    setFieldValues({ ...fieldValues, [fieldName]: event.target.value });
    setFormErrors({ ...formErrors, [fieldName]: '' });
  };

  const renderLayoutOptions = () => {
    return (
      <Select onChange={handleLayoutChange}>
        {layouts.map((layout) => (
          <option key={layout.name} value={layout.name}>
            {layout.name}
          </option>
        ))}
      </Select>
    );
  };

  const renderDPIOptions = () => {
    return (
      <Select onChange={handleDPIChange}>
        {selectedLayout.dpiSuggestions.map((dpis) => (
          <option key={dpis} value={dpis}>
            {dpis} dpis
          </option>
        ))}
      </Select>
    );
  };

  const renderScaleOptions = () => {
    return (
      <Select onChange={handleScaleChange}>
        {selectedLayout.scales.map((scale) => (
          <option key={scale} value={scale}>
            1:{scale}
          </option>
        ))}
      </Select>
    );
  };

  const renderFormatOptions = () => {
    return (
      <Select value="pdf" disabled onChange={handleFormatChange}>
        <option value="pdf">pdf</option>
      </Select>
    );
    // TODO: Enable other formats
    /*
    return (
      <select onChange={this.handleFormatChange}>
        {this.formats.map(format => <option key={format} value={format}>{format}</option>)}
      </select>
    );
    */
  };

  const renderAttributeFields = () => {
    // TODO: More input types?
    return selectedLayout.fields.map((field) => (
      <div key={field.name}>
        <label htmlFor={field.name}>{field.name}:</label>
        <TextInput id={field.name} type="text" onChange={(event) => handleAttributeChange(event, field.name)} />
        {formErrors[field.name] && (
        <div style={{ color: 'red', fontSize: '0.8em' }}>{formErrors[field.name]}</div>
        )}
      </div>
    ));
  };

  return (
    <>
      <MeasureTools map={props.map} sideBarTitle="Imprimir Mapa" openTool={props.openTool}/>
      <SidebarContent>
        <UploadFileToMap map={props.map} onLayerAdded={() => {}} />
        <RowDiv>
          <GenerateShareMap processMapState={processMapState} map={props.map} mapConfig={props.mapConfig}/>
        </RowDiv>
        {currentState === STATES.READY_TO_PRINT ? (
          <>
            <RowDiv>{renderAttributeFields()}</RowDiv>
            <RowDiv>{renderScaleOptions()}</RowDiv>
            <RowDiv>
              {renderLayoutOptions()}
              {renderDPIOptions()}
              {renderFormatOptions()}
            </RowDiv>
            <RowDiv>
              <Button onClick={handlePrintClick}>{PrintMap.messages.print_button_text}</Button>
            </RowDiv>
          </>
        ) : (
          <LoadingSpinner />
        )}
      </SidebarContent>
    </>
  );
}

PrintMap.propTypes = {
  map: PropTypes.instanceOf(OLMap).isRequired,
  layers: PropTypes.arrayOf(PropTypes.instanceOf(Layer)).isRequired,
  openTool: PropTypes.func.isRequired,
};

PrintMap.messages = {
  alert_draw: 'Desenho não encontrado',
  title: 'Imprimir mapa',
  print_button_text: 'Imprimir',
  share_map_text: 'Gerar link para partilhar',
};

export default PrintMap;
