import styled from 'styled-components';

const ActionButtonContainer = styled.div`
  display: flex;
   > button:not(:last-child) {
    margin-right: 5px;
   }
`;

const HeaderActionButtonContainer = styled(ActionButtonContainer)`
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`;


export default ActionButtonContainer;
export { HeaderActionButtonContainer };
