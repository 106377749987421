import { useState } from "react";
import PropTypes from "prop-types";
import {
  SidebarHeader,
  SidebarTitle,
  HeaderActionButtonContainer,
  SelectableButton,
} from "../../presentational/common";
import LayerSwitcherItem from "./BaseLayerSwitcherItem";
import Layer from "../../../models/Layer";
import adminToolbox, { ADMIN_ACTIONS } from "../../Admin/AdminComponent";

/**
 * Subcomponent of the LayerSwitcher to toggle the visibility of base layers
 */
function BaseLayerSwitcherGroup({ layers, renderAdminButtons }) {
  // Initialize visibleLayer based on visible base layers
  const [visibleLayer, setVisibleLayer] = useState(() => {
    const visibleBaseLayers = layers.filter((layer) => layer.olLayer.getVisible());
    if (visibleBaseLayers.length > 1) {
      visibleBaseLayers.forEach((layer, index) => layer.olLayer.setVisible(index === 0));
    }
    return visibleBaseLayers[0] || null;
  });

  // Handle changing the visible layer
  const handleChange = (newVisibleLayer) => {
    if (visibleLayer) {
      visibleLayer.olLayer.setVisible(false);
    }
    newVisibleLayer.olLayer.setVisible(true);
    setVisibleLayer(newVisibleLayer);
  };

  return (
    <>
      <SidebarHeader>
        <SidebarTitle>Layers Base</SidebarTitle>
        <HeaderActionButtonContainer>{renderAdminButtons && renderAdminButtons()}</HeaderActionButtonContainer>
      </SidebarHeader>
      {layers.map((layer) => (
        <LayerSwitcherItem
          key={layer.id}
          layer={layer}
          isChecked={visibleLayer === layer}
          setActiveLayerCallback={handleChange}
          actions={[
            {
              action: ADMIN_ACTIONS.EDIT,
              url: layer.detailURL,
              component: SelectableButton,
              children: <i className="fas fa-pencil-alt" />,
              title: "Editar",
            },
            {
              action: ADMIN_ACTIONS.DELETE,
              url: layer.detailURL,
              component: SelectableButton,
              children: <i className="fas fa-trash-alt" />,
              title: "Apagar",
            },
          ]}
        />
      ))}
    </>
  );
}

BaseLayerSwitcherGroup.propTypes = {
  layers: PropTypes.arrayOf(PropTypes.instanceOf(Layer)).isRequired,
  renderAdminButtons: PropTypes.func,
};

BaseLayerSwitcherGroup.defaultProps = {
  renderAdminButtons: null,
};

export default adminToolbox(BaseLayerSwitcherGroup);
