import styled, { css } from 'styled-components';

const TransparentInput = styled.input`
  width: 100%;
  border: 0;
  border-radius: 2px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  color: white;

  &::placeholder {
    opacity: 0.7;
    color: white;
  }

  ${props => props.marginBottom && css`
    margin-bottom: ${props.marginBottom};
 `}
`;

export default TransparentInput;
