const API_BASE_URL = process.env.REACT_APP_API_URL;

const MAPFISH_BASE_URL = process.env.REACT_APP_MAPFISH_URL;

const EPSG_IO = 'https://epsg.io';

const Constants = {
  API_BASE_URL,
  MAPFISH_BASE_URL,
  EPSG_IO,
};

export default Constants;
