import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes, { func } from 'prop-types';
import FeatureModificationRecord, { FEATURE_ACTION } from '../../models/FeatureModificationRecord';
import Layer from '../../models/Layer';
import Modify from 'ol/interaction/Modify';
import Snap from 'ol/interaction/Snap';
import Collection from 'ol/Collection';
import SelectInteraction from 'ol/interaction/Select';
import SidebarContent from '../presentational/SidebarContent';
import { Button, Select, SidebarHeader, SidebarTitle } from '../presentational/common';
import styled from 'styled-components';
import axios from 'axios';
import Constants from '../../utils/constants';
import NormalText from '../presentational/common/NormalText';
import DrawControls from './DrawControls';
import FeatureInfoDisplayer from '../FeatureInfoDisplayer/FeatureInfoDisplayer';
import Draw from 'ol/interaction/Draw';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';

const SectionContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

const LayerSelect = styled(Select)`
  flex: 1;
  margin-right: 10px;
`;

/**
 * Enum for all the modes supported by the FeatureEditTools component
 * @readonly
 * @enum {string}
 */
const EDIT_MODE = {
  IDLE: 'Idle',
  MODIFY: 'Modify',
  DRAW_POINT: 'Point',
  DRAW_LINE: 'LineString',
  DRAW_POLYGON: 'Polygon',
  END_DRAWING: 'EndDrawing',
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const defaultSelectedStyle = new Style({
  stroke: new Stroke({
    color: 'rgb(255,165,0)',
    width: 1.25,
  }),
  fill: new Fill({
    color: 'rgba(255,165,0,0.4)',
  }),
  image: new CircleStyle({
    fill: new Fill({
      color: 'rgba(255,165,0,0.4)',
    }),
    stroke: new Stroke({
      color: 'rgb(255,165,0)',
      width: 1.25,
    }),
    radius: 5,
  }),
});

export default function FeatureEditTools(props) {
  // State
  const [currentMode, setCurrentMode] = useState(EDIT_MODE.IDLE);
  const [targetLayer, setTargetLayer] = useState(null);
  const [featureSchema, setFeatureSchema] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [hasPendingChanges, setHasPendingChanges] = useState(null);
  // Previous state for didUpdate logic
  const previousMode = usePrevious(currentMode);

  // Replace all 'this' variables with refs
  const targetLayerRef = useRef();
  const selectInteraction = useRef();
  const modifyInteraction = useRef();
  const targetLayerStyle = useRef();
  const modificationRecord = useRef(new FeatureModificationRecord(props.projection));
  const snapInteraction = useRef();
  const drawInteraction = useRef();

  // didMount
  useEffect(() => {
    // Setup modifications observable
    modificationRecord.current.addObserver('changed', () =>
      setHasPendingChanges(modificationRecord.current.hasPendingChanges()),
    );

    // Setup all map interactions
    setupMapInteractions();

    // componentWillUnmount
    return () => {
      disableCurrentMode(currentMode);

      if (targetLayer !== null) {
        targetLayer.olLayer.setStyle(targetLayerStyle.current);
      }

      props.editableLayers.forEach((layer) => {
        layer.olLayer.getSource().clear();
      });
    };
  }, []);

  useEffect(() => {
    targetLayerRef.current = targetLayer;
  }, [targetLayer]);

  // componentDidUpdate
  useEffect(() => {
    // Only toggle component mode if the old state is different from the current
    if (currentMode === previousMode) {
      return;
    }

    disableCurrentMode(previousMode);

    // Enable next mode state
    switch (currentMode) {
      case EDIT_MODE.DRAW_POINT:
      case EDIT_MODE.DRAW_LINE:
      case EDIT_MODE.DRAW_POLYGON:
        enableDrawMode(currentMode);
        break;
      case EDIT_MODE.MODIFY:
        enableModifyMode();
        break;
      default:
        break;
    }
  }, [currentMode]);

  const setupMapInteractions = () => {
    // Setup select interaction
    (selectInteraction.current = new SelectInteraction({
      layers: (layer) => layer === targetLayerRef.current.olLayer,
    })),
      selectInteraction.current.on('select', (event) => {
        const selectedFeature = event.selected.pop();
        setSelectedFeature(selectedFeature);
      });

    modifyInteraction.current = new Modify({
      // Only the selected feature is modifiable
      features: selectInteraction.current.getFeatures(),
    });
    modifyInteraction.current.on('modifyend', handleModifyEnd);

    const editableVectorLayers = props.editableLayers.map((layer) => layer.olLayer);

    snapInteraction.current = new Snap({
      // Enable snapping on all features from all the editable layers
      features: editableVectorLayers.reduce(
        (featureCollection, layer) => featureCollection.extend(layer.getSource().getFeatures()),
        new Collection(),
      ),
    });

    // Add listeners to the add/remove events from the editable layers to
    // update the features than can be used for snapping
    editableVectorLayers.forEach((layer) => {
      layer.getSource().on('addfeature', (event) => snapInteraction.current.addFeature(event.feature));
      layer.getSource().on('removefeature', (event) => snapInteraction.current.removeFeature(event.feature));
    });

    // Set the initial state for the map interactions
    selectInteraction.current.setActive(false);
    modifyInteraction.current.setActive(false);
    snapInteraction.current.setActive(true);

    // Add the interactions to the map
    props.addInteractionCallback(selectInteraction.current);
    props.addInteractionCallback(modifyInteraction.current);
    props.addInteractionCallback(snapInteraction.current);
  };

  const disableCurrentMode = (mode) => {
    switch (mode) {
      case EDIT_MODE.DRAW_POINT:
      case EDIT_MODE.DRAW_LINE:
      case EDIT_MODE.DRAW_POLYGON:
        disableDrawMode();
        break;
      case EDIT_MODE.MODIFY:
        disableModifyMode();
        break;
      case EDIT_MODE.END_DRAWING:
        break;
      default:
        break;
    }
  };

  const enableDrawMode = (drawingType) => {
    const { geometry_name: geometryName } = featureSchema;

    drawInteraction.current = new Draw({
      type: drawingType,
      source: targetLayer.olLayer.getSource(),
      geometryName,
    });
    drawInteraction.current.on('drawend', handleDrawEnd);

    // It's necessary to remove the snap interaction and add it again after the
    // draw interaction to enable snapping of drawn features
    props.removeInteractionCallback(snapInteraction.current);
    props.addInteractionCallback(drawInteraction.current);
    props.addInteractionCallback(snapInteraction.current);
  };

  function disableDrawMode() {
    props.removeInteractionCallback(drawInteraction.current);
    drawInteraction.current.un('drawend', handleDrawEnd);
    drawInteraction.current = null;
  }

  function enableModifyMode() {
    selectInteraction.current.setActive(true);
    modifyInteraction.current.setActive(true);

    document.addEventListener('keydown', handleKeyDown);
  }

  function disableModifyMode() {
    selectInteraction.current.setActive(false);
    unselectFeature();
    modifyInteraction.current.setActive(false);

    document.removeEventListener('keydown', handleKeyDown);
  }

  function handleLayerChange(event) {
    const targetLayer = props.editableLayers.find((layer) => layer.id === event.currentTarget.value);

    selectInteraction.current.setActive(true);
    modifyInteraction.current.setActive(true);

    document.addEventListener('keydown', handleKeyDown);

    if (targetLayer !== null) {
      targetLayer.olLayer.setStyle(targetLayerStyle.current);
    }

    targetLayerStyle.current = targetLayer.olLayer.getStyle();
    targetLayer.olLayer.setStyle(defaultSelectedStyle);

    setTargetLayer(targetLayer);
    setFeatureSchema(null);
    targetLayer.getFeatureSchema().then((featureSchema) => {
      setFeatureSchema(featureSchema);
    });
  }

  function handleModifyEnd(event) {
    const modifiedFeature = event.features.item(0);

    // Add a new entry for the modified feature
    modificationRecord.current.addNewEntry(modifiedFeature, targetLayer, FEATURE_ACTION.MODIFY);
  }

  function handleDrawEnd({ feature }) {
    selectInteraction.current.getFeatures().push(feature);

    modificationRecord.current.addNewEntry(feature, targetLayer, FEATURE_ACTION.CREATE);

    setCurrentMode(EDIT_MODE.END_DRAWING);
    setSelectedFeature(feature);
  }

  function handleFeatureDataChange(feature) {
    modificationRecord.current.addNewEntry(feature, targetLayer, FEATURE_ACTION.MODIFY);

    setCurrentMode(EDIT_MODE.MODIFY);
  }

  function handleKeyDown(event) {
    // if the user pressed the delete key with a feature selected the it is
    // deleted
    if (event.keyCode === 46 && selectedFeature !== undefined) {
      event.preventDefault();

      // Get the layer of the selected feature and remove it from that layer
      // and from the select interaction
      targetLayer.olLayer.getSource().removeFeature(selectedFeature);

      // Add a new entry for the deleted feature
      modificationRecord.current.addNewEntry(selectedFeature, targetLayer, FEATURE_ACTION.DELETE);

      unselectFeature();
    }
  }

  function handleDrawTypeChange(drawType) {
    setCurrentMode(drawType === null ? EDIT_MODE.MODIFY : drawType);
  }

  function handleFormCancel() {
    if (currentMode === EDIT_MODE.END_DRAWING) {
      targetLayer.olLayer.getSource().removeFeature(selectedFeature);
      modificationRecord.current.addNewEntry(selectedFeature, targetLayer, FEATURE_ACTION.DELETE);

      unselectFeature();
    }

    setCurrentMode(EDIT_MODE.MODIFY);
  }

  function unselectFeature() {
    selectInteraction.current.getFeatures().clear();

    setSelectedFeature(null);
  }

  return (
    <React.Fragment>
      <SidebarHeader collapseClick={() => {}}>
        <SidebarTitle>{FeatureEditTools.messages.draw_map}</SidebarTitle>
      </SidebarHeader>
      <SidebarContent>
        <NormalText>{FeatureEditTools.messages.help_message}</NormalText>
        <SectionContainer>
          <LayerSelect value={targetLayer !== null ? targetLayer.id : ''} onChange={handleLayerChange}>
            <option disabled value="">
              {FeatureEditTools.messages.choose_target_layer}
            </option>
            {props.editableLayers.map((layer) => (
              <option key={layer.id} value={layer.id}>
                {layer.name}
              </option>
            ))}
          </LayerSelect>
        </SectionContainer>
        {targetLayer && featureSchema && (
          <DrawControls
            geometryType={featureSchema.geometry}
            currentDrawType={currentMode}
            snapChangeCallback={(enabled) => snapInteraction.current.setActive(enabled)}
            drawTypeChangeCallback={handleDrawTypeChange}
          />
        )}
        {selectedFeature && (
          <SectionContainer>
            <FeatureInfoDisplayer
              feature={selectedFeature}
              layer={targetLayer}
              jsonschema={featureSchema.jsonschema}
              formOpen={currentMode === EDIT_MODE.END_DRAWING}
              cancelText={currentMode === EDIT_MODE.END_DRAWING ? 'Descartar' : 'Cancelar'}
              formCancelledCallback={handleFormCancel}
              dataChangedCallback={handleFeatureDataChange}
            />
          </SectionContainer>
        )}
        {hasPendingChanges && (
          <SectionContainer>
            <Button
              onClick={() => {
                const transactions = modificationRecord.current.createWFSTransactions();

                transactions.then((t) => {
                  Promise.all(
                    t.map(({ url, data }) =>
                      axios.post(`${Constants.API_BASE_URL}/edition/`, {
                        transaction: new XMLSerializer().serializeToString(data),
                        url,
                      }),
                    ),
                  ).then(() => {
                    props.editableLayers.forEach((layer) => layer.olLayer.getSource().clear());
                    unselectFeature();
                  });

                  modificationRecord.current.clear();
                });
              }}
            >
              {FeatureEditTools.messages.save_modifications}
            </Button>
          </SectionContainer>
        )}
      </SidebarContent>
    </React.Fragment>
  );
}

FeatureEditTools.propTypes = {
  addInteractionCallback: PropTypes.func.isRequired,
  removeInteractionCallback: PropTypes.func.isRequired,
  addTemporaryLayerToMap: PropTypes.func.isRequired,
  editableLayers: PropTypes.arrayOf(PropTypes.instanceOf(Layer)).isRequired,
  projection: PropTypes.string.isRequired,
};

FeatureEditTools.messages = {
  draw_point: 'desenhar ponto',
  draw_line: 'desenhar linha',
  draw_polygon: 'desenhar polígonos',
  choose_target_layer: 'escolha a layer a editar',
  confirm: 'Confirmar',
  discard: 'descartar',
  save_modifications: 'Guardar alterações',
  draw_map: 'Desenhar mapa',
  help_message: 'Faça zoom no mapa até que apareçam os dados disponíveis para edição',
};
