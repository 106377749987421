// TODO: dynamically read configurations from layer
import Observable from '../common/Observable';
import WFS from 'ol/format/WFS';

/**
 * Enum for the feature actions available to the user.
 * @readonly
 * @enum {number}
 */
const FEATURE_ACTION = {
  CREATE: 0,
  MODIFY: 1,
  DELETE: 2,
};

/**
 * Class used to register user made modifications to Features
 */
class FeatureModificationRecord extends Observable {
  constructor(projection = 'EPSG:4326') {
    super();
    this.recordedModifications = new Map();
    this.projection = projection;
  }

  /**
   * Add a new record for a new action performed by the user
   * @param {ol.Feature} feature feature that the user interacted with
   * @param {ol.layer.Vector} layer the feature's layer
   * @param {FEATURE_ACTION} action action performed by the user
   */
  addNewEntry(feature, layer, action) {
    if (!this.recordedModifications.has(layer)) {
      this.recordedModifications.set(layer, new Map());
    }

    // Get the feature current state and perform the state transition based on
    // the user action
    const layerMap = this.recordedModifications.get(layer);
    const currentState = layerMap.get(feature);

    switch (currentState) {
      case undefined:
        layerMap.set(feature,
          FeatureModificationRecord.transitionFromInitial(action));
        break;
      case FEATURE_ACTION.CREATE:
        layerMap.set(feature,
          FeatureModificationRecord.transitionFromCreated(action));
        break;
      case FEATURE_ACTION.MODIFY:
        layerMap.set(feature,
          FeatureModificationRecord.transitionFromModified(action));
        break;
      default:
        throw new Error(`Invalid state transition (from delete to ${action})`);
    }

    this.dispatchEvent('changed', { target: this });
  }

  /**
   * Manage the state transition for a feature currently in the CREATE state
   * @param {FEATURE_ACTION} action action performed by the user
   * @returns {(FEATURE_ACTION|undefined)} new feature state
   */
  static transitionFromCreated(action) {
    switch (action) {
      case FEATURE_ACTION.MODIFY:
        return FEATURE_ACTION.CREATE;
      case FEATURE_ACTION.DELETE:
        return undefined;
      default:
        throw new Error(`Invalid state transition (from created to ${action})`);
    }
  }

  /**
   * Manage the state transition for a feature currently in the MODIFY state
   * @param {FEATURE_ACTION} action action performed by the user
   * @returns {(FEATURE_ACTION|undefined)} new feature state
   */
  static transitionFromModified(action) {
    switch (action) {
      case FEATURE_ACTION.MODIFY:
        return FEATURE_ACTION.MODIFY;
      case FEATURE_ACTION.DELETE:
        return FEATURE_ACTION.DELETE;
      default:
        throw new Error(
          `Invalid state transition (from modified to ${action})`);
    }
  }

  /**
   * Manage the state transition for a feature that doesn't have a defined state
   * @param {FEATURE_ACTION} action action performed by the user
   * @returns {(FEATURE_ACTION|undefined)} new feature state
   */
  static transitionFromInitial(action) {
    return action;
  }

  /**
   * Creates a list of all the wfs transactions for the changes made by the user
   * @returns {Array} list of all wfs transactions
   */
  createWFSTransactions() {
    const transactionPromises = Array.from(this.recordedModifications.entries())
      .map(([layer, featuresMap]) => {
        return layer.getFeatureSchema()
          .then(featureSchema => {
            const layerModifications = {
              [FEATURE_ACTION.CREATE]: [],
              [FEATURE_ACTION.MODIFY]: [],
              [FEATURE_ACTION.DELETE]: [],
            };

            featuresMap.forEach(
              (state, feature) => {
                if (state === undefined) {
                  return;
                }

                const featureProps = feature.getKeys()
                  .filter(prop => prop !== feature.getGeometryName());
                const schemaProps = Object.keys(
                  featureSchema.jsonschema.properties);

                featureProps.filter(prop => schemaProps.indexOf(prop) === -1)
                  .forEach(prop => feature.unset(prop));

                layerModifications[state].push(feature);
              });

            const {
              [FEATURE_ACTION.CREATE]: created,
              [FEATURE_ACTION.MODIFY]: modified,
              [FEATURE_ACTION.DELETE]: deleted,
            } = layerModifications;

            // If the typename has : we split it to get the feature prefix and
            // reverse the output to ensure that the typename is always in the
            // first position
            const [featureType, prefix = ''] = layer.featureTypeName.split(':')
              .reverse();

            const writeOptions = {
              featureNS: prefix || null,
              featurePrefix: prefix,
              featureType,
              srsName: this.projection,
              version: '1.1.0',
            };

            // Ensure that there is at least one change to create the
            // transaction
            if ((created.length + modified.length + deleted.length) === 0) {
              return null;
            }

            return {
              url: layer.serviceUrl,
              data: (new WFS()).writeTransaction(
                created, modified, deleted, writeOptions,
              ),
            };
          });
      });

    return Promise.all(transactionPromises)
      .then(transactions => transactions.filter(t => t !== null));
  }

  /**
   * Returns whether there are pending changes that require a wfs transaction
   * @returns {boolean} true if there are pending changes
   */
  hasPendingChanges() {
    // Look in the records for at least a feature with a "transactionable" state
    return Array.from(this.recordedModifications.values())
      .some(featuresMap => Array.from(featuresMap.values())
        .some(state => state !== undefined));
  }

  /**
   * Clear all the recorded modifications
   */
  clear() {
    this.recordedModifications.clear();
    this.dispatchEvent('changed', { target: this });
  }
}

export default FeatureModificationRecord;
export { FEATURE_ACTION };
