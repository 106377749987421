import axios from 'axios';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import WKT from 'ol/format/WKT';
import Select from 'ol/interaction/Select';
import { Vector } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { Stroke, Style } from 'ol/style';
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import Constants from '../../utils/constants';
import adminToolbox from '../Admin/AdminComponent';
import SidebarContent from '../presentational/SidebarContent';
import { NormalText, SelectableButton } from '../presentational/common';
import Checkbox from '../presentational/common/Checkbox';
import SidebarHeader from '../presentational/common/SidebarHeader';
import SidebarTitle from '../presentational/common/SidebarTitle';
import TextInput from '../presentational/common/TextInput';
import ToolButton from '../presentational/common/ToolButton';
import QueuedVideosDashboard from './QueuedVideosDashboard';
import VideoPlayer from './VideoPlayer';
import { getLineArrowStyle, getLineStyle } from './featureStyles';

const ProjectButton = styled.button.attrs({ type: 'button' })`
  appearance: none;

  text-align: left;
  width: 100%;
  height: 50px;
  border: none;

  background-color: #00a1e8;
  color: white;
  border-bottom: solid 1px white;
  cursor: pointer;
  padding-left: 2rem;

  &.active {
    background-color: #008cd0;

    ~ div {
      height: initial;
    }
  }
`;

const VisualizationProject = styled(ProjectButton)`
  padding-left: 4rem;
`;

const CarTrackButton = styled.button.attrs({ type: 'button' })`
  position: relative;
  appearance: none;
  border: none;
  margin: 0;
  cursor: pointer;
  background-color: white;
  padding: 2px 0;

  border-top: 1px dotted darkgrey;

  &:last-child {
    border-bottom: 1px dotted darkgrey;
  }

  &:hover {
    opacity: 0.8;

    &:not(.active) {
      background-color: #ccc;
    }
  }

  &.active {
    background-color: #008cd0;
    color: white;
  }

  &.seen {
    opacity: 0.5;
  }
`;

const VisualizationProjectContainer = styled.div`
  display: flex;
  flex-direction: column;

  height: 0;
  overflow: hidden;
`;

const SpinnerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 100;
`;

const BoundaryLabel = styled(NormalText.withComponent('label'))`
  text-align: right;
  padding: 10px;
`;

const DeleteViewsButton = styled(ToolButton)`
  font-size: 0.9em;
  background-color: rgba(255, 0, 0, 0.9);
  padding: 7px;
  margin: 5px 0 10px 0;
`;

const CoordinatesInput = styled(TextInput)`
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 20%;
  margin-bottom: 1px;
  text-align: center;
  background-color: white;
  color: black;
  font-size: 0.9em;
`;

function Geovideo({ map, renderAdminButtons }) {
  const [projects, setProjects] = useState(null);
  const [carTracks, setCarTracks] = useState(null);
  const [spinnerEnabled, setSpinnerEnabled] = useState(true);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedVisualizationProject, setSelectedVisualizationProject] = useState(null);
  const [selectedCarTrack, setSelectedCarTrack] = useState(null);
  const [dashboardEnabled, setDashboardEnabled] = useState(false);
  const [mapCoordinates, setMapCoordinates] = useState('');

  const carTrackLayer = useRef();
  const selectInteraction = useRef();

  const handleCarTrackSelection = carTrack => {
    if (carTrack == null || (selectedCarTrack && carTrack.id === selectedCarTrack.id)) {
      setSelectedCarTrack(null);

      selectInteraction.current.getFeatures().clear();
      selectInteraction.current.setMap(map.olMap);
      selectInteraction.current.setActive(true);
      carTrackLayer.current.setMap(map.olMap);
      return;
    }

    selectInteraction.current.setMap(null);
    selectInteraction.current.setActive(false);
    carTrackLayer.current.setMap(null);

    setSpinnerEnabled(true);

    axios.get(carTrack.url).then(({ data }) => {
      const carTrackData = {
        ...data,
        seen: carTrack.seen,
      };

      setSelectedCarTrack({
        id: carTrack.id,
        data: carTrackData,
      });
      setSpinnerEnabled(false);
    });
  };

  const handleVisualizationProjectChange = visualizationProject => {
    carTrackLayer.current.getSource().clear();
    setSelectedCarTrack(null);
    setCarTracks(null);

    if (selectedVisualizationProject === visualizationProject || visualizationProject === null) {
      setSelectedVisualizationProject(null);

      return;
    }

    setSelectedVisualizationProject(visualizationProject);

    setSpinnerEnabled(true);

    axios.get(visualizationProject.url).then(({ data: { car_tracks: carTracksData, boundary_area: boundaryArea } }) => {
      if (boundaryArea != null) {
        const formatter = new WKT();

        const [, wktPolygon] = boundaryArea.split(';');
        const boundaryGeometry = formatter.readGeometry(wktPolygon, {
          featureProjection: map.projection.name,
          dataProjection: 'EPSG:4326',
        });

        const boundaryFeature = new Feature({
          geometry: boundaryGeometry,
        });

        boundaryFeature.setId('boundaryArea');
        boundaryFeature.setStyle(
          new Style({
            stroke: new Stroke({
              color: [0, 0, 0, 1],
              width: 5,
              lineDash: [25, 15],
            }),
            zIndex: 100,
          })
        );

        carTrackLayer.current.getSource().addFeature(boundaryFeature);
      }

      if (carTracksData.length > 0) {
        carTracksData.forEach(carTrack => {
          const replacedRouteCoordinates = carTrack.route.coordinates.map(([lat, lon]) => [lon, lat]);
          const modifiedCarTrack = carTrack;
          modifiedCarTrack.route.coordinates = replacedRouteCoordinates;
          const feature = new GeoJSON().readFeature(carTrack.route, {
            featureProjection: map.projection.name,
            dataProjection: 'EPSG:4326',
          });

          feature.set('carTrack', modifiedCarTrack);
          feature.set('seen', modifiedCarTrack.seen);

          carTrackLayer.current.getSource().addFeature(feature);
        });

        map.olMap.getView().fit(carTrackLayer.current.getSource().getExtent());
      }

      setCarTracks(carTracksData);
      setSpinnerEnabled(false);
    });
  };

  const handleProjectChange = project => {
    handleVisualizationProjectChange(null);

    if (selectedProject === project) {
      setSelectedProject(null);

      return;
    }

    setSelectedProject(project);
  };

  useEffect(() => {
    carTrackLayer.current = new Vector({
      source: new VectorSource({}),
      map: map.olMap,
      style: getLineStyle(0.6),
    });

    let hoveredFeature;

    map.olMap.on('pointermove', ev => {
      const coordinate4326 = map.coordinateFromMapProjection(ev.coordinate);
      coordinate4326.reverse();

      setMapCoordinates(coordinate4326.join(', '));

      // limit hovered features to one
      const feature = map.olMap.forEachFeatureAtPixel(map.olMap.getEventPixel(ev.originalEvent), feat => feat, {
        layerFilter: l => l === carTrackLayer.current,
      });

      const targetElement = map.olMap.getTargetElement();
      targetElement.style.cursor = feature && feature.getId() !== 'boundaryArea' ? 'pointer' : '';

      if (hoveredFeature != null && feature !== hoveredFeature) {
        hoveredFeature.setStyle(null);
        hoveredFeature = null;
      }

      if (feature == null || feature === hoveredFeature || feature.getId() === 'boundaryArea') {
        return;
      }

      feature.setStyle(getLineArrowStyle(map));
      hoveredFeature = feature;
    });

    selectInteraction.current = new Select({
      layers: [carTrackLayer.current],
      style: getLineArrowStyle(map),
    });

    selectInteraction.current.on('select', ({ selected: [selectedFeature] }) =>
      handleCarTrackSelection(selectedFeature.get('carTrack'))
    );

    map.olMap.addInteraction(selectInteraction.current);

    axios.get(`${Constants.API_BASE_URL}/geovideo/visualization-projects/`).then(({ data }) => {
      const projectsData = data.reduce((acc, { project, project_name: name, ...visualizationProject }) => {
        if (!acc.has(project)) {
          acc.set(project, { name, visualizationProjects: [] });
        }

        acc.get(project).visualizationProjects = [...acc.get(project).visualizationProjects, visualizationProject];

        return acc;
      }, new Map());

      setProjects(projectsData);
      setSpinnerEnabled(false);
    });

    return () => {
      carTrackLayer.current.setMap(null);

      map.olMap.removeInteraction(selectInteraction.current);
    };
  }, []);

  const boundaryFeature =
    carTrackLayer.current != null ? carTrackLayer.current.getSource().getFeatureById('boundaryArea') : null;

  return (
    <>
      <Modal isOpen={dashboardEnabled} onRequestClose={() => setDashboardEnabled(false)} ariaHideApp={false}>
        <SelectableButton
          style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
          }}
          onClick={() => setDashboardEnabled(false)}
        >
          <i className="fas fa-times" />
        </SelectableButton>
        <QueuedVideosDashboard />
      </Modal>
      <SidebarHeader>
        <SidebarTitle>GeoVideo</SidebarTitle>
      </SidebarHeader>
      <SidebarContent>
        {spinnerEnabled && (
          <SpinnerDiv>
            <i className="far fa-compass fa-4x fa-spin" />
          </SpinnerDiv>
        )}

        <div style={{ marginBottom: '15px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            {renderAdminButtons && renderAdminButtons()}
            <SelectableButton onClick={() => setDashboardEnabled(true)}>
              <i className="fas fa-tasks" /> Tarefas Processamento
            </SelectableButton>
          </div>
        </div>

        {projects != null &&
          Array.from(projects.entries()).map(([project, { name, visualizationProjects }]) => (
            <div key={project}>
              <ProjectButton
                className={selectedProject === project && 'active'}
                onClick={() => handleProjectChange(project)}
              >
                <div>{name}</div>
              </ProjectButton>
              {selectedProject === project &&
                visualizationProjects.map(visualizationProject => (
                  <React.Fragment key={visualizationProject.id}>
                    <VisualizationProject
                      className={`${selectedVisualizationProject === visualizationProject ? 'active' : ''}`}
                      onClick={() => handleVisualizationProjectChange(visualizationProject)}
                    >
                      <div>{visualizationProject.name}</div>
                    </VisualizationProject>
                    <VisualizationProjectContainer>
                      {selectedVisualizationProject === visualizationProject && carTracks != null && (
                        <>
                          {boundaryFeature && (
                            <BoundaryLabel>
                              <Checkbox
                                defaultChecked
                                onChange={event => {
                                  const [r, g, b] = boundaryFeature.getStyle().getStroke().getColor();

                                  const newColor = [r, g, b, event.currentTarget.checked ? 1 : 0];

                                  boundaryFeature.getStyle().getStroke().setColor(newColor);

                                  boundaryFeature.changed();
                                }}
                              />{' '}
                              Mostrar limites
                            </BoundaryLabel>
                          )}
                          {carTracks.length === 0 ? (
                            <p style={{ marginTop: '1rem' }}>Não existem tracks disponíveis para este projeto!</p>
                          ) : (
                            <>
                              <DeleteViewsButton
                                onClick={() => {
                                  if (window.confirm('Apagar todas as visualizações deste bloco?')) {
                                    axios
                                      .delete(
                                        `${Constants.API_BASE_URL}/geovideo/visualization-projects/${visualizationProject.id}/delete-track-views`
                                      )
                                      .then(() => handleVisualizationProjectChange(null));
                                  }
                                }}
                              >
                                Apagar Visualizações
                              </DeleteViewsButton>
                              {carTracks.map(carTrack => (
                                <CarTrackButton
                                  className={`${
                                    selectedCarTrack && selectedCarTrack.id === carTrack.id ? 'active' : ''
                                  } ${carTrack.seen ? 'seen' : ''}`}
                                  key={carTrack.id}
                                  onClick={() => handleCarTrackSelection(carTrack)}
                                >
                                  {carTrack.name} <i className="fas fa-video" /> {carTrack.track_count}
                                  {carTrack.seen && (
                                    <i
                                      style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                      }}
                                      className="fas fa-eye"
                                    />
                                  )}
                                </CarTrackButton>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </VisualizationProjectContainer>
                  </React.Fragment>
                ))}
            </div>
          ))}
      </SidebarContent>
      {selectedCarTrack && (
        <VideoPlayer
          map={map}
          carTrack={selectedCarTrack.data}
          boundaryFeature={boundaryFeature}
          visualizationProjectID={selectedVisualizationProject.id}
          onClose={() => handleCarTrackSelection(null)}
          onCarTrackSeen={(carTrackId, seen) => {
            setCarTracks(
              carTracks.map(ct => {
                if (ct.id === carTrackId) {
                  return {
                    ...ct,
                    seen,
                  };
                }

                return ct;
              })
            );

            carTrackLayer.current
              .getSource()
              .getFeatures()
              .find(f => f.get('carTrack') && f.get('carTrack').id === carTrackId)
              .set('seen', seen);

            handleCarTrackSelection(null);
          }}
        />
      )}
      <CoordinatesInput
        value={mapCoordinates}
        onChange={evt => setMapCoordinates(evt.target.value)}
        onKeyDown={evt => {
          if (evt.key === 'Enter') {
            const [y, x] = mapCoordinates
              .replace(/ /g, '')
              .split(',')
              .map(v => parseFloat(v));

            if (Number.isNaN(x) || Number.isNaN(y) || x < -90 || x > 90 || y < -180 || y > 180) {
              setMapCoordinates('');
              return;
            }

            map.olMap.getView().setCenter(map.coordinateToMapProjection([x, y]));
          }
        }}
      />
    </>
  );
}

export default adminToolbox(Geovideo);
