import styled from 'styled-components';

export default styled.input.attrs({
  type: 'checkbox',
})`
  appearance: none;
  position: relative;
  height: 20px;
  width: 20px;
  outline: none;
  margin-right: 15px;

  &:after {
      position:absolute;
      top:4px;
      left:0;
      width:100%;
      height:100%;
      line-height:20px;
      text-align:center;
      font-family: 'Font Awesome 5 Free';
      font-size: 16px;
      font-weight: 900;
      color:#ccc;
      content: '\f070';
    }

  &:checked:after {
    color: #008cd0;
    content: '\f06e';
  }
`;
