import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { ImageBackground, WelcomeHeader } from '../components/presentational';
import background from '../images/client-assets/background.jpg';
import MapList from '../components/MapList/MapList';
import { ADMIN_ACTIONS } from '../components/Admin/AdminComponent';
import Constants from '../utils/constants';
import { logout } from '../thunks/userThunk';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Link } from '../components/presentational/common';

const FullContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  > div {
    max-width: 780px;
    margin: 15px;
  }
`;

const TopRightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 40px;
  margin-top: 25px;
`;

const LoginLink = styled(Link.withComponent(RouterLink))`
  margin-right: 25px;
`;

const LogoutButton = styled(Link)`
  margin-left: 15px;
  cursor: pointer;
`;

const UserDiv = styled.div`
  color: white;

  & span {
    font-size: 15px;
  }
`;

const MapListContainer = props => {
  const dispatch = useDispatch();
  const {isAuthenticated, user} = useSelector((state) => state.user);
  return(
  <React.Fragment>
    <ImageBackground image={background} />
    <FullContainer>
      <TopRightContainer>
        {isAuthenticated ?
          <UserDiv>
            <span>Olá, {user}</span>
            <LogoutButton onClick={() => dispatch(logout())}>
              <i className="fas fa-sign-out-alt" /> Logout
            </LogoutButton>
          </UserDiv> :
          <React.Fragment>
            <LoginLink to="/login/">
              <i className="fas fa-lock" /> Login
            </LoginLink>
            <RouterLink to="/register/">
              <Button>
                <i className="fas fa-user" /> registe-se
              </Button>
            </RouterLink>
          </React.Fragment>
        }
      </TopRightContainer>
      <CenterContainer>
        <div>
          <WelcomeHeader
            welcomeText={`Bem-vindo aos mapas temáticos da ${process.env.REACT_APP_SIG_NAME}`}
            descriptionText="Selecione o Mapa que pretende visualizar"
          />
          <MapList
            actions={[
              {
                action: ADMIN_ACTIONS.CREATE,
                url: `${Constants.API_BASE_URL}/maps/`,
                component: Button,
                children: (
                  <React.Fragment>
                    <i className="fas fa-magic" /> Criar Mapa
                  </React.Fragment>
                ),
              },
            ]}
            onCreateCallback={({ slug }) => {
              window.location = `/mapas/${slug}`;
            }}
          />
        </div>
      </CenterContainer>
    </FullContainer>
  </React.Fragment>
)
};



export default MapListContainer;
