import React from 'react';
import PropTypes from 'prop-types';

const ExternalLayersListItem = ({ id, name, handleLayerClick }) => {
  const handleClick = () => handleLayerClick(id);

  return (
    <li>
      <button onClick={handleClick} className="btn btn-default">Add</button>
      {name}
    </li>
  );
};

ExternalLayersListItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleLayerClick: PropTypes.func.isRequired,
};

export default ExternalLayersListItem;
