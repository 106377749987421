import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import adminToolbox from '../Admin/AdminComponent';
import { ActionButtonContainer } from '../presentational/common';


const LayerItemDiv = styled.div`
  display: flex;
  padding: 5px 10px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid#ccc;
`;

const SubText = styled.p`
  margin-bottom: 0;
  font-size: 13px;
`;

const SearchableLayerListItem = ({ layer, renderAdminButtons }) => (
  <LayerItemDiv>
    <div>
      <div>{layer.alias}</div>
      <SubText>Campos pesquisáveis: {layer.attributes.filter(
        ({ searchable_field }) => searchable_field)
        .map(({ alias }) => alias)
        .join(', ')}
      </SubText>
      <SubText>Campos visíveis nos resultados: {layer.attributes.filter(
        ({ show_in_results }) => show_in_results)
        .map(({ alias }) => alias)
        .join(', ')}
      </SubText>
    </div>
    <ActionButtonContainer>
      {renderAdminButtons && renderAdminButtons()}
    </ActionButtonContainer>
  </LayerItemDiv>
);

SearchableLayerListItem.propTypes = {
  layer: PropTypes.object.isRequired,
  renderAdminButtons: PropTypes.func,
};

SearchableLayerListItem.defaultProps = {
  renderAdminButtons: null,
};

export default adminToolbox(SearchableLayerListItem);
