import React, { useEffect } from 'react';
import styled from 'styled-components';

const VideoContainer = styled.div``;

const Popup = () => {
  useEffect(() => {
    window.opener.postMessage('', '*');
  }, []);

  return <VideoContainer id="container" />;
};

export default Popup;
