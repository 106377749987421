import React from 'react';
import RcSlider from 'rc-slider/lib/Slider';
import 'rc-slider/assets/index.css';

const Slider = (props) => {
  const trackStyle = {
    height: '6px',
    backgroundColor: '#0081B4',
    borderRadius: '0px',
  };

  const handleStyle = {
    zIndex: '0',
    width: '12px',
    height: '12px',
    marginTop: '-3px',
    border: 'none',
    boxShadow: '0 0 2px #5e5e5e',
    backgroundColor: 'white',
    cursor: 'pointer',
  };

  const railStyle = {
    height: '6px',
    backgroundColor: '#e8e8e8',
    borderRadius: '0',
  };

  return (
    <RcSlider
      trackStyle={trackStyle}
      handleStyle={handleStyle}
      railStyle={railStyle}
      {...props}
    />
  );
};

export default Slider;
