import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './MeasureTools.scss';
import { SidebarHeader, SidebarTitle } from '../presentational/common';
import SelectableButton from '../presentational/common/SelectableButton';
import { ReactComponent as LineIcon } from '../../images/line.svg';
import { ReactComponent as PolygonIcon } from '../../images/polygon.svg';

import { getArea, getLength } from 'ol/sphere';
import { transform } from 'ol/proj';
import Feature from 'ol/Feature';
import Style from 'ol/style/Style';
import styled from 'styled-components';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Circle from 'ol/style/Circle';
import Draw from 'ol/interaction/Draw';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
import Overlay from 'ol/Overlay';
import OLMap from 'ol/Map';
import { unByKey } from 'ol/Observable';
import CoordSearch from '../CoordSearch/CoordSearch';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CustomHeaderContainer = styled.div`
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
`;

const StyledSelectableButton = styled(SelectableButton)`
  width: 100%;
  justify-content: center;
`;

const CustomSidebarContent = styled.div`
  display: flex;
  padding: 15px;
  padding-bottom: 0px;
  flex-direction: column;
`;

/**
 * Enum for the tools options
 * @readonly
 * @enum {string}
 */
const TOOLSTATES = {
  /** @type {object} */
  NONE: null,
  LINE: 'LineString',
  POLYGON: 'Polygon',
  POINT: 'Point',
};

const PTCRS = 'EPSG:3763';

/**
 * Component for the measure tools
 */
function MeasureTools(props) {
  const [tool, setTool] = useState(TOOLSTATES.NONE);
  const layer = useRef(null);
  const drawInteraction = useRef(null);
  const overlays = useRef([]);
  const sketch = useRef(null);
  const helpTooltipElement = useRef(null);
  const measureTooltipElement = useRef(null);
  const measureTooltip = useRef(null);
  const helpTooltip = useRef(null);
  const listener = useRef(null);

  const currentCRS = useSelector((state) => state.map.currentProjection);

  /**
   * Format the line length to a string
   *
   * @param {ol.geom.LineString} line Line to measure
   * @param {ol.proj.Projection} projection View projection
   * @returns {string} Formatted length of the line
   */
  const formatLength = (line, projection) => {
    const length = getLength(line, { projection });
    if (length > 1000) {
      return `${Math.round((length / 1000) * 100) / 100} km`;
    }
    return `${Math.round(length * 100) / 100} m`;
  };

  /**
   * Format the polygon area to a string
   *
   * @param {ol.geom.Polygon} polygon Polygon to measure
   * @param {ol.proj.Projection} projection View projection
   * @returns {string} Formatted area of the polygon
   */
  const formatArea = (polygon, projection) => {
    const area = getArea(polygon, { projection });
    let output;
    if (area > 1000000) {
      output = `${Math.round((area / 1000000) * 100) / 100} km<sup>2</sup>`;
    } else {
      output = `${Math.round(area * 100) / 100} m<sup>2</sup>`;
    }

    // if is a closed polygon put the perimeter
    if (polygon.getCoordinates()[0].length >= 4) {
      output += `<br>${formatLength(polygon, projection)}`;
    }

    return output;
  };

  /**
   *
   * @param {list} coordinate_list
   * @returns {string} Formatted coordinates of the point in EPSG:3763
   */
  const formatPoint3763 = (coordinate_list) => {
    return `${MeasureTools.messages.x}: ${coordinate_list[0].toFixed(
      2,
    )}<br>${MeasureTools.messages.y}: ${coordinate_list[1].toFixed(2)}`;
  };

  /**
   * Format the point to a coordinates
   *
   * @param {ol.geom.Point} point Point to format into coordinates
   * @param {ol.proj.Projection} viewProjection View projection
   * @returns {string} Formatted coordinates of the point in EPSG:4326
   */
  const formatPoint = (point, viewProjection) => {
    const projection_name = currentCRS.name;
    const coords = transform(point.getCoordinates(), viewProjection, currentCRS.name);
    // If PT CRS format as x, y instead of lat, lon
    if (projection_name === PTCRS) {
      return formatPoint3763(coords);
    }
    return `${MeasureTools.messages.latitude}: ${coords[1].toFixed(
      6,
    )}<br>${MeasureTools.messages.longitude}: ${coords[0].toFixed(6)}`;
  };

  useEffect(() => {
    createMeasureLayer();
    return () => {
      removeLayerContent();
      removeOverlays();
      removeDrawInteraction();
      layer.current.setMap(null);
    };
  }, []);

  useEffect(() => {
    removeDrawInteraction();
    if (tool !== TOOLSTATES.NONE) {
      createDrawInteraction(tool);
      addDrawInteraction();
      props.map.on('pointermove', pointerMoveHandler);
      props.map.getViewport().addEventListener('mouseout', () => {
        helpTooltipElement.current.classList.add('hidden');
      });
    } else {
      props.map.un('pointermove', pointerMoveHandler);
    }
  }, [tool, currentCRS]);

  /**
   * Creates the vector layer that host the features to measure
   */
  const createMeasureLayer = () => {
    const drawLayer = props.map
      .getLayers()
      .getArray()
      .filter((layer) => {
        if (layer.get('name') === 'measure') {
          return layer;
        }
      });
    layer.current = drawLayer[0];
  };

  /**
   * Set tooltip class, offset and prepare variables for next tooltip element
   * Called on drawEnd and when adding a coordinate marker
   */
  const postAddToolTip = () => {
    measureTooltipElement.current.className = 'tooltip tooltip-static';
    measureTooltip.current.setOffset([0, -7]);
    sketch.current = null;
    measureTooltipElement.current = null;
    createMeasureTooltip();
  };

  /**
   * Creates the draw interaction and set's the listeners for drawstart and drawend events
   *
   * @param {tool} type Type of the draw interaction
   */
  const createDrawInteraction = (type) => {
    drawInteraction.current = new Draw({
      source: layer.current.getSource(),
      type,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.5)',
          lineDash: [10, 10],
          width: 2,
        }),
        image: new Circle({
          radius: 5,
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)',
          }),
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
        }),
      }),
    });
    drawInteraction.current.on('drawstart', (evt) => {
      sketch.current = evt.feature;
      // cause when is a point, onChange isn't called
      if (evt.feature.getGeometry() instanceof Point) {
        measureTooltipElement.current.innerHTML = formatPoint(
          evt.feature.getGeometry(),
          props.map.getView().getProjection(),
        );
        measureTooltip.current.setPosition(evt.feature.getGeometry().getCoordinates());
      }

      listener.current = evt.feature.getGeometry().on('change', (evtGeo) => {
        let tooltipCoord = evt.coordinate;
        let output;

        if (evtGeo.target instanceof LineString) {
          output = formatLength(evtGeo.target, props.map.getView().getProjection());
          tooltipCoord = evtGeo.target.getLastCoordinate();
        } else if (evtGeo.target instanceof Polygon) {
          output = formatArea(evtGeo.target, props.map.getView().getProjection());
          tooltipCoord = evtGeo.target.getInteriorPoint().getCoordinates();
        }

        measureTooltipElement.current.innerHTML = output;
        measureTooltip.current.setPosition(tooltipCoord);
      });
    });
    drawInteraction.current.on('drawend', () => {
      postAddToolTip();
      unByKey(listener.current);
    });
  };

  /**
   * Add the draw interaction to the map
   */
  const addDrawInteraction = () => {
    props.map.addInteraction(drawInteraction.current);
    createMeasureTooltip();
    createHelpTooltip();
  };

  /**
   * Remove the draw interaction to the map
   */
  const removeDrawInteraction = () => {
    props.map.removeInteraction(drawInteraction.current);
  };

  /**
   * Creates a tooltip to present the measure information for a specific feature
   */
  const createMeasureTooltip = () => {
    if (measureTooltipElement.current) {
      measureTooltipElement.current.parentNode.removeChild(measureTooltipElement.current);
    }
    measureTooltipElement.current = document.createElement('div');
    measureTooltipElement.current.className = 'tooltip tooltip-measure';
    measureTooltip.current = new Overlay({
      element: measureTooltipElement.current,
      offset: [0, -15],
      positioning: 'bottom-center',
      stopEvent: false,
    });
    props.map.addOverlay(measureTooltip.current);
    overlays.current.push(measureTooltip.current);
  };

  /**
   * Creates a tooltip for help messages
   */
  const createHelpTooltip = () => {
    if (helpTooltipElement.current) {
      helpTooltipElement.current.parentNode.removeChild(helpTooltipElement.current);
    }
    helpTooltipElement.current = document.createElement('div');
    helpTooltipElement.current.className = 'tooltip hidden';
    helpTooltip.current = new Overlay({
      element: helpTooltipElement.current,
      offset: [15, 0],
      positioning: 'center-left',
      stopEvent: false,
    });
    props.map.addOverlay(helpTooltip.current);
    overlays.current.push(helpTooltip.current);
  };

  /**
   * Removes all the measures on the map
   */
  const removeLayerContent = () => {
    layer.current.getSource().clear();
  };

  /**
   * Removes all overlays (help tooltips and measure tooltips)
   */
  const removeOverlays = () => {
    overlays.current.forEach((overlay) => {
      props.map.removeOverlay(overlay);
    });
    overlays.current = [];
  };

  /**
   * Pointer move event handler that its added to the map so we can give some tips to the user
   *
   * @param evt
   */
  const pointerMoveHandler = (evt) => {
    if (evt.dragging) {
      return;
    }

    /** @type {string} */
    let helpMsg = MeasureTools.messages.help_start_drawing;

    if (sketch.current) {
      const geom = sketch.current.getGeometry();
      if (geom instanceof Polygon) {
        helpMsg = MeasureTools.messages.help_continue_polygon;
      } else if (geom instanceof LineString) {
        helpMsg = MeasureTools.messages.help_continue_line;
      }
    }

    // cause point mode doesn't has a sketch
    if (tool === TOOLSTATES.POINT) {
      helpMsg = MeasureTools.messages.help_point;
    }

    helpTooltipElement.current.innerHTML = helpMsg;
    helpTooltip.current.setPosition(evt.coordinate);

    helpTooltipElement.current.classList.remove('hidden');
  };

  /**
   * Turn on or turn off the Line Measure Tool
   */
  const handleMeasureLineClick = () => {
    if (tool === TOOLSTATES.LINE) {
      setTool(TOOLSTATES.NONE);
    } else {
      setTool(TOOLSTATES.LINE);
    }
  };

  /**
   * Turn on or turn off the Polygon Measure Tool
   */
  const handleMeasurePolygonClick = () => {
    if (tool === TOOLSTATES.POLYGON) {
      setTool(TOOLSTATES.NONE);
    } else {
      setTool(TOOLSTATES.POLYGON);
    }
  };

  /**
   * Turn on or turn off the Point Measure Tool
   */
  const handleMeasurePointClick = () => {
    if (tool === TOOLSTATES.POINT) {
      setTool(TOOLSTATES.NONE);
    } else {
      setTool(TOOLSTATES.POINT);
    }
  };

  /**
   * Remove all overlays added by this component
   */
  const handleClearClick = () => {
    removeLayerContent();
    removeOverlays();
    setTool(TOOLSTATES.NONE);
  };

  /**
   * Add Marker and its tooltip to map
   * @param {list} coordinates coordinates in list form (e.g: [43, -8])
   */
  const addCoordinateMarkerTooltip = (coordinates) => {
    // Create Point geomtry with chosen coordinates
    const coordinate_point = new Point(coordinates);
    // Process tooltip html
    const marker_tooltip = formatPoint(coordinate_point, props.map.getView().getProjection());
    // Create measure tool tip, same logic as with drawing
    createMeasureTooltip();
    measureTooltipElement.current.innerHTML = marker_tooltip;
    measureTooltip.current.setPosition(coordinates);
    // Create Feature from Point
    const point_vector = new Feature({
      geometry: coordinate_point,
    });
    // Add marker feature to the measure vector layer
    layer.current.getSource().addFeatures([point_vector]);
    postAddToolTip();
  };

  return (
    <>
      <SidebarHeader>
        <SidebarTitle>{props.sideBarTitle}</SidebarTitle>
        <CustomHeaderContainer>
          <SelectableButton onClick={() => props.openTool('PrintRequestList')}>
            <i style={{ marginRight: '5px' }} className="fas fa-history" /> Histórico de Prints
          </SelectableButton>
          <SelectableButton title="Limpar Desenho" onClick={handleClearClick}>
            <i className="fas fa-redo" />
          </SelectableButton>
        </CustomHeaderContainer>
      </SidebarHeader>
      <CustomSidebarContent>
        <ButtonContainer>
          <StyledSelectableButton onClick={handleMeasurePointClick} selected={tool === TOOLSTATES.POINT}>
            <i style={{ marginRight: '5px' }} className="fas fa-map-marker-alt" />
            Desenhar Ponto
          </StyledSelectableButton>
          <StyledSelectableButton onClick={handleMeasureLineClick} selected={tool === TOOLSTATES.LINE}>
            <LineIcon style={{ marginRight: '5px' }} width="12px" height="20px" />
            Desenhar Linha
          </StyledSelectableButton>
          <StyledSelectableButton onClick={handleMeasurePolygonClick} selected={tool === TOOLSTATES.POLYGON}>
            <PolygonIcon style={{ marginRight: '5px' }} width="12px" height="20px" />
            Desenhar Polígono
          </StyledSelectableButton>
        </ButtonContainer>
        <CoordSearch map={props.map} onAddMarker={addCoordinateMarkerTooltip} />
      </CustomSidebarContent>
    </>
  );
}

MeasureTools.propTypes = {
  map: PropTypes.instanceOf(OLMap).isRequired,
  lineColor: PropTypes.string,
  sideBarTitle: PropTypes.string,
  openTool: PropTypes.func.isRequired,
};

MeasureTools.defaultProps = {
  lineColor: '#f44e42',
  sideBarTitle: 'Medir',
};

MeasureTools.messages = {
  measure: 'Medir',
  help_start_drawing: 'Click para começar a desenhar',
  help_continue_line: 'Click para continuar a desenhar a linha',
  help_continue_polygon: 'Click para continuar a desenhar o polígono',
  help_point: 'Click para selecionar o ponto',
  latitude: 'Latitude',
  longitude: 'Longitude',
  clean_measures: 'Limpar medidas',
  x: 'X',
  y: 'Y',
};

export default MeasureTools;
