import { useState } from 'react';
import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { TransparentInput } from '../presentational';
import Constants from '../../utils/constants';
import { OverlaySpinner } from '../presentational/common';

const MESSAGES = {
  submit: 'Registar',
  firstName: 'Primeiro Nome',
  lastName: 'Último Nome',
  username: 'Username',
  email: 'Email',
  nif: 'NIF',
  password: 'Password',
  confirmPassword: 'Confirmar Password',
};

const NameWrapper = styled.div`
  display: flex;
`;

const NameContainer = styled.div`
  flex: 1;
`;

const SubmitButton = styled.button.attrs({
  type: 'submit',
})`
  width: 100%;
  color: white;
  background-color: #008cd0;
  border: none;
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
`;

const FormSuccess = styled.div`
  background-color: #2ea500;
  color: white;
  border-radius: 2px;
  padding: 4px 8px;
  margin-bottom: 4px;
`;

const FormError = styled.div`
  color: red;
  padding: 4px 8px;
  margin-bottom: 16px;
`;

const FormAlertDanger = styled.div`
  background-color: #a50000;
  color: white;
  border-radius: 2px;
  padding: 4px 8px;
  margin-bottom: 4px;
`;

export default function RegisterForm() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    nif: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState('initial');

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const onFormSubmit = async event => {
    event.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setErrors({
        password: ['As passwords devem ser iguais.'],
        confirmPassword: ['As passwords devem ser iguais.'],
      });
      return;
    }

    setMode('pending');

    try {
      await axios.post(`${Constants.API_BASE_URL}/auth/users/`, {
        username: formData.username,
        email: formData.email,
        nif: formData.nif,
        password: formData.password,
        first_name: formData.firstName,
        last_name: formData.lastName,
      });

      setFormData({
        username: '',
        email: '',
        nif: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
      });
      setErrors({});
      setMode('success');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrors(error.response.data);
        setMode('initial');
      } else {
        setErrors({ global: ['Erro de servidor, tente mais tarde.'] });
      }
    }
  };

  const {
    firstName: firstNameError = [],
    lastName: lastNameError = [],
    username: usernameError = [],
    email: emailError = [],
    nif: nifError = [],
    password: passwordError = [],
    confirmPassword: confirmPasswordError = [],
    global: globalError = [],
  } = errors;

  return (
    <React.Fragment>
      {mode !== 'success' ? (
        <form onSubmit={onFormSubmit}>
          <NameWrapper>
            <NameContainer style={{ marginRight: '8px' }}>
              <TransparentInput
                id="inputFirstName"
                type="text"
                value={formData.firstName}
                required
                name="firstName"
                placeholder={MESSAGES.firstName}
                onChange={handleInputChange}
              />
              <FormError>
                {firstNameError.map(error => (
                  <p key={error}>{error}</p>
                ))}
              </FormError>
            </NameContainer>
            <NameContainer style={{ marginLeft: '8px' }}>
              <TransparentInput
                id="inputLastName"
                type="text"
                value={formData.lastName}
                required
                name="lastName"
                placeholder={MESSAGES.lastName}
                onChange={handleInputChange}
              />
              <FormError>
                {lastNameError.map(error => (
                  <p key={error}>{error}</p>
                ))}
              </FormError>
            </NameContainer>
          </NameWrapper>
          <TransparentInput
            id="inputUsername"
            type="text"
            value={formData.username}
            required
            name="username"
            placeholder={MESSAGES.username}
            onChange={handleInputChange}
          />
          <FormError>
            {usernameError.map(error => (
              <p key={error}>{error}</p>
            ))}
          </FormError>
          <TransparentInput
            id="inputEmail"
            type="email"
            value={formData.email}
            required
            name="email"
            placeholder={MESSAGES.email}
            onChange={handleInputChange}
          />
          <FormError>
            {emailError.map(error => (
              <p key={error}>{error}</p>
            ))}
          </FormError>
          <TransparentInput
            id="inputNif"
            type="text"
            value={formData.nif}
            required
            name="nif"
            placeholder={MESSAGES.nif}
            maxLength="9"
            onChange={handleInputChange}
          />
          <FormError>
            {nifError.map(error => (
              <p key={error}>{error}</p>
            ))}
          </FormError>
          <TransparentInput
            id="inputPassword"
            type="password"
            value={formData.password}
            required
            name="password"
            placeholder={MESSAGES.password}
            onChange={handleInputChange}
          />
          <FormError>
            {passwordError.map(error => (
              <p key={error}>{error}</p>
            ))}
          </FormError>
          <TransparentInput
            id="inputConfirmPassword"
            type="password"
            value={formData.confirmPassword}
            required
            name="confirmPassword"
            placeholder={MESSAGES.confirmPassword}
            onChange={handleInputChange}
          />
          <FormError>
            {confirmPasswordError.map(error => (
              <p key={error}>{error}</p>
            ))}
          </FormError>
          {globalError.length > 0 && (
            <FormAlertDanger>
              {globalError.map(error => (
                <p key={error}>{error}</p>
              ))}
            </FormAlertDanger>
          )}
          <SubmitButton>{MESSAGES.submit}</SubmitButton>
        </form>
      ) : (
        <FormSuccess>Conta criada com sucesso. Irá receber um email para ativar a conta.</FormSuccess>
      )}
      {mode === 'pending' && <OverlaySpinner />}
    </React.Fragment>
  );
}
