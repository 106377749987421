import PropTypes from 'prop-types';
import { KML } from 'ol/format';
import JSZip from 'jszip';
import styled from 'styled-components';
import { Button } from '../presentational/common';
import './UploadFileToMap.scss';
import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { containsExtent } from 'ol/extent';
import ActionButtonContainer from '../presentational/common/ActionButtonContainer';

const FileInput = styled.input``;

const UploadButton = styled(Button)`
  width: 100%;
  border-radius: 1px 4px 4px 1px;
  border: 1px solid #0080b6;
`;

const ButtonContainer = styled(ActionButtonContainer)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const UploadFileLabel = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: #666;
  margin-bottom: 10px;
`;

const UploadFileSeparator = styled.div`
  border-top: 1px solid #ccc;
  margin-bottom: 7%;
`;

const UploadFileFinalSeperator = styled.div`
  border-top: 1px solid #ccc;
  margin-top: 5%
  margin-bottom: 5%
`;

const UploadFileToMap = ({ map }) => {
  const { extent } = useSelector((state) => state.map.map);
  const { bbox } = useSelector((state) => state.map.mapConfig);

  const fileInputRef = useRef(null);
  const handleClick = useCallback(() => fileInputRef.current?.click(), []);

  const onFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.name.endsWith('.kmz')) {
        await handleKMZFile(file);
      } else if (file.name.endsWith('.kml')) {
        await handleKMLFile(file);
      } else {
        alert('Tipo de ficheiro inválido');
      }
    }
  };

  const handleKMLFile = (file) => {
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const kmlFormat = new KML();
        const features = kmlFormat.readFeatures(e.target.result, {
          featureProjection: map.getView().getProjection(),
        });

        addFeaturesToLayer(features);
      };
      reader.readAsText(file);
    } catch (error) {
      alert('Erro ao abrir ficheiro KML');
    }
  };

  const handleKMZFile = async (file) => {
    try {
      const zip = new JSZip();
      const zipContent = await zip.loadAsync(file);
      let kmlContent = null;
      let featuresFromAllFiles = [];
      for (const relativePath in zipContent.files) {
        const zipEntry = zipContent.files[relativePath];
        if (relativePath.endsWith('.kml')) {
          kmlContent = await zipEntry.async('text');
          const kmlFormat = new KML();
          const features = kmlFormat.readFeatures(kmlContent, {
            featureProjection: map.getView().getProjection(),
          });
          featuresFromAllFiles = featuresFromAllFiles.concat(features);
        }
      }
      addFeaturesToLayer(featuresFromAllFiles);

      if (!kmlContent) {
        alert('Não foi encontrado nenhum ficheiro KML');
      }
    } catch (error) {
      alert('Erro ao abrir KMZ');
    }
  };

  const addFeaturesToLayer = (features) => {
    const featuresLength = features.length;
    // Filter features if bbox exist
    if (bbox) {
      features = features.filter((feature) => {
        return containsExtent(extent, feature.getGeometry().getExtent());
      });
    }

    const drawLayer = map
      .getLayers()
      .getArray()
      .find((layer) => layer.get('name') === 'measure');

    if (drawLayer && features.length > 0) {
      drawLayer.getSource().addFeatures(features);
      const extent = drawLayer.getSource().getExtent();
      map.getView().fit(extent);
    } else {
      alert('Todas as geometrias estão fora da bounding box');
    }
    if (features.length > 0 && features.length !== featuresLength) {
      alert('Algumas geometrias fora da bounding box não foram importadas');
    }
  };

  return (
    <>
      <UploadFileSeparator />
      <UploadFileLabel>{UploadFileToMap.messages.title}</UploadFileLabel>
      <ButtonContainer>
        <label htmlFor="file-upload" className="file-upload-label">
          <span className="file-upload-text">
            <i className="fa-solid fa-cloud-arrow-up cloud-icon"></i>
            {UploadFileToMap.messages.placeholderInputFile}
          </span>
          <FileInput
            accept=".kml,.kmz"
            id="file-upload"
            onChange={onFileChange}
            type="file"
            hidden={true}
            ref={fileInputRef}
          />
          <UploadButton onClick={handleClick}>Upload</UploadButton>
        </label>
      </ButtonContainer>
      <UploadFileFinalSeperator />
    </>
  );
};

UploadFileToMap.propTypes = {
  map: PropTypes.instanceOf(Object).isRequired,
  onLayerAdded: PropTypes.func,
};

UploadFileToMap.messages = {
  title: 'Carregamento de Ficheiros',
  placeholderInputFile: 'Arraste kml/kmz para o mapa',
};

export default UploadFileToMap;
