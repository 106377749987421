import axios from 'axios';
import Constants from '../utils/constants';
import MapModel from '../models/Map';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchProjectionInfo = (srid) =>
  axios.get(`${Constants.API_BASE_URL}/projection-details?srid=${srid}`).then(({ data: projectionDetails }) => ({
    srid,
    name: `EPSG:${projectionDetails.code}`,
    proj4: projectionDetails.proj4,
    bbox: projectionDetails.bbox,
    fullName: projectionDetails.name,
  }));


const fetchCoordinatesProjectionList = () =>
  axios.get(`${Constants.API_BASE_URL}/coord-projection-list`).then((response) => response.data
  ).catch((error) => {
    throw error;
  });

/*
 * action creators
 */

export const fetchMap = createAsyncThunk('map/fetchMap', async ({ mapSlug }) => {
  // eslint-disable-next-line no-useless-catch
  try {
    // Fetch map
    const response = await axios.get(`${Constants.API_BASE_URL}/maps/${mapSlug}/`);
    const { data: mapConfig } = response;
    const mapProjection = mapConfig.projection;

    // Fetch projection list for coordinate display
    const coordProjectionList = await fetchCoordinatesProjectionList();

    // Fetch projection info for map's projection
    const projectionInfo = await fetchProjectionInfo(mapProjection);

    let totalProjectionList = [projectionInfo];
    // Filter out map's projection
    const sridList = coordProjectionList.map((proj) => proj.srid).filter((srid) => srid !== mapProjection);
    // Fetch projection info for remaining projections
    for (let i=0; i<sridList.length; i++) {
      let srid = sridList[i];
      let currentInfo = await fetchProjectionInfo(srid);
      totalProjectionList.push(currentInfo);
    }

    const {
      id,
      name,
      slug,
      min_zoom: minZoom,
      max_zoom: maxZoom,
      projection,
      url: detailURL,
      initial_extent: initialExtent,
      bbox,
      ...remainingConfigs
    } = { ...mapConfig, projection: projectionInfo };
    const map = new MapModel(id, name, slug, minZoom, maxZoom, projection, detailURL, bbox, initialExtent, remainingConfigs);
    mapConfig.bbox = map.extent
    return { map, mapProjection: projectionInfo, projectionList: totalProjectionList, mapConfig: mapConfig };
  } catch (error) {
    throw error;
  }
});

export const fetchMapState = createAsyncThunk('map/fetchMapState', async({ mapStateSlug }) => {
  const mapStateResponse = await axios.get(`${Constants.API_BASE_URL}/map-state/${mapStateSlug}`);
  return {
    mapState: mapStateResponse.data
  };
});
