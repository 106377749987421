import styled from 'styled-components';
import { EXTRA_SMALL_MAX, ULTRA_SMALL_MAX } from '../presentational/mediaBreakpoints';

const CoordinatesProjectionBar = styled.div`
  display: flex;
  position: absolute;
  background: white;
  bottom:0;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 32%;
  margin-bottom: 1px;
  z-index: 10;
  height: min-content;
  max-width: fit-content;

  @media(max-width: ${ULTRA_SMALL_MAX}) {
    top: 1.5vh;
    left: 17%;
    transform: none;
    bottom: unset;
  }

  @media (min-width: ${ULTRA_SMALL_MAX}) and (max-width: ${EXTRA_SMALL_MAX}) {
    top: 1.5vh;
    left: 25%;
    transform: none;
    bottom: unset;
  }
`;

export default CoordinatesProjectionBar;