import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ExternalLayersListItem from './ExternalLayersListItem';
import Layer from '../../models/Layer';

export default function ExternalLayersList({ layers, handleLayerClick }) {
  const [layersState, setLayersState] = useState(layers);

  const handleLayerItemClick = useCallback((layerId) => {
    const layer = layersState.find(layerItem => layerItem.id === layerId);
    handleLayerClick(layer);
    setLayersState(layersState.filter(layerItem => layerItem.id !== layerId));
  }, [layersState, handleLayerClick]);

  const items = layersState.map(layer => (
    <ExternalLayersListItem
      key={layer.id}
      id={layer.id}
      name={layer.name}
      handleLayerClick={handleLayerItemClick}
    />
  ));

  return <ul>{items}</ul>;
}

ExternalLayersList.propTypes = {
  layers: PropTypes.arrayOf(PropTypes.instanceOf(Layer)).isRequired,
  handleLayerClick: PropTypes.func.isRequired,
};
