import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import defaultLogo from '../../images/client-assets/logo-small.png';

const ResponsiveImage = styled.img.attrs({
  className: 'img-fluid',
})`
  margin-bottom: 15px;
`;

const WelcomeText = styled.h4`
  margin-bottom: 15px;
`;

const DescriptionText = styled.p`
  font-size: 14px;
  margin-bottom: 0;
`;

const WelcomeHeader = ({
  logo, welcomeText, descriptionText, className,
}) =>
  (
    <div className={className}>
      <ResponsiveImage src={logo} alt="" />
      <WelcomeText>{welcomeText}</WelcomeText>
      <DescriptionText>{descriptionText}</DescriptionText>
    </div>
  );

WelcomeHeader.propTypes = {
  logo: PropTypes.string,
  welcomeText: PropTypes.string.isRequired,
  descriptionText: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

WelcomeHeader.defaultProps = {
  logo: defaultLogo,
};


export default styled(WelcomeHeader)`
  width: 100%;
  color: white;
  padding: 10px 0;
  text-align: ${props => props.textAlign || 'left'};
  font-weight: bold;
  ${props => props.marginBottom && css`
    margin-bottom: ${props.marginBottom};
  `}
`;
