import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TextInput from './TextInput';

const Container = styled.div`
  position: relative;
  flex: 1;
`;

const CleanButton = styled.button.attrs({
  type: 'button',
})`
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  background: transparent;
  border: 0;
  color: #ccc;
  opacity: 0.5;
  cursor: pointer;
  outline: none;
  &:hover {
    opacity: 1;
  }
`;

const SearchTextInput = styled(TextInput)`
  border-radius: 0 4px 4px 0;
`;

function SearchInput(props){


  const handleInputChange = (event) => {
    const searchText = event.target.value;

    props.onChange(searchText);
  }


  return (
    <Container>
      <SearchTextInput
        value={props.value}
        onChange={handleInputChange}
        placeholder={props.placeholder}
      />
      <CleanButton onClick={() => props.onChange('')}>
        <i className="fas fa-times" />
      </CleanButton>
    </Container>
  );

}

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

SearchInput.defaultProps = {
  placeholder: '',
  value: '',
};

export default SearchInput;

