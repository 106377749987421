import React, { useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Sidebar, ToolBar } from '../components/presentational';
import FeatureEditTools from '../components/FeatureEditTools/FeatureEditTools';
import FeatureSearchForm from '../components/FeatureSearch/FeatureSearchForm';
import MeasureTools from '../components/Measure/MeasureTools';
import { selectTool, toggleSidebar } from '../reducers/sidebarReducer';
import { ADMIN_ACTIONS } from '../components/Admin/AdminComponent';
import Constants from '../utils/constants';
import BaseLayerSwitcherGroup from '../components/LayerSwitcher/BaseLayers/BaseLayerSwitcherGroup';
import OverlayLayerSwitcherGroup from '../components/LayerSwitcher/OverlayLayers/OverlayLayerSwitcherGroup';
import PrintMap from '../components/PrintMap/PrintMap';
import PrintRequestList from '../components/PrintMap/PrintRequestList';
import { LAYER_TYPES } from '../factories/LayerFactory';
import Settings from '../components/Settings/Settings';
import { SelectableButton } from '../components/presentational/common';
import { EXTRA_SMALL_MAX } from '../components/presentational/mediaBreakpoints';
import Geovideo from '../components/Geovideo/Geovideo';
import GetFeatureInfo from '../components/GetFeatureInfo/GetFeatureInfo';

const FullHeightContainer = styled.div`
  position: fixed;
  right: 0;
  display: flex;
  height: 100%;
  align-items: center;
  right: ${(props) => (props.opened ? '0' : '-400px')};
  transition: all 0.5s ease;

  @media (max-width: ${EXTRA_SMALL_MAX}) {
    flex-wrap: wrap;
    align-items: flex-end;
    height: 50px;
    width: 100%;
    right: auto;
    bottom: ${(props) => (props.opened ? 'calc(50vh - 50px)' : '0')};
  }
`;

function SideMenuContainer() {
  const dispatch = useDispatch();
  const { opened, selectedTool } = useSelector((state) => state.sidebar);
  const { map, mapConfig } = useSelector((state) => state.map);

  const user = useSelector((state) => state.user);

  const usedTool = useRef(null);
  const renderCurrentTool = () => {
    usedTool.current = selectedTool;

    const toolsToRender = [];

    if (usedTool.current === 'FeatureEditTools') {
      toolsToRender.push(
        <div key="FeatureEditTools" style={selectedTool === 'FeatureEditTools' ? {} : { display: 'none' }}>
          <FeatureEditTools
            addInteractionCallback={(interaction) => map.olMap.addInteraction(interaction)}
            removeInteractionCallback={(interaction) => map.olMap.removeInteraction(interaction)}
            addTemporaryLayerToMap={map.addTemporaryLayer}
            editableLayers={map.layers.filter((layer) => layer.editionAttributes && layer.editionAttributes.length)}
            projection={map.olMap.getView().getProjection().getCode()}
          />
        </div>,
      );
    }

    if (usedTool.current === 'FeatureSearchForm') {
      toolsToRender.push(
        <div key="FeatureSearchForm" style={selectedTool === 'FeatureSearchForm' ? {} : { display: 'none' }}>
          <FeatureSearchForm
            searchableLayers={map.searchableLayers}
            map={map.olMap}
            actions={[
              {
                action: ADMIN_ACTIONS.CREATE,
                url: `${Constants.API_BASE_URL}/search/config/`,
                component: SelectableButton,
                children: <i className="fas fa-magic" />,
                title: 'Adicionar Layers Pesquisáveis',
              },
            ]}
          />
        </div>,
      );
    }

    if (usedTool.current === 'MeasureTools') {
      toolsToRender.push(
        <div key="MeasureTools" style={selectedTool === 'MeasureTools' ? {} : { display: 'none' }}>
          <MeasureTools map={map.olMap} />
        </div>,
      );
    }

    if (usedTool.current === 'BaseLayerSwitcher') {
      const baseLayers = map.layers.filter((layer) => layer.type === LAYER_TYPES.BASE);

      toolsToRender.push(
        <div key="BaseLayerSwitcher" style={selectedTool === 'BaseLayerSwitcher' ? {} : { display: 'none' }}>
          <BaseLayerSwitcherGroup
            layers={baseLayers}
            actions={[
              {
                action: ADMIN_ACTIONS.CREATE,
                url: `${Constants.API_BASE_URL}/layers/base-layers/wms-base-layer/`,
                component: SelectableButton,
                children: (
                  <React.Fragment>
                    <i className="fas fa-magic" /> Adicionar WMS
                  </React.Fragment>
                ),
              },
            ]}
          />
        </div>,
      );
    }

    if (usedTool.current === 'OverlayLayerSwitcher') {
      const overlayLayers = map.layers.filter((layer) => layer.type === LAYER_TYPES.OVERLAY);

      toolsToRender.push(
        <div key="OverlayLayerSwitcher" style={selectedTool === 'OverlayLayerSwitcher' ? {} : { display: 'none' }}>
          <OverlayLayerSwitcherGroup
            layers={overlayLayers}
            map={map}
            addLayerToMap={map.addLayerToMap}
            viewProjection={map.olMap.getView().getProjection()}
            actions={[
              {
                action: ADMIN_ACTIONS.CREATE,
                url: `${Constants.API_BASE_URL}/layers/overlay-layers/wms-overlay-layer/`,
                component: SelectableButton,
                children: (
                  <React.Fragment>
                    <i className="fas fa-magic" /> WMS
                  </React.Fragment>
                ),
                title: "Adicionar WMS",
              },
              {
                action: ADMIN_ACTIONS.CREATE,
                url: `${Constants.API_BASE_URL}/layers/overlay-layers/wfs-overlay-layer/`,
                component: SelectableButton,
                children: (
                  <React.Fragment>
                    <i className="fas fa-magic" /> WFS
                  </React.Fragment>
                ),
                title: "Adicionar WFS",
              },
            ]}
          />
        </div>,
      );
    }

    if (usedTool.current === 'Settings') {
      toolsToRender.push(
        <div key="Settings" style={selectedTool === 'Settings' ? {} : { display: 'none' }}>
          <Settings
            actions={[
              {
                action: ADMIN_ACTIONS.EDIT,
                url: `${Constants.API_BASE_URL}/maps/${map.slug}/`,
                component: SelectableButton,
                children: (
                  <React.Fragment>
                    <i className="fas fa-pencil-alt" /> Editar Mapa
                  </React.Fragment>
                ),
              },
              {
                action: ADMIN_ACTIONS.EDIT,
                url: `${Constants.API_BASE_URL}/maps/${map.slug}/layer-ordering/`,
                component: SelectableButton,
                children: (
                  <React.Fragment>
                    <i className="fas fa-sort" /> Alterar ordem das layers
                  </React.Fragment>
                ),
              },
              {
                action: ADMIN_ACTIONS.DELETE,
                url: `${Constants.API_BASE_URL}/maps/${map.slug}/`,
                component: SelectableButton,
                children: (
                  <React.Fragment>
                    <i className="fas fa-trash-alt" /> Remover Mapa
                  </React.Fragment>
                ),
              },
            ]}
            onChangeCallback={(data) => {
              if ('redirect_on_update' in data) {
                window.location = `/mapas/${data.slug}`;
              } else {
                window.location.reload(true);
              }
            }}
            onDeleteCallback={() => {
              window.location = '/';
            }}
          />
        </div>,
      );
    }

    if (usedTool.current === 'PrintMap') {
      toolsToRender.push(
        <div key="PrintMap" style={selectedTool === 'PrintMap' ? {} : { display: 'none' }}>
          <PrintMap
            openTool={(tool) => dispatch(selectTool(tool))}
            mapConfig={mapConfig}
            map={map.olMap}
            layers={map.layers}
          />
        </div>,
      );
    }

    if (usedTool.current === 'PrintRequestList') {
      toolsToRender.push(
        <div key="PrintRequestList" style={selectedTool === 'PrintRequestList' ? {} : { display: 'none' }}>
          <PrintRequestList
            openTool={(tool) => dispatch(selectTool(tool))}
            mapConfig={mapConfig}
            map={map.olMap}
            layers={map.layers}
          />
        </div>,
      );
    }

    if (usedTool.current === 'Geovideo') {
      toolsToRender.push(
        <div key="Geovideo" style={selectedTool === 'Geovideo' ? {} : { display: 'none' }}>
          <Geovideo
            map={map}
            actions={[
              {
                action: ADMIN_ACTIONS.CREATE,
                url: `${Constants.API_BASE_URL}/geovideo/projects/`,
                component: SelectableButton,
                children: (
                  <React.Fragment>
                    <i className="fas fa-magic" /> Criar Projeto
                  </React.Fragment>
                ),
              },
              {
                action: ADMIN_ACTIONS.CREATE,
                url: `${Constants.API_BASE_URL}/geovideo/visualization-projects/`,
                component: SelectableButton,
                children: (
                  <React.Fragment>
                    <i className="fas fa-magic" /> Criar Projeto de Visualização
                  </React.Fragment>
                ),
              },
            ]}
          />
        </div>,
      );
    }

    return toolsToRender;
  };

  return (
    <FullHeightContainer opened={opened}>
      <ToolBar openTool={(tool) => dispatch(selectTool(tool))} user={user} map={map} opened={opened} />
      {map && <Sidebar collapseClick={() => dispatch(toggleSidebar())} opened={opened}>{renderCurrentTool()}</Sidebar>}
    </FullHeightContainer>
  );
}

export default SideMenuContainer;
