import React from 'react';
import PropTypes from 'prop-types';
import Feature from 'ol/Feature';
import { fromExtent } from 'ol/geom/Polygon';
import WKT from 'ol/format/WKT';
import { useSelector } from 'react-redux';
import Button from '../presentational/common/Button';
import './MapExtentChooser.scss';

const MapExtentChooser = (props) => {
  const map = useSelector((state) => state.map.map);
  const handleReadButtonClick = (event) => {
    event.preventDefault();

    // Get the current extent of the map with no changes
    const view = map.olMap.getView();
    const extent = view.calculateExtent(map.olMap.getSize());

    const feature = new Feature({
      geometry: fromExtent(extent),
    });

    const formatter = new WKT();
    const wkt = formatter.writeFeature(
      feature, { dataProjection: map.projection.name },
    );
    props.onChange(`SRID=${map.projection.srid};${wkt}`);
    alert("Leitura da Bounding Box efetuada com sucesso")
  };

  const handleDeleteButtonClick = (event) => {
    event.preventDefault();
    // Put null on the field value
    props.onChange(undefined);

    alert("Bounding Box removida")
  };

  if (map == null) {
    return <div>Não aplicável</div>;
  }

  return (
    <div>
      <Button onClick={handleReadButtonClick} className={'extent-chooser-btn'}>
        {props.options.buttonText}
      </Button>
      {!props.required &&
      <Button danger onClick={handleDeleteButtonClick}>
        Eliminar BBOX
      </Button>
      }
    </div>
  );
};

MapExtentChooser.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    buttonText: PropTypes.string.isRequired,
  }).isRequired,
  required: PropTypes.bool.isRequired,
};


const MapExtentChooserWidget = MapExtentChooser

const WrappedMapExtentChooserWidget = props => <MapExtentChooserWidget {...props} />

export default WrappedMapExtentChooserWidget;
