import { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Constants from '../../utils/constants';
import { OverlaySpinner } from '../presentational/common';
import { TransparentInput } from '../presentational';

const MESSAGES = {
  submit: 'Recuperar Password',
  email: 'Email',
  backToLogin: 'Voltar ao Login',
  formError: 'Ocorreu um erro, por favor tente mais tarde.',
  formSuccess:
    'E-mail enviado com sucesso. Por favor, verifique o seu email para seguir o link de alteração de password.',
  emailRequired: 'O email é obrigatório.',
};

const SubmitButton = styled.button.attrs({
  type: 'submit',
})`
  width: 100%;
  color: white;
  background-color: #008cd0;
  border: none;
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
`;

const FormError = styled.div`
  color: red;
`;

const FormSuccess = styled.div`
  background-color: #2ea500;
  color: white;
  border-radius: 2px;
  padding: 4px 8px;
  margin-bottom: 4px;
`;

const ForgotPasswordLink = styled.a`
  color: white;
  cursor: pointer;
  text-align: center;
  display: block;
  margin-top: 10px;
`;

function ForgotPasswordForm({ onBackToLogin }) {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    if (!email) {
      setError(MESSAGES.emailRequired);
      return;
    }

    setLoading(true);

    try {
      await axios.post(`${Constants.API_BASE_URL}/auth/password-reset/`, { email });
      setSuccess(true);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  if (success) {
    return <FormSuccess>{MESSAGES.formSuccess}</FormSuccess>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <TransparentInput
        type="email"
        name="email"
        placeholder={MESSAGES.email}
        value={email}
        onChange={handleChange}
        marginBottom="25px"
      />
      <SubmitButton>{MESSAGES.submit}</SubmitButton>
      {loading && <OverlaySpinner />}
      {error && <FormError>{error}</FormError>}
      <ForgotPasswordLink onClick={onBackToLogin}>{MESSAGES.backToLogin}</ForgotPasswordLink>
    </form>
  );
}

export default ForgotPasswordForm;
