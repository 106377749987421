import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Constants from '../../utils/constants';
import { SelectableButton } from '../presentational/common';


const fetchQueuedVideos = () => {
  return axios.get(`${Constants.API_BASE_URL}/geovideo/queued-videos/`)
    .then(({ data: queuedVideos }) => {
      const queuedVideosGrouped = queuedVideos.reduce(
        (acc, { project_id, project_name, status }) => {


          if (!(project_id in acc)) {
            return { ...acc, [project_id]: { name: project_name, statuses: { [status]: 1 } } };
          }

          if (!(status in acc[project_id]['statuses'])) {
            return {
              ...acc,
              [project_id]: {
                ...acc[project_id], statuses: { ...acc[project_id]['statuses'], [status]: 1 }
              },
            }
          }

          return {
            ...acc,
            [project_id]: {
              ...acc[project_id],
              statuses: {
                ...acc[project_id]['statuses'],
                [status]: acc[project_id]['statuses'][status] + 1,
              }
            },
          };
        }, {});

      return queuedVideosGrouped

    });
};


const QueuedVideosDashboard = () => {
  const [queuedVideos, setQueuedVideos] = useState(null);
  const [movedProjects, setMovedProjects] = useState([]);

  useEffect(() => {
    fetchQueuedVideos().then(setQueuedVideos);
  }, []);

  return (
    <div>
      <SelectableButton style={{ position: 'absolute', top: '20px', right: '55px' }}
                        onClick={() => {
                          setQueuedVideos(null);
                          fetchQueuedVideos().then(setQueuedVideos)
                        }}>
        <i className="fas fa-sync" />
      </SelectableButton>
      {(queuedVideos !== null && Object.keys(queuedVideos).length === 0) &&
      <div>Não existem tarefas pendentes.</div>}
      {queuedVideos && Object.entries(queuedVideos).map(
        ([projectId, { name, statuses }]) => (
          <div key={projectId} style={{ borderBottom: '1px solid black', padding: '10px 0' }}>
            <h5>{name}</h5>
            {Object.entries(statuses).map(
              ([status, count]) => (
                <div key={status}>
                  {status}: {count}
                </div>
              )
            )}
            {(statuses['Processado'] != null && movedProjects.indexOf(projectId) ===
              -1) &&
            (<SelectableButton
              style={{ marginTop: '30px' }}
              onClick={() => {
                if (window.confirm(
                  `Prentende mover os vídeos processados do projeto "${name}"?`)) {
                  setMovedProjects([...movedProjects, projectId]);
                  setQueuedVideos(null);

                  axios.post(
                    `${Constants.API_BASE_URL}/geovideo/queued-videos/move-processed/?project=${projectId}`)
                    .then(() => fetchQueuedVideos().then(setQueuedVideos))
                }
              }}
            >
              <i className="fas fa-people-carry"></i> Mover vídeos processados
            </SelectableButton>)}
          </div>
        )
      )}
    </div>
  );
};

export default QueuedVideosDashboard;
