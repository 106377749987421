import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import Button from '../presentational/common/Button';

const FeatureInfoTable = styled.table`
  margin-bottom: 20px;

  &, & td, & th {
    border: 1px solid #ddd;
    border-collapse: collapse;
    font-size: 90%;
  }


  th {
    font-weight: bold;
    background: #eee;
    padding: 5px 20px;
    margin: 0;
  }


  a {
    color: blue;
  }

  td{
    background: #fff;
    padding: 1px 5px;
    margin: 0;
  }

  tr:nth-child(odd) td{
    background: #eee;
  }

  caption{
    text-align: left;
    font-size: 100%;
    font-weight: bold;
  }
`;

const TableContainerDiv = styled.div`
  overflow: scroll;
  padding: 20px 10px;
`;

const FeatureDisplayer = ({ features, featureFocus, handleModalClose }) => {
  const groupedFeatures = features.reduce((groupedFeatures, feature) => {
    const [featureType,] = feature.id.split('.');

    if (!(featureType in groupedFeatures)) {
      groupedFeatures[featureType] = [];
    }

    groupedFeatures[featureType].push(feature);

    return groupedFeatures;
  }, {});

  const getUrlField = (feature, prop) => {
    return <a href={feature.properties[prop]} target="_blank">{feature.properties[prop]}</a>
  }

  const renderFeatureProps = (featureProps, feature) => {
    featureProps = featureProps.map(prop => {
    const isString = typeof feature.properties[prop] === 'string';
    const isURL = isString && (feature.properties[prop].startsWith('http://')  || feature.properties[prop].startsWith('https://'));

      return (<td key={`${feature.id}-${prop}`}>
        {isURL ? getUrlField(feature, prop) : feature.properties[prop]}
      </td>)})
    return featureProps;
}

  return (<Modal isOpen={true} ariaHideApp={false}>
    <Button onClick={handleModalClose}>
      <i className="fas fa-times" />
    </Button>
    <TableContainerDiv>
      {Object.entries(groupedFeatures)
        .map(([featureType, features]) => {
            const featureProps = Object.keys(features[0].properties);

            return (<FeatureInfoTable key={featureType}>
              <caption>{featureType}</caption>
              <tbody>
              <tr>
                <th>id</th>
                {featureProps.map(prop => <th key={`${prop}-header`}>{prop}</th>)}
                <th>Focar</th>
              </tr>
              {features.map(feature =>
                <tr key={feature.id}>
                  <td>{feature.id}</td>
                  {renderFeatureProps(featureProps, feature)}
                  <td><Button onClick={() => featureFocus(feature)}>Focar</Button></td>
                </tr>
              )}
              </tbody>
            </FeatureInfoTable>);
          }
        )}
    </TableContainerDiv>
  </Modal>);
};

export default FeatureDisplayer;
