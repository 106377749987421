import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Constants from '../utils/constants';

export const login = createAsyncThunk(
  'user/login',
  async (credentials) => {
    const { username, password } = credentials;
    try {
      const response = await axios.post(`${Constants.API_BASE_URL}/auth/token/login/`, {
        username,
        password,
      });
      const { auth_token: token, edit_permission: editPermission } = response.data;
      localStorage.setItem('user', username);
      localStorage.setItem('access_token', token);
      localStorage.setItem('edit_permission', editPermission);
      axios.defaults.headers.common.Authorization = `Token ${token}`;
      return { token, username, editPermission };
    } catch (error) {

      throw error;
    }
  }
);

export const logout = createAsyncThunk(
  'user/logout',
  async () => {
    try {
      localStorage.removeItem('user');
      localStorage.removeItem('access_token');
      localStorage.removeItem('edit_permission');
      delete axios.defaults.headers.common.Authorization;
      return true;
    } catch (error) {
      // Handle error
      throw error;
    }
  }
);
