import { getTransform } from 'ol/proj';
import { applyTransform, containsExtent } from 'ol/extent';
import { isObject } from '../components/RJSForm/Form';

export default class Utils {
  /**
   *  Checks if an array has duplicated elements
   *
   * @param {Array} array Array to check
   * @returns {boolean} Returns true if the array has duplicates
   */
  static arrayHasDuplicates(array) {
    return (new Set(array)).size !== array.length;
  }

  /**
   *
   * @param {object} object object to validate
   * @param {...string} keys key chain to lookup
   * @returns {boolean} Returns true if object contains the keys in chain
   */
  static validChain(object, ...keys) {
    return keys.reduce((a, b) => (a || {})[b], object) !== undefined;
  }

  /**
   * Transforms all null properties into empty strings
   *
   * @param {object} object object to transform
   */
  static cleanNullProperties(object) {
    const newObject = Object.assign({}, object);
    Object.entries(object).forEach(([key, value]) => {
      if (value === null) {
        newObject[key] = '';
      }
    });
    return newObject;
  }

  /**
   * Get the react component display name
   *
   * @param {React.Component} component react component
   */
  static getComponentDisplayName(component) {
    return component.displayName || component.name || 'Component';
  }

  /**
   * Converts a projection to a specific projection
   *
   * @param {ol.extent} extent Extent to convert
   * @param {ol.ProjectionLike} sourceProjection Current extent projection
   * @param {ol.ProjectionLike} targetProjection Target extent projection
   */
  static transformExtentToProjection(extent, sourceProjection, targetProjection) {
    const fromLonLat = getTransform(sourceProjection, targetProjection);
    return applyTransform(extent, fromLonLat);
  }

  /**
   * Convert a describe feature type to a corresponding json schema definition to generate forms.
   * @param featureType describe feature type JSON
   * @returns object json schema definition
   * @constructor
   */
  static WFSFeatureTypeToReactJsonSchema(featureType) {
    const typeMapping = {
      boolean: { type: 'boolean' },
      date: { type: 'string', format: 'date' },
      datetime: { type: 'string', format: 'date-time' },
      decimal: { type: 'number' },
      double: { type: 'number' },
      float: { type: 'number' },
      int: { type: 'integer' },
      integer: { type: 'integer' },
      long: { type: 'number' },
      string: { type: 'string' },
      time: { type: 'string' },
    };

    const schemaProperties = Object.entries(featureType)
      .reduce((fieldDefinitions, [fieldName, fieldType]) => {
        const fieldSchema = fieldType in typeMapping ? typeMapping[fieldType] : typeMapping.string;

        return { ...fieldDefinitions, [fieldName]: fieldSchema };
      }, {});

    return {
      type: 'object',
      properties: schemaProperties,
    };
  }

  /**
   * Compare two object keys and fill the missing ones with null
   * @param incompleteObj the object to fill with missing keys
   * @param targetObj sample "complete" object
   */
  static objectFillMissingKeys(incompleteObj, targetObj) {
    Object.keys(targetObj).forEach((key) => {
      if (!(key in incompleteObj) || incompleteObj[key] === undefined) {
        incompleteObj[key] = null;
      } else if (isObject(targetObj[key])) {
        Utils.objectFillMissingKeys(incompleteObj[key], targetObj[key]);
      }
    });
  };

  /**
   * Convert CRS full name intro custom one if it is defined else return full name
   * @param {string} crsName projection full name
   * @returns string with custom projection name
   */
  static customCRSName(crsName) {
    const crsMapping = {
      'ETRS89 / Portugal TM06': 'ETRS89 PT-TM06',
    };

    if (Object.prototype.hasOwnProperty.call(crsMapping, crsName)) {
      return crsMapping[crsName];
    } else {
      return crsName;
    }
  }

  /**
   * Check if a list of features is contained within an extent
   * @param {list} featureList list of features
   * @param {extent} checkExtent extent used to check if it containes all of the features
   * @returns {bool} True if all features are contained within checkExtent
   */
  static extentContainsAllFeatures(featureList, checkExtent) {
    if (checkExtent === undefined || checkExtent === null) {
      return true;
    }
    const featureLength = featureList.length;
    const containedFeatures = featureList.filter((currentFeature) =>
      containsExtent(checkExtent, currentFeature.getGeometry().getExtent())
    );
    return Boolean(featureLength === containedFeatures.length);
  }
}
