import { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Constants from '../../utils/constants';
import { OverlaySpinner } from '../presentational/common';
import { TransparentInput } from '../presentational';

const MESSAGES = {
  newPassword: 'Nova Password',
  confirmPassword: 'Confirmar Password',
  submit: 'Alterar Password',
  formError: 'Ocorreu um erro, por favor tente mais tarde.',
  formSuccess: 'A password foi alterada com sucesso.',
  passwordsMismatch: 'As passwords não coincidem.',
};

const SubmitButton = styled.button.attrs({
  type: 'submit',
})`
  width: 100%;
  color: white;
  background-color: #008cd0;
  border: none;
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
`;

const FormError = styled.div`
  color: red;
`;

const FormSuccess = styled.div`
  background-color: #2ea500;
  color: white;
  border-radius: 2px;
  padding: 4px 8px;
  margin-bottom: 4px;
`;

const ResetPasswordForm = ({ uid, token }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setLocalMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLocalMessage('');
    if (newPassword !== confirmPassword) {
      setLocalMessage(MESSAGES.passwordsMismatch);
      return;
    }
    setLoading(true);
    try {
      await axios.post(`${Constants.API_BASE_URL}/auth/password-reset/confirm/${uid}/${token}/`, {
        token: token,
        uid: uid,
        new_password: newPassword,
      });
      setLocalMessage(MESSAGES.formSuccess);
    } catch (error) {
      setLocalMessage(MESSAGES.formError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TransparentInput
        type="password"
        name="newPassword"
        placeholder={MESSAGES.newPassword}
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        marginBottom="25px"
      />
      <TransparentInput
        type="password"
        name="confirmPassword"
        placeholder={MESSAGES.confirmPassword}
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        marginBottom="25px"
      />
      <SubmitButton>{MESSAGES.submit}</SubmitButton>
      {loading && <OverlaySpinner />}
      {message &&
        (message === MESSAGES.formSuccess ? <FormSuccess>{message}</FormSuccess> : <FormError>{message}</FormError>)}
    </form>
  );
};

export default ResetPasswordForm;
