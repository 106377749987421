import { createSlice } from '@reduxjs/toolkit';
import { login, logout} from '../thunks/userThunk';


const initialState = {
  token: localStorage.getItem('access_token'),
  user: localStorage.getItem('user'),
  loggingIn: false,
  isAuthenticated: localStorage.getItem('access_token') !== null,
  hasEditPermission: localStorage.getItem('edit_permission') === 'true',
  error: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loggingIn = true;
        state.isAuthenticated = false;
        state.hasEditPermission = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loggingIn = false;
        state.isAuthenticated = true;
        state.error = false;
        state.token = action.payload.token;
        state.user = action.payload.username;
        state.hasEditPermission = action.payload.editPermission;
      })
      .addCase(login.rejected, (state) => {
        state.loggingIn = false;
        state.isAuthenticated = false;
        state.error = true;
        state.token = null;
        state.user = null;
        state.hasEditPermission = false;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isAuthenticated = false;
        state.token = null;
        state.user = null;
        state.hasEditPermission = false;
      })
  },
});


export default userSlice.reducer;
