import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { login, logout } from '../../thunks/userThunk';
import { OverlaySpinner } from '../presentational/common';
import { TransparentInput } from '../presentational';
import ForgotPasswordForm from './ForgotPasswordForm';

const MESSAGES = {
  submit: 'Entrar',
  username: 'Username',
  password: 'Password',
  forgotPassword: 'Recuperar password',
  register: 'Criar conta',
  formError: 'Ocorreu um erro, por favor tente mais tarde.',
};

const SubmitButton = styled.button.attrs({
  type: 'submit',
})`
  width: 100%;
  color: white;
  background-color: #008cd0;
  border: none;
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
`;

const FormError = styled.div`
  color: red;
`;

const Links = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ForgotPasswordLink = styled.a`
  color: white;
  cursor: pointer;
`;

const RegisterLink = styled(Link)`
  color: white;
  cursor: pointer;
`;

function LoginForm({ showForgotPassword, setShowForgotPassword }) {
  const dispatch = useDispatch();
  const { isAuthenticated, loggingIn, error } = useSelector((state) => state.user);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (username && password) {
      dispatch(login({ username, password }));
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {showForgotPassword ? (
        <ForgotPasswordForm onBackToLogin={() => setShowForgotPassword(false)} />
      ) : (
        <form onSubmit={handleSubmit}>
          <TransparentInput
            type="text"
            name="username"
            placeholder={MESSAGES.username}
            value={username}
            onChange={handleChange}
            marginBottom="25px"
          />
          <TransparentInput
            type="password"
            name="password"
            placeholder={MESSAGES.password}
            value={password}
            onChange={handleChange}
            marginBottom="25px"
          />
          <SubmitButton>{MESSAGES.submit}</SubmitButton>
          <Links>
            <RegisterLink to="/register">{MESSAGES.register}</RegisterLink>
            <ForgotPasswordLink onClick={() => setShowForgotPassword(true)}>
              {MESSAGES.forgotPassword}
            </ForgotPasswordLink>
          </Links>
          {loggingIn && <OverlaySpinner />}
          {error && <FormError>{MESSAGES.formError}</FormError>}
        </form>
      )}
    </>
  );
}

LoginForm.propTypes = {
  showForgotPassword: PropTypes.bool.isRequired,
  setShowForgotPassword: PropTypes.func.isRequired,
};

export default LoginForm;
