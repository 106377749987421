// Bootstrap Breakpoints from: https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints

/**
 * Extra extra small devices (portrait phones, less than 415px)
 */
const ULTRA_SMALL_MAX = '415.98px';
/**
 * Extra small devices (portrait phones, less than 576px)
 */
const EXTRA_SMALL_MAX = '575.98px';
/**
 * Small devices (landscape phones, less than 768px)
 */
const SMALL_MAX = '767.98px';
/**
 * Medium devices (tablets, less than 992px)
 */
const MEDIUM_MAX = '991.98px';
/**
 * Large devices (desktops, less than 1200px)
 */
const LARGE_MAX = '1199.98px';

export {
  ULTRA_SMALL_MAX,
  EXTRA_SMALL_MAX,
  SMALL_MAX,
  MEDIUM_MAX,
  LARGE_MAX,
};
