import React, { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchMapState } from '../../thunks/mapThunk';


function AutoMapState() {
    const dispatch = useDispatch();

    const { mapStateSlug } = useParams();
    const { mapState } = useSelector(state => state.map);

    useEffect(() => {
        dispatch(fetchMapState({mapStateSlug}));
    }, [] );

    if (mapState != null && mapState != undefined) {
        return <Redirect to={`/mapas/${mapState.map}`}/>;
    }

    return (
      <div></div>
    );
  }

  export default AutoMapState;