import styled from 'styled-components';

export default styled.input.attrs({
  type: 'checkbox',
})`
  appearance: none;
  position: relative;
  top: 3px;
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 15px;
  width: 15px;
  outline: none;

  &:checked {
    background: #008cd0;

    &:after {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      line-height:15px;
      text-align:center;
      color:#fff;
      font-family: 'Font Awesome 5 Free';
      font-size: 9px;
      font-weight: 900;
      content: '\f00c';
    }
  }
`;
