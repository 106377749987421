import LayerFactory, { LAYER_SOURCES } from '../factories/LayerFactory';
import Constants from '../utils/constants';
import axios from 'axios';

class Layer {
  constructor(layerType, {
    id, name, order, detailURL, editionAttributes, ...layerConfig
  }) {
    // Prevent duplicated ids between base and overlay layers
    this.id = `${layerType}-${id}`;
    this.name = name;
    this.type = layerType;
    this.order = order;
    this.olLayer = LayerFactory.createLayer(layerConfig);
    this.detailURL = detailURL;
    this.editionAttributes = editionAttributes;
    this.sourceType = layerConfig.sourceType;

    const { url, featureTypeName, wmsParams: { LAYERS: wmsLayerName } = {} } = layerConfig;
    this.serviceUrl = url;
    this.featureTypeName = featureTypeName;
    this.layerName = wmsLayerName;
  }

  getFeatureSchema() {
    if (this.sourceType !== LAYER_SOURCES.WFS) {
      throw new Error(
        `Invalid method for this layer source type! Allowed: ${LAYER_SOURCES.WFS} Given: ${this.sourceType}`);
    }

    if (this._featureSchema) {
      return Promise.resolve(this._featureSchema);
    }

    const url = `${Constants.API_BASE_URL}/layers/wfs-request/describe-feature-type/?url=${this.serviceUrl}&typename=${this.featureTypeName}`;

    return axios.get(url)
      .then(({ data: featureSchema }) => {
        this._featureSchema = featureSchema;

        return featureSchema;
      })
      .catch(() => {
        throw new Error('Error requesting feature type description');
      });
  }
}


export default Layer;
