import styled from 'styled-components';

export default styled.input.attrs({
  type: 'radio',
})`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  border-radius: 50%;
  transition: 0.2s all linear;
  outline: none;
  margin-right: 5px;
  position: relative;

  &:checked {
    background-color: #008cd0;
    border: none;
  }
`;

