import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Layer from '../../../models/Layer';
import adminToolbox from '../../Admin/AdminComponent';
import SidebarListItem from '../../presentational/common/SidebarListItem';
import { NormalText, ActionButtonContainer, SelectableButton, Button } from '../../presentational/common';
import EyeCheckbox from '../../presentational/EyeCheckbox';
import Slider from '../../presentational/common/Slider';
import ResponsiveImage from '../../presentational/common/ResponsiveImage';
import Modal from 'react-modal';
import { ImageWMS } from 'ol/source';
import TileWMS from 'ol/source/TileWMS';

const LayerNameLabel = styled(NormalText.withComponent('label'))`
  margin-bottom: 0;
`;


const CloseModalButton = styled(Button)`
  align-self: flex-start;
  margin-bottom: 10px;
`;

const ButtonContainerDiv = styled.div`
  display: flex;
  flex: 1.5;
  width: 150px;
  justify-content: end;
`;

const CustomActionButtonContainer = styled(ActionButtonContainer)`
  margin-left: 10px;
`;

const ResponsiveImageStart = styled(ResponsiveImage)`
  align-self: flex-start;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const SliderContainer = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
`;
const ColumnContainer = styled.div`
  width: 100%;`;

/**
 * OverlayLayerSwitcherGroup item for each overlay layer
 */
function OverlayLayerSwitcherItem({ layer, renderAdminButtons }) {
  const [isVisible, setIsVisible] = useState(layer.olLayer.getVisible());
  const [opacity, setOpacity] = useState(layer.olLayer.getOpacity());
  const [layerLegendActive, setLayerLegendActive] = useState(null);

  const handleVisibilityChange = (event) => {
    const isChecked = event.currentTarget.checked;
    layer.olLayer.setVisible(isChecked);
    setIsVisible(isChecked);
  };

  const handleOpacityChange = (opacity) => {
    layer.olLayer.setOpacity(opacity);
    setOpacity(opacity);
  };

  return (
    <>
      <SidebarListItem>
        <SliderContainer>
          <EyeCheckbox id={`input-${layer.id}`} checked={isVisible} onChange={handleVisibilityChange} />
          <ColumnContainer>
            <LayerNameLabel htmlFor={`input-${layer.id}`}>
              {layer.name}
            </LayerNameLabel>
            <Slider min={0} max={1} step={0.05} value={opacity} onChange={handleOpacityChange} />
          </ColumnContainer>
        </SliderContainer>
        <ButtonContainerDiv>
          <CustomActionButtonContainer>
            {(layer.olLayer.getSource() instanceof ImageWMS || layer.olLayer.getSource() instanceof TileWMS) &&
              <SelectableButton title="Legenda"
                                onClick={() => setLayerLegendActive(layer.olLayer.getSource().getLegendUrl())}>
                <i className="fas fa-info fa-fw" />
              </SelectableButton>}
            {renderAdminButtons && renderAdminButtons()}
          </CustomActionButtonContainer>
          {layerLegendActive !== null &&
            <Modal isOpen={true} onRequestClose={() => setLayerLegendActive(null)} ariaHideApp={false}>
              <ModalContent>
                <CloseModalButton onClick={() => setLayerLegendActive(null)}><i
                  className="fas fa-times" /></CloseModalButton>
                <ResponsiveImageStart src={layerLegendActive} />
              </ModalContent>
            </Modal>
          }
        </ButtonContainerDiv>
      </SidebarListItem>
    </>
  );
}

OverlayLayerSwitcherItem.propTypes = {
  layer: PropTypes.instanceOf(Layer).isRequired,
  renderAdminButtons: PropTypes.func,
};

OverlayLayerSwitcherItem.defaultProps = {
  renderAdminButtons: null,
};

export default adminToolbox(OverlayLayerSwitcherItem);
